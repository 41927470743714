import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../common/Title';
import Description from '../../common/Description';
import CookieDetails from './cookieDetails';
import styles from './TechnicallyNecessary.scss';

const TechnicallyNecessary = ({
  title, message, cookiesInfo, cookieInfoLabels
}) => (
  <div className={styles.container}>
    <Title text={title} />
    <div className={styles.description}>
      <div>
        <Description text={message} />
        <CookieDetails cookiesInfo={cookiesInfo} cookieInfoLabels={cookieInfoLabels} />
      </div>
    </div>
  </div>
);

TechnicallyNecessary.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cookiesInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  cookieInfoLabels: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TechnicallyNecessary;
