import { NODE_TYPES } from '../../constants';

function multiConstantGenerator(values) {
  return {
    type: NODE_TYPES.MULTI_CONSTANT,
    values: values.map(String)
  };
}

export default multiConstantGenerator;
