import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../common/Title';
import Description from '../../common/Description';
import styles from './legalNotice.scss';

const LegalNotice = ({ title, description }) => (
  <div className={styles.container}>
    <Title text={title} />
    <div className={styles.description}>
      <Description text={description} />
    </div>
  </div>
);

LegalNotice.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default LegalNotice;
