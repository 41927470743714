import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Icon, { ICON_TYPES } from '../../Icon/index';
import stripHtml from '../../../utils/stripHtml/index';
import styles from '../dropdown.scss';
import { testName } from "../../../utils/testUtils";
import { isDropdownAccessibilityEnabled } from '../../../services/featureFlags';
import { getTranslation } from '../../../services/translations';
import IndicatorCheckbox from '../../IndicatorCheckbox';
import Row from '../../../commons/Table/Row';
import Cell from '../../../commons/Table/Cell';
import { itemShape } from '../types';

const { bool, func } = PropTypes;

class Item extends React.Component {
  constructor(props) {
    super(props);

    this._$item;

    this.select = this.select.bind(this);
    this.setRefContainer = this.setRefContainer.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  static propTypes = {
    isMultiValued: bool,
    item: itemShape.isRequired,
    selectItem: func.isRequired,
    focused: bool,
    selected: bool
  };

  static defaultProps = {
    focused: false,
    isMultiValued: false,
    selected: false
  };

  componentDidMount() {
    if (this.props.focused) {
      this._$item.focus();
    }
  }

  componentDidUpdate() {
    if (this.props.focused) {
      this._$item.focus();
    }
    if (!this.props.focused) {
      this._$item.blur();
    }
  }

  componentWillUnmount() {
    this._$item.blur();
  }

  setRefContainer(container) {
    this._$item = container;
  }

  select() {
    this.props.selectItem(this.props.item);
  }

  onKeyDown(e) {
    return this.props.handleKeyPressSelectItem(e, this.props.item);
  }

  renderIcon() {
    return <Icon type={ICON_TYPES.CHECKBOX} containerStyles={styles.dropdownIcon} />;
  }

  getAriaLabelObject(text, selected) {
    /** TODO: This translation was created for Calendar purposes
     * Eventhough this is transparent for admins that only translate
     * the "Selected" string **/

    const selectedText = getTranslation('CAL_SELECTED');
    const accessibleAriaLabel = selected ? `${text}, ${selectedText}` : text;
    return { 'aria-label': accessibleAriaLabel };
  }

  getContentWrapper(hasLangKey) {
    if (hasLangKey) {
      return (props) => <a ref={this.setRefContainer} role="link" {...props} />;
    }
    return (props) => <button ref={this.setRefContainer} role="button" type="button" {...props} />;
  }

  renderAccessibleContent() {
    const {
      isMultiValued,
      item,
      selected
    } = this.props;

    const { text } = item;
    const optionText = stripHtml(text);

    const Wrapper = this.getContentWrapper(item.langKey);
    const ariaLabelObject = this.getAriaLabelObject(optionText, selected);
    return (
      <Wrapper
        tabIndex="-1"
        className={styles.dropdownListItemLink}
        {...ariaLabelObject}
      >
        {isMultiValued ? (
          <Row>
            <Cell containerStyles={styles.dropdownListItemMultiValuedCheckboxCell}>
              <IndicatorCheckbox isSelected={selected} />
            </Cell>
            <Cell>
              <span>{optionText}</span>
            </Cell>
          </Row>
        ) : (
          <span>
            <span>{optionText}</span>
            {selected && this.renderIcon()}
          </span>
        )}
      </Wrapper>
    );
  }

  legacyRender() {
    const {
      item,
      selected
    } = this.props;

    return (
      <li
        ref={this.setRefContainer}
        role="menuitem"
        tabIndex="-1"
        className={styles.dropdownListItem}
        onClick={this.select}
        onKeyDown={this.onKeyDown}
        {...testName(stripHtml(item.text), 'option')}
      >
        {stripHtml(item.text)}
        { selected && this.renderIcon() }
      </li>
    );
  }

  accessibleRender() {
    const {
      isMultiValued,
      item,
      selected,
      focused
    } = this.props;

    const langKeyObject = item.langKey ? { lang: item.langKey } : {};
    const listItemClasses = classNames(
      styles.dropdownListItem,
      {
        [styles.dropdownListItemWithLinkFocused]: focused,
        [styles.dropdownListItemMultiValued]: isMultiValued
      }
    );
    const ariaSelectedObject = { 'aria-selected': !!selected };

    return (
      <li
        role="option"
        className={listItemClasses}
        onClick={this.select}
        onKeyDown={this.onKeyDown}
        {...langKeyObject}
        {...ariaSelectedObject}
        {...testName(stripHtml(item.text), 'option')}

      >
        {this.renderAccessibleContent()}
      </li>
    );
  }

  render() {

    const { isSubComponent } = this.props;

    if (isDropdownAccessibilityEnabled() || isSubComponent) {
      return this.accessibleRender();
    }
    return this.legacyRender();
  }
}

export default Item;
