import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import mergeCustomizerForAllAndCount from '../../utils/mergeCustomizerForAllAndCount';
import isDefined from '../../utils/isDefined';

import Question from '../question';
import Option from '../option';
import HtmlInput from '../htmlInput';
import { createCondition } from '../condition';
import { createField } from '../field';

/**
 * @private
 *
 * Given an id and the resources (questions, options, or htmlInputs), find
 * the resource with the given id in the given resources.
 *
 * If the resource is not found, the defaultValue will be returned.
 */
export const findAndUpdateExistingResource = ({
  id, resources, attributes, defaultValue = {}
}) => {
  const resource = resources[id];
  const resourceExists = isDefined({ err: 'Question must exist!', value: resource });
  if (!resourceExists) return defaultValue;
  mergeWith(resource, attributes, mergeCustomizerForAllAndCount);
  return resource;
};


export const normalizeFieldData = (fieldName, questionId) => {
  // questionId may be 0, so !questionId would break that case
  if (!fieldName || questionId == null) return null;

  return { id: fieldName };
};

/**
 * Resources are Question, Option, HtmlInput.
 *
 * ResourcesRelationshipManager manages the creation of the Resources and their
 * relationship dependencies. If an Option is created with `createOrUpdateOption`,
 * the ResourcesRelationshipManager will create an Option and link it to the
 * Question if it exists.
 *
 * Always create the Resources in this order:
 *   1) Question
 *   2) HtmlInput
 *   3) Option
 *
 * ResourcesRelationshipManager will store all of its resources in this format
 *
 * {
 *   questions: {
 *     [id]: Question{...}
 *     all: Set{...},
 *     count: 0
 *   },
 *   options: {
 *     [id]: Option{...}
 *     all: Set{...}
 *     count: 0
 *   },
 *   htmlInputs: {
 *     [id]: HtmlInput{...}
 *     all: Set{...}
 *     count: 0
 *   },
 * }
 */


export default class ResourcesRelationshipManager {
  constructor(args = {}) {
    this._resources = defaultsDeep(args, {
      options: { all: new Set(), count: 0 },
      questions: { all: new Set(), count: 0 },
      htmlInputs: { all: new Set(), count: 0 },
      /**
       * New store slice type. Avoid use of Set
       * and remove all and count keys because
       * only added unnecessary complexities and
       * were not used anywhere
       * We're moving on from that
       */
      components: {},
      conditions: {},
      fields: {},
      fieldControlledBy: {}
    });
  }

  /**
   * Returns all of the Resources created in this format:
   *
   * {
   *   questions: {
   *     [id]: Question{...}
   *     all: Set{...},
   *     count: 0
   *   },
   *   options: {
   *     [id]: Option{...}
   *     all: Set{...}
   *     count: 0
   *   },
   *   htmlInputs: {
   *     [id]: HtmlInput{...}
   *     all: Set{...}
   *     count: 0
   *   },
   * }
   */
  resources() {
    return this._resources;
  }

  /**
   * Creates a Question. This should always be used first
   */
  createOrUpdateQuestion(questionData) {
    const question = this._createOrUpdateResource('questions', Question, questionData);
    this.createComponent(question.id);
    return question;
  }

  /**
   * Creates an HtmlInput.
   *
   * If the ID of a question is present in field `question`, the Question will
   * be linked with the HtmlInput
   */
  createOrUpdateHtmlInput(htmlInputData) {
    const htmlInput = this._createOrUpdateResource('htmlInputs', HtmlInput, htmlInputData);
    findAndUpdateExistingResource({
      id: htmlInput.question,
      resources: this._resources.questions,
      attributes: {
        htmlInputs: new Set([htmlInput.id]),
        htmlInputsWithAnswer: htmlInput.value ? new Set([htmlInput.id]) : new Set()
      }
    });
    return htmlInput;
  }

  /**
   * Creates an HtmlInput.
   *
   * If the ID of a question is present in field `question`, the Question will be
   * linked with the Option.
   *
   * If the ID of a HtmlInput is present in field `htmlInput`, the HtmlInput will
   * be linked with the Option.
   */
  createOrUpdateOption(optionData) {
    const option = this._createOrUpdateResource('options', Option, optionData);
    if (option.parent) {
      const parentOption = this._resources.options[option.parent];
      const parentExists = isDefined({ err: 'Option.parent must exists!', value: parentOption });
      if (parentExists) parentOption.options.add(option.id);
    }
    findAndUpdateExistingResource({
      id: option.question,
      resources: this._resources.questions,
      attributes: {
        options: new Set([option.id]),
        optionsWithAnswer: option.hasAnswer ? new Set([option.id]) : new Set()
      }
    });
    const htmlInput = findAndUpdateExistingResource({
      id: option.htmlInput,
      resources: this._resources.htmlInputs,
      attributes: { options: new Set([option.id]) }
    });
    if (option.value !== undefined &&
      htmlInput &&
      !htmlInput.isTextAnswer()
    ) htmlInput.possibleValues.add(option.value);
    return option;
  }

  /** Adds component dynamic state to the store */

  createComponent(id, componentData = {}) {
    this._resources.components[id] = merge(this._resources.components[id], componentData);
  }

  /**
   * Creates a condition resource and connects it to its component
   * @param conditionData the condition block, as it was sent from the Survey Engine
   * @param componentId the id of the component that owns this condition. Could be a Question or
   * an Option
   * @returns the condition resource or null if parameters were not provided
   */
  createCondition(conditionData, componentId) {
    // componentId may be 0, so !componentId would break that case
    if (!conditionData || componentId == null) return null;

    const condition = createCondition(conditionData);

    const component = get(
      this._resources.questions,
      componentId,
      get(this._resources.options, componentId)
    );

    if (!component) return null;

    component.addCondition(condition);
    this._resources.conditions[condition.id] = condition;

    return condition;
  }

  /**
   * Creates a field resource and the proper relationships in the model to the components that
   * control it, i.e., which components may impact the field when they are conditioned out of
   * the survey
   * @param fieldName the field name, as sent from the Survey Engine
   * @param initialValue the initial value of the field (defaults to null)
   * @param questionId the question that controls the field
   * @param [optionId] the option (i.e., the Row on the Rating grid) that controls the field
   * @returns the field resource or null if parameters were not provided
   */
  createField(fieldName, initialValue, questionId, optionId) {
    const normalizedFieldData = normalizeFieldData(fieldName, questionId);

    if (!normalizedFieldData) return null;

    return this._createFieldResource(normalizedFieldData, initialValue, questionId, optionId);
  }

  /**
   * Creates a multivalued field resource and the proper relationships in the model to the
   * components that control it, i.e., which components may impact the field when they are
   * conditioned out of the survey
   * @param fieldName the field name, as sent from the Survey Engine
   * @param initialValue the initial value of the field (defaults to [])
   * @param questionId the question that controls the field
   * @param [optionId] the option (i.e., the Row on the Rating grid) that controls the field
   * @returns the field resource or null if parameters were not provided
   */
  createMultiValuedField(fieldName, initialValue, questionId, optionId) {
    const normalizedFieldData = normalizeFieldData(fieldName, questionId);

    if (!normalizedFieldData) return null;

    normalizedFieldData.isMultiValued = true;

    return this._createFieldResource(normalizedFieldData, initialValue, questionId, optionId);
  }

  /**
   * Creates an alternative resource for a field and the proper relationships in the model to the
   * component that controls it, i.e., the component that may impact the alternative from being
   * selected when they are conditioned out of the survey. This component can only be an Option
   * @param fieldName the field that owns the alternative as sent from the Survey Engine, i.e.,
   * q_ltr_scale
   * @param alternativeId the alternative id, typically, the value is sent from the Survey Engine
   * as "formValue", because it's what we end up posting if that alternative is selected
   * @param optionId the option that controls the alternative, i.e., the option that will remove
   * the alternative from being selectable if it gets conditioned out of the survey by its own IPCs
   */
  createAlternative(fieldName, alternativeId, optionId) {
    if (!fieldName || !alternativeId || !optionId) return;

    const alternativeRelationship = {
      [alternativeId]: optionId
    };

    if (!this._resources.fieldControlledBy[fieldName]) {
      this._resources.fieldControlledBy[fieldName] = [{
        alternativesControlledBy: alternativeRelationship
      }];
    } else {
      if (!this._resources.fieldControlledBy[fieldName][0].alternativesControlledBy) {
        this._resources.fieldControlledBy[fieldName][0].alternativesControlledBy = {};
      }

      // per resourceManager instance, a field should only have one relationship
      Object.assign(
        this._resources.fieldControlledBy[fieldName][0].alternativesControlledBy,
        alternativeRelationship
      );
    }
  }

  /**
   * @private
   *
   * Creates or updates the Resource: Question, Option, or HtmlInput
   */
  _createOrUpdateResource(type, Klass, data) {
    let resource = this._findResource(type, data.id);
    if (resource === undefined || resource === null) {
      resource = new Klass(data);
      this._addResource(type, resource.id, resource);
    } else {
      mergeWith(resource, data, mergeCustomizerForAllAndCount);
    }
    return resource;
  }

  _findResource(type, id) {
    return this._resources[type][id];
  }

  _addResource(type, id, resource) {
    this._resources[type][id] = resource;
    this._resources[type].all.add(id);
    this._resources[type].count += 1;
  }

  _createFieldResource(normalizedFieldData, initialValue, questionId, optionId) {
    const field = createField(normalizedFieldData);

    this._initFieldResource(field, questionId);
    this._setFieldRelationships(field, questionId, optionId);

    this._resources.components[questionId].values[field.id] = initialValue;

    return field;
  }

  _initFieldResource(field, questionId) {
    if (!this._resources.components[questionId].values) {
      this._resources.components[questionId].values = {};
    }

    if (!this._resources.fields[field.id]) {
      this._resources.fields[field.id] = field;
    }

    if (!this._resources.fieldControlledBy[field.id]) {
      this._resources.fieldControlledBy[field.id] = [{}];
    }

    if (!this._resources.components[questionId].values[field.id]) {
      this._resources.components[questionId].values[field.id] = field.isMultiValued ? [] : null;
    }
  }

  // set up which question and option (if any) controls this field
  _setFieldRelationships(field, questionId, optionId) {
    const fieldRelationship = {
      question: questionId
    };

    if (optionId != null) {
      fieldRelationship.option = optionId;
      // this.createOrUpdateOption has side effects that we need to manually revert later
      // That's why we're updating directly the resource
      this._createOrUpdateResource('options', Option, {
        id: optionId,
        field: field.id
      });
    } else {
      this._createOrUpdateResource('questions', Question, {
        id: questionId,
        field: field.id
      });
    }

    // per resourceManager instance, a field should only have one relationship
    Object.assign(this._resources.fieldControlledBy[field.id][0], fieldRelationship);
  }
}
