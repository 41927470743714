function getKey(e) {
  return (e.keyCode ? e.keyCode : e.which);
}

function isEnterKey(e) {
  return getKey(e) === 13;
}

function isSpaceKey(e) {
  return getKey(e) === 32;
}

function isArrowDownKey(e) {
  return getKey(e) === 40;
}

function isArrowLeftKey(e) {
  return getKey(e) === 37;
}

function isArrowRightKey(e) {
  return getKey(e) === 39;
}

function isArrowUpKey(e) {
  return getKey(e) === 38;
}

function isEsc(e) {
  return getKey(e) === 27;
}

function isTab(e) {
  return getKey(e) === 9;
}

export {
  getKey,
  isEnterKey,
  isSpaceKey,
  isArrowDownKey,
  isArrowUpKey,
  isArrowLeftKey,
  isArrowRightKey,
  isEsc,
  isTab
};
