import get from 'lodash/get';

export const BEGIN_BUTTON = {
  name: 'beginButton',
  domId: 'buttonBegin',
  showKey: 'showBeginButton',
  formKey: 'nextButton',
  translationKey: 'BEGIN_SURVEY'
};

export const BACK_BUTTON = {
  name: 'backButton',
  domId: 'buttonBack',
  showKey: 'showBackButton',
  formKey: 'prevButton',
  translationKey: 'BACK_BUTTON'
};

export const NEXT_BUTTON = {
  name: 'nextButton',
  domId: 'buttonNext',
  showKey: 'showNextButton',
  formKey: 'nextButton',
  translationKey: 'NEXT_BUTTON'
};

export const SAVE_BUTTON = {
  name: 'saveButton',
  domId: 'buttonSave',
  showKey: 'showSaveButton',
  formKey: 'saveButton',
  translationKey: 'SAVE_BUTTON'
};

export const FINISH_BUTTON = {
  name: 'finishButton',
  domId: 'buttonFinish',
  showKey: 'showFinishButton',
  formKey: 'finishButton',
  translationKey: 'FINISH_BUTTON'
};

export const BUTTON_CONFIGS = [BEGIN_BUTTON, BACK_BUTTON, NEXT_BUTTON, SAVE_BUTTON, FINISH_BUTTON];
export const TRIP_ADVISOR_BUTTONS = [NEXT_BUTTON, FINISH_BUTTON];
export const TRIP_ADVISOR_BLOCKTYPE = 'tripAdvisorWidgetPage';
export const TRIP_ADVISOR_CAPTION_KEY = 'TA_SKIP_STEP';

export const BAZAAR_VOICE_BUTTONS = [NEXT_BUTTON, FINISH_BUTTON];
export const BAZAAR_VOICE_BLOCKTYPE = 'bazaarVoicePage';

export const normalizeButton = (rawData, buttonConfig = {}) => {
  if (!rawData) return undefined;
  const {
    name, domId, showKey, formKey, translationKey
  } = buttonConfig;
  const shouldShow = Boolean(rawData[showKey]);
  if (!shouldShow) return undefined;

  const normalizedButton = { name, domId };
  normalizedButton.caption = (rawData.translations && rawData.translations[translationKey]) || (normalizedButton.name === 'saveButton' ? 'Save' : '');
  normalizedButton.formKey = rawData[formKey];
  return normalizedButton;
};

export const hasTripAdvisorNode = (rawData) => {
  const nodes = get(rawData, 'body.body.parts', []);
  return Boolean(nodes.find(node => node.blockType === TRIP_ADVISOR_BLOCKTYPE));
};

export const hasBazaarVoiceNode = (rawData) => {
  const nodes = get(rawData, 'body.body.parts', []);
  return Boolean(nodes.find(node => node.blockType === BAZAAR_VOICE_BLOCKTYPE));
};

export const getTripAdvisorCaption = rawData =>
  (rawData.translations && rawData.translations[TRIP_ADVISOR_CAPTION_KEY]) || '';

export const getBazaarVoicePostCaption = (rawData) => {
  if (rawData.translations) {
    return `${rawData.translations.BAZAAR_VOICE_POST_TO_BAZAAR_VOICE} ${rawData.translations.BAZAAR_VOICE_SUBMIT_SURVEY}`;
  }
  return '';
};

export const getBazaarVoiceNextCaption = (rawData) => {
  if (rawData.translations) {
    return `${rawData.translations.BAZAAR_VOICE_SKIP_STEP} ${rawData.translations.BAZAAR_VOICE_SUBMIT_SURVEY}`;
  }
  return '';
};

export default class NavigationButtonsNormalizer {
  // eslint-disable-next-line class-methods-use-this
  normalize({ rawData, normalizedData = {} }) {
    const navButtonsData = {};

    BUTTON_CONFIGS.forEach((buttonConfig) => {
      const normalizedButtonData = normalizeButton(rawData, buttonConfig);
      if (normalizedButtonData) navButtonsData[buttonConfig.name] = normalizedButtonData;
    });

    if (hasTripAdvisorNode(rawData)) {
      const tripAdvisorCaption = getTripAdvisorCaption(rawData);
      TRIP_ADVISOR_BUTTONS.forEach((buttonConfig) => {
        const button = navButtonsData[buttonConfig.name];
        if (button) button.caption = tripAdvisorCaption;
      });
    }

    if (hasBazaarVoiceNode(rawData)) {
      const BazaarVoicePostCaption = getBazaarVoicePostCaption(rawData);
      const BazaarVoiceNextCaption = getBazaarVoiceNextCaption(rawData);
      BAZAAR_VOICE_BUTTONS.forEach((buttonConfig) => {
        const button = navButtonsData[buttonConfig.name];
        if (button) {
          button.caption = BazaarVoiceNextCaption;
          button.domId = 'doNotPostToBazaarVoice';
          button.name = 'doNotPostToBazaarVoice';
        }
      });
      // Add review submitButton
      const normalizedButton = {
        name: 'submitButton',
        caption: BazaarVoicePostCaption,
        formKey: 'postToBazaarVoice',
        domId: 'postToBazaarVoice'
      };
      navButtonsData.submitButton = normalizedButton;
    }

    navButtonsData.numOfButtons = Object.keys(navButtonsData).length;

    return {
      ...normalizedData,
      navigation: navButtonsData
    };
  }
}
