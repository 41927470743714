import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import forEachRight from 'lodash/forEachRight';
import Question from '../../../commons/Question';
import Dropdown from '../../../commons/Dropdown/';
import layoutStyles from '../../../css/layout.scss';
import styles from './nestedDropdown.scss';

import { isDropdownAccessibilityEnabled, isSearchableDropdownEnabled } from '../../../services/featureFlags';

const {
  arrayOf, bool, number, string, shape
} = PropTypes;

export default class NestedDropdown extends Component {
  static propTypes = {
    nddId: string,
    validationEmpty: string,
    validationFailed: string,
    requiredField: string,
    caption: string,
    showFullNDD: bool,
    progressiveDisclosure: bool,
    /**
     * Array of dropdowns. Each object describe a level of dropdown, which should be taken from the `level` property,
     * not the index of that object.
     */
    dropdown: arrayOf(shape({
      hideAlways: bool,
      caption: string,
      level: number,
      alt: arrayOf(shape({
        id: string,
        active: bool,
        text: string
      })),
      id: string
    })),
    /**
     * Array of ids for each dropdown. The position in the array matches the level of said dropdown,
     * being 0 the lowest level (no children)
     * Note: the order of this array doesn't match the order of the `dropdown` property,
     * which is actually reversed. This is the only array reversed.
     */
    dropdowns: arrayOf(string),
    /**
     * The links property describes the parent-children relations between options.
     * For each dropdown, we'll have a map with:
     * - unitId for each option that may be displayed at that level
     * - an array of unitIds, that are the 'children' of that unit (hence, becoming the "next level options")
     *
     * Only the dropdowns that have children are described (as in: not the lowest level)
     * Format is: map<dropdownId, map<unitId, arrayOf(unitId)>>
     */
    links: PropTypes.objectOf(PropTypes.objectOf(PropTypes.arrayOf(string)))
  };

  static defaultProps = {
    nddId: '',
    validationEmpty: '',
    validationFailed: '',
    requiredField: '',
    caption: '',
    showFullNDD: true,
    progressiveDisclosure: false,
    dropdown: [],
    dropdowns: []
  };

  constructor(props) {
    super(props);

    this.initializeDropdowns();

    this.getParentChain = this.getParentChain.bind(this);
    this.handleChangeCurrentValue = this.handleChangeCurrentValue.bind(this);
    this.getNextLevelOptions = this.getNextLevelOptions.bind(this);
  }

  /**
   * Sets the initial values for originalDropdownList, and the state.
   * This method is meant to be called inside the constructor,
   * hence the this.state assignment.
   */
  initializeDropdowns() {
    const value = [];
    const currentDropdownList = [];
    const activeDropdownListItem = [];

    this.originalDropdownList = [];
    forEach(this.props.dropdown, (dropdown, index) => {
      const level = dropdown.level;

      this.originalDropdownList[level] = [...dropdown.alt];
      activeDropdownListItem[level] = dropdown.alt.length === 1 ? dropdown.alt[0] : dropdown.alt.find(dropdownList => dropdownList.active) || {};
      value[level] = activeDropdownListItem[level] && (activeDropdownListItem[level].id || '');

      if (this.props.progressiveDisclosure && index !== 0) {
        currentDropdownList[level] = [];
      } else {
        currentDropdownList[level] = dropdown.alt;
      }
    });

    this.state = {
      value,
      currentDropdownList,
      activeDropdownListItem
    };
  }

  componentWillMount() {
    // If there are active elements, select them.
    this.selectActiveItems(this.state.activeDropdownListItem);
  }

  /**
   * Select items, for a given state. This will select items in order, from root level to the last selected item.
   * This function is meant to be called when the component has mounted.
   * @param {array} items The items to display as selected
   */
  selectActiveItems(items) {
    // You'll have to traverse the list in reverse order, to simulate the normal usage,
    // as in: select top dropdown first, so the following dropdowns are filtered.
    forEachRight(items, (item, idx) => {
      if (item.id) {
        this.handleChangeCurrentValue(item.id, idx, true);
      }
    });
  }

  /**
   * Returns the parent chain for a given item, inside a dropdown.
   * - Both itemLevel and itemId (also treated as itemValue) are required to build the chain correctly.
   * - This chain also includes the passed item, in the first position of the array.
   * - Levels are ordered in a way that their values go higher the closer you get to the root of the tree (level 0 are items )
   * @param {string} itemId Identifier (or value) for the item
   * @param {number} itemLevel Item level, being 0 the lower levels (children only).
   */
  getParentChain(itemId, itemLevel) {
    const rootLevel = this.props.dropdowns.length - 1;
    const parentChain = [itemId];
    let dropdownId;
    let levelLinks;
    let lastChild = itemId;
    let possibleParents;

    for (let i = itemLevel + 1; i <= rootLevel; i++) {
      dropdownId = this.props.dropdowns[i];
      levelLinks = this.props.links[dropdownId];
      possibleParents = [];
      forEach(levelLinks, (value, key) => {
        if (includes(value, lastChild)) {
          possibleParents.push(key);
        }
      });

      if (possibleParents.length === 1) {
        // We found our match!
        parentChain.push(possibleParents[0]);
        lastChild = possibleParents[0];
      } else {
        // For both of these cases (no parents, or multiple parents),
        // we want to complete the parentChain with all the info we can.
        // So we exit the loop, and return parentChain as it is.
        break;
      }
    }
    return parentChain;
  }

  /**
   * For a given item, we return all the children options that belong to the next level.
   * @param {string} itemId Identifier for the item whose children we want to display as options
   * @param {number} itemLevel Level of the item. Higher levels mean closeness to the root.
   */
  getNextLevelOptions(itemId, itemLevel) {
    const dropdownId = this.props.dropdowns[itemLevel];
    const nextLevel = itemLevel - 1;

    // If there are options for that particular item...
    const childrenIds = this.props.links[dropdownId][itemId];
    if (childrenIds) {
      const allNextLevelOptions = this.originalDropdownList[nextLevel];

      return filter(allNextLevelOptions, option => includes(childrenIds, option.id));
    }
    // Fallback, in case we don't have options declared for that item
    return [];
  }

  /**
   * Handles the change of value in any of the dropdowns.
   * - Will autoselect parents, if possible.
   * - Will autoselect children, if the next levels have only one child.
   * - Will filter options for the lower levels, unless progressive disclosure is active.
   * @param {string} activeValue The value for the option selected. Tipically matches an id.
   * @param {number} level The level of the option selected
   * @param {bool} skipResetting Whether we should reset|filter the options in children levels
   */
  handleChangeCurrentValue(activeValue, level, skipResetting) {
    const {
      currentDropdownList,
      activeDropdownListItem,
      value
    } = this.state;

    // Get parents, reset filters for each level
    forEach(this.getParentChain(activeValue, level), (elementId, index) => {
      const elementLevel = level + index;
      const nextLevel = elementLevel - 1;

      value[elementLevel] = elementId;
      activeDropdownListItem[elementLevel] = find(this.originalDropdownList[elementLevel], p => (elementId === p.id));

      // Filter values in dropdown for the next level, if applies
      if (nextLevel >= 0) {
        currentDropdownList[nextLevel] = this.getNextLevelOptions(elementId, elementLevel);
      }
    });

    // traverse children options
    for (let i = level; i > 0; i--) {
      const nextLevel = i - 1;

      // reset next level
      if (!skipResetting) {
        activeDropdownListItem[nextLevel] = {};
        value[nextLevel] = '';
      }

      const activeId = this.props.dropdowns[i];

      if (this.props.progressiveDisclosure && !value[i]) {
        currentDropdownList[nextLevel] = [];
        /** TODO: Links are always provided. This check is not useful
         * In case of checking this other checks must be done in the code
         */
      } else if (this.props.links[activeId]) {
        const nextLevelOptions = this.getNextLevelOptions(value[i], i);
        currentDropdownList[nextLevel] = nextLevelOptions;

        // auto-select if only 1 item in the list
        if (nextLevelOptions.length === 1) {
          activeDropdownListItem[nextLevel] = currentDropdownList[nextLevel][0];
          value[nextLevel] = currentDropdownList[nextLevel][0].id;
        }
      }
    }

    this.setState({
      currentDropdownList,
      activeDropdownListItem,
      value
    });
  }

  render() {
    const {
      caption, validationEmpty, validationFailed, requiredField, dropdown
    } = this.props;
    return (
      <div className="questionBlock nestedDropdownQuestion">
        <fieldset>
          <Question validationEmpty={validationEmpty} validationFailed={validationFailed} requiredField={requiredField} caption={caption} />
          <div className={layoutStyles.answers}>
            {
              dropdown.map((dropdownListItem, idx) => {
                const { level, id } = dropdownListItem;
                const elementId = `dropdown-${id}`;
                const elementIdObject = isDropdownAccessibilityEnabled() ? { elementId } : {};

                return (
                  <div
                    key={idx}
                    className={classNames(styles.dropdownWrapper, {
                      [styles.dropdownHidden]: dropdownListItem.hideAlways,
                      [styles.isSearchableDropdownEnabled]: isSearchableDropdownEnabled()
                    })}
                  >
                    <Dropdown
                      containerStyles={layoutStyles.answer}
                      dropdownList={this.state.currentDropdownList[level]}
                      selected={this.state.activeDropdownListItem[dropdownListItem.level]}
                      onChange={value => this.handleChangeCurrentValue(value, level)}
                      label={dropdownListItem.caption}
                      {...elementIdObject}
                    />
                    <input type="hidden" id={dropdownListItem.id} name={dropdownListItem.id} value={this.state.value[level]} />
                  </div>
                );
              })
            }
          </div>
        </fieldset>
      </div>
    );
  }
}
