import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {Provider} from 'react-redux';
import PageTemplate from '../PageStructure/PageTemplate';

const {array, object} = PropTypes;

export default class App extends PureComponent {
  static propTypes = {
    questionBlocks: array,
    store: object,
    surveyData: object
  };

  static defaultProps = {
    questionBlocks: [],
    store: {},
    surveyData: {}
  };

  render() {
    const {questionBlocks, store, surveyData} = this.props;
    return (
      <Provider store={store}>
        <PageTemplate questionBlocks={questionBlocks} {...surveyData}/>
      </Provider>
    );
  }
}
