import { ReorderableList } from './DNDLayout/ReorderableList';

// one-based, like 1st, 2nd, 3rd
function findNthEmptyIndex(list, nth) {
  let count = 0;
  let i = 0;
  while (count < nth && i < list.length) {
    const value = list[i++];
    if (!value) count++;
  }

  if (count < nth && i === list.length) {
    return -1;
  }

  return i - 1;
}

// 0-based, like indices in an array
function countEmptyPositionsUntil(list, n) {
  let emptyPositionsCount = 0;
  let i = 0;
  while (i <= n && i < list.length) {
    const value = list[i++];
    if (!value) emptyPositionsCount++;
  }

  return emptyPositionsCount;
}

function fillWithNullUntil(list, n) {
  const filledList = [...list];
  let i = filledList.length;
  while (i < n) {
    filledList[i++] = null;
  }

  return filledList;
}

function insertAndShiftUntilEmptySpace(list, alternativeId, targetIndex) {
  const alternativeInTargetIndex = list[targetIndex];
  let reorderedList = [...list];
  if (alternativeInTargetIndex) {
    // based on KrisDK's idea
    reorderedList[targetIndex] = alternativeId;
    reorderedList = insertAndShiftUntilEmptySpace(
      reorderedList,
      alternativeInTargetIndex,
      targetIndex + 1
    );
  } else {
    reorderedList = fillWithNullUntil(reorderedList, targetIndex);
    reorderedList[targetIndex] = alternativeId;
  }

  return reorderedList;
}

function insertAndShiftUntilEmptySpaceInUnfiltered(
  alternativeId,
  targetIndexInFilteredList,
  filteredList,
  unfilteredList
) {
  const initialIndexInFilteredList = filteredList.indexOf(alternativeId);
  const initialIndexInUnfilteredList = unfilteredList.indexOf(alternativeId);
  const reorderedList = [...unfilteredList];

  if (initialIndexInUnfilteredList > -1) {
    reorderedList[initialIndexInUnfilteredList] = null;
  }

  if (!targetIndexInFilteredList) {
    return insertAndShiftUntilEmptySpace(reorderedList, alternativeId, 0);
  }

  const alternativeInTargetPositionOfFilteredList = filteredList[targetIndexInFilteredList - 1];
  let targetIndexInUnfilteredList;
  if (alternativeInTargetPositionOfFilteredList) {
    targetIndexInUnfilteredList = reorderedList.indexOf(alternativeInTargetPositionOfFilteredList);
  } else {
    let emptyPositionIndex = countEmptyPositionsUntil(
      filteredList,
      targetIndexInFilteredList - 1
    );
    if (initialIndexInFilteredList > -1 && targetIndexInFilteredList > initialIndexInFilteredList) {
      // the alternative was there and was replaced with null a few lines above
      emptyPositionIndex++;
    }
    targetIndexInUnfilteredList = findNthEmptyIndex(reorderedList, emptyPositionIndex);
  }

  return insertAndShiftUntilEmptySpace(
    reorderedList,
    alternativeId,
    targetIndexInUnfilteredList + 1
  );
}

function insertAtEmptySpace(
  alternativeId,
  targetIndexInFilteredList,
  filteredList,
  unfilteredList
) {
  let reorderedList;

  if (filteredList.length <= targetIndexInFilteredList) {
    const newList = fillWithNullUntil([], targetIndexInFilteredList - filteredList.length);
    const firstHalf = unfilteredList.slice(0, targetIndexInFilteredList);
    const secondHalf = unfilteredList.slice(targetIndexInFilteredList);
    reorderedList = firstHalf.concat(newList, alternativeId, secondHalf);
  } else {
    const emptyPositionIndex = countEmptyPositionsUntil(filteredList, targetIndexInFilteredList);
    const targetIndexInUnfilteredList = findNthEmptyIndex(unfilteredList, emptyPositionIndex);

    reorderedList = [...unfilteredList];
    reorderedList[targetIndexInUnfilteredList] = alternativeId;
  }

  return reorderedList;
}

function swapWithEmptySpace(
  alternativeId,
  targetIndexInFilteredList,
  filteredList,
  unfilteredList
) {
  let reorderedList = [...unfilteredList];
  const initialIndexInUnfilteredList = unfilteredList.indexOf(alternativeId);
  reorderedList[initialIndexInUnfilteredList] = null;

  if (filteredList.length <= targetIndexInFilteredList) {
    const newList = fillWithNullUntil([], targetIndexInFilteredList - filteredList.length);
    const firstHalf = reorderedList.slice(0, initialIndexInUnfilteredList + 1);
    const secondHalf = reorderedList.slice(initialIndexInUnfilteredList + 1);
    reorderedList = firstHalf.concat(newList, alternativeId, secondHalf);
  } else {
    const emptyPositionIndex = countEmptyPositionsUntil(filteredList, targetIndexInFilteredList);
    const targetIndexInUnfilteredList = findNthEmptyIndex(unfilteredList, emptyPositionIndex);

    reorderedList[targetIndexInUnfilteredList] = alternativeId;
  }

  return reorderedList;
}

function reorderList(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList) {
  const alternativeInTargetPositionOfFilteredList = filteredList[targetIndexInFilteredList];

  const initialIndexInUnfilteredList = unfilteredList.indexOf(alternativeId);
  const targetIndexInUnfilteredList = unfilteredList
    .indexOf(alternativeInTargetPositionOfFilteredList);

  return ReorderableList.defaultReorderHandler(
    unfilteredList,
    initialIndexInUnfilteredList,
    targetIndexInUnfilteredList
  );
}

// Here's the drill: alternatives in this component can be filtered out dynamically
// by having In-Page Conditions.
// This is complex situation where the components (who represent what the user sees)
// don't know about filtered alternatives and takes action (reordering after DnD for example)
// upon that.
// But we need to preserve previously choices made by the survey taker when hidden by IPC.
// So we need to do a best effort translation of what the user did in the filtered list and
// what would have done in unfiltered list, without IPC.
// And in that unfiltered list, we perform the action of the user (reorder, insertion, etc)
// The components will always receive filtered lists because they don't even know that IPC exists
// Of course, improvements are accepted.
/* eslint-disable max-len */
function setAlternativeAt(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList) {
  let reorderedList;

  const listHasNulls = unfilteredList.indexOf(null) !== -1;
  const initialIndexInFilteredList = filteredList.indexOf(alternativeId);
  const alternativeInTargetPositionOfFilteredList = filteredList[targetIndexInFilteredList];

  if (listHasNulls || initialIndexInFilteredList === -1 || targetIndexInFilteredList >= filteredList.length) {
    // Only Dropdown may have nulls
    if (alternativeInTargetPositionOfFilteredList) {
      // alternative is being inserted where another alternative already exists
      reorderedList = insertAndShiftUntilEmptySpaceInUnfiltered(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList);
    } else if (initialIndexInFilteredList === -1) {
      // alternative is being inserted for the first time in an empty space
      reorderedList = insertAtEmptySpace(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList);
    } else {
      // the alternative was already selected and is being moved to an empty space
      reorderedList = swapWithEmptySpace(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList);
    }
  } else {
    // it's a plain reordering
    // valid for both DnD and Dropdown modes
    reorderedList = reorderList(alternativeId, targetIndexInFilteredList, filteredList, unfilteredList);
  }

  return reorderedList;
}

export {
  findNthEmptyIndex,
  countEmptyPositionsUntil,
  insertAndShiftUntilEmptySpace,
  insertAndShiftUntilEmptySpaceInUnfiltered,
  insertAtEmptySpace,
  swapWithEmptySpace,
  reorderList,
  setAlternativeAt
};
