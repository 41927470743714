import isFinite from 'lodash/isFinite';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';
import { COMPARE_OPERATIONS } from './constant';
import evaluateNode from '../..';

export const OPERATION_EVALUATORS = {
  [COMPARE_OPERATIONS.EQUAL]: (left, right) => left === right,
  [COMPARE_OPERATIONS.GREATER]: (left, right) => left > right,
  [COMPARE_OPERATIONS.GREATER_OR_EQUAL]: (left, right) => left >= right,
  [COMPARE_OPERATIONS.LESS]: (left, right) => left < right,
  [COMPARE_OPERATIONS.LESS_OR_EQUAL]: (left, right) => left <= right
};

function compareConditionEvaluator(node, values) {
  const operationEvaluator = OPERATION_EVALUATORS[node.op];
  if (!operationEvaluator) throw new Error(`Evaluator not available for compare operation ${node.op}`);

  const { left, right } = node;
  const leftNode = evaluateNode(left, values);
  const rightNode = evaluateNode(right, values);

  const leftEval = parseInt(leftNode, 10);
  const rightEval = parseInt(rightNode, 10);

  if (!isFinite(leftEval) && !isFinite(rightEval)) return false;
  return operationEvaluator(leftEval, rightEval);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.COMPARISON, compareConditionEvaluator);

export default compareConditionEvaluator;
