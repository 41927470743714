import evaluateNode from '../..';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function notEvaluator(node, values) {
  const { child } = node;

  return !evaluateNode(child, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.NOT, notEvaluator);
export default notEvaluator;
