import { QUESTION_TYPES } from '../../question';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';

export const MATCH_HTML = 'rawhtml';

export default class HtmlLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_HTML;
    return shouldNormalize;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();
    const { content: caption } = questionData;
    resourcesManager.createOrUpdateQuestion({ id, caption, type: QUESTION_TYPES.HTML });
    resourcesManager.createCondition(questionData.condition, id);
    return resourcesManager.resources();
  }
}
