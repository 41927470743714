import TechnicallyNecessary from './TechnicallyNecessary';
import { withData } from '../../../../services/surveyDataProvider';

const mapSurveyDataToProps = (surveyData) => {
  const { title, message, cookiesInfo } = surveyData.cookieBanner.technicallyNecessaryCookiesInfo;
  const { cookieInfoLabels } = surveyData.cookieBanner;

  return {
    title,
    message,
    cookiesInfo,
    cookieInfoLabels
  };
};

export default withData(mapSurveyDataToProps)(TechnicallyNecessary);
