export default class ScrollService {
  constructor() {
    this.components = new Map();
  }

  subscribeComponent(componentId, node) {
    this.components.set(componentId, node);
  }

  scrollIntoComponent(componentId) {
    const elementTo = this.components.get(componentId);
    if (elementTo) {
      elementTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
