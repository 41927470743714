import PropTypes from 'prop-types';
import React from 'react';
import {
  LEGAL_NOTICE_PANEL_ID,
  COOKIE_CONSENT_PANEL_ID,
  TECHNICALLY_NECESSARY_PANEL_ID
}  from '../constants';
import LegalNotice from '../panels/LegalNotice';
import CookieConsent from '../panels/CookieConsent';
import TechnicallyNecessary from '../panels/TechnicallyNecessary';

const panels = {
  [LEGAL_NOTICE_PANEL_ID]: LegalNotice,
  [COOKIE_CONSENT_PANEL_ID]: CookieConsent,
  [TECHNICALLY_NECESSARY_PANEL_ID]: TechnicallyNecessary
};

const SecondaryPanel = ({ panel }) => {
  if (!panel) { return null; }

  const Panel = panels[panel];

  return <Panel />;
};

SecondaryPanel.propTypes = {
  panel: PropTypes.string.isRequired
};

export default SecondaryPanel;
