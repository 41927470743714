/* eslint-disable no-continue */
import padLeft from '../../utils/padLeft';

const SUNDAY = 0;
const SATURDAY = 6;
const NUMBER_OF_DAYS_IN_WEEK = 7;

export const YEAR_IDENTIFIER = 'Y';
export const YEAR_LONG_FORMAT = 'YYYY';
export const YEAR_SHORT_FORMAT = 'YY';

export const MONTH_IDENTIFIER = 'M';
export const MONTH_LONG_FORMAT = 'MMMM';
export const MONTH_MEDIUM_FORMAT = 'MMM';
export const MONTH_SHORT_FORMAT = 'MM';
export const MONTH_1CHAR_FORMAT = 'M';

export const DAY_IDENTIFIER = 'D';
export const DAY_LONG_FORMAT = 'DD';
export const DAY_SHORT_FORMAT = 'D';

export const WEEKDAY_IDENTIFIER = 'E';
export const WEEKDAY_LONG_FORMAT = 'EEEE';
export const WEEKDAY_MEDIUM_FORMAT = 'EEE';
export const WEEKDAY_SHORT_FORMAT = 'EE';
export const WEEKDAY_1CHAR_FORMAT = 'E';

const MONTH_REGEX = `\\b${MONTH_IDENTIFIER}+\\b`;
const DAY_REGEX = `\\b${DAY_IDENTIFIER}+\\b`;
const WEEKDAY_REGEX = `\\b${WEEKDAY_IDENTIFIER}+\\b`;
const YEAR_REGEX = `\\b${YEAR_IDENTIFIER}+\\b`;
export const ALL_IDENTIFIERS_REGEX = new RegExp(`${MONTH_REGEX}|${DAY_REGEX}|${WEEKDAY_REGEX}|${YEAR_REGEX}`, 'gi');
export const YEAR_MONTH_IDENTIFIER_REGEX = new RegExp(`${MONTH_REGEX}|${YEAR_REGEX}`, 'gi');

export const EMPTY_CALENDAR = Object.freeze([]);

export const MEDIUM_MONTHS = Object.freeze([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]);

export const LONG_MONTHS = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]);

export const LONG_WEEKDAYS = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]);

export const MEDIUM_WEEKDAYS = Object.freeze([
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
]);

export const SHORT_WEEKDAYS = Object.freeze([
  'Su',
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa'
]);

export const CHAR_WEEKDAYS = Object.freeze([
  'S',
  'M',
  'T',
  'W',
  'T',
  'F',
  'S'
]);

export const formatDay = (day, dayFormat = DAY_LONG_FORMAT) => {
  if (typeof day !== 'number') return `${day}`;
  const uppercaseDayFormat = `${dayFormat}`.toUpperCase();
  return (uppercaseDayFormat === DAY_LONG_FORMAT) ? padLeft(day, 2, '0') : `${day}`;
};


export const formatYear = (year, yearFormat = YEAR_LONG_FORMAT) => {
  if (typeof year !== 'number') return `${year}`;
  const uppercaseYearFormat = yearFormat.toUpperCase();
  return (uppercaseYearFormat === YEAR_SHORT_FORMAT) ? `${year}`.substring(2, 4) : `${year}`;
};

export const getNumberOfDaysInMonthAndWeekdayOfLastDay = (year, month) => {
  const dateOfLastDayOfNextMonth = new Date(year, month + 1, 0);
  return [dateOfLastDayOfNextMonth.getDate(), dateOfLastDayOfNextMonth.getDay()];
};

export const isDate = date => date instanceof Date;

export default class Calendar {
  constructor({
    dateFormat = 'EEEE MM/DD/YYYY',
    fullDateFormat = 'EEEE MM/DD/YYYY',
    calendarTitleFormat = 'MMMM YYYY',

    longMonths = LONG_MONTHS,
    mediumMonths = MEDIUM_MONTHS,

    longWeekdays = LONG_WEEKDAYS,
    mediumWeekdays = MEDIUM_WEEKDAYS,
    shortWeekdays = SHORT_WEEKDAYS,
    charWeekdays = CHAR_WEEKDAYS
  }) {
    this._dateFormat = dateFormat;
    this._fullDateFormat = fullDateFormat;
    this._calendarTitleFormat = calendarTitleFormat;

    this._translations = {
      [MONTH_LONG_FORMAT]: longMonths,
      [MONTH_MEDIUM_FORMAT]: mediumMonths,
      [WEEKDAY_LONG_FORMAT]: longWeekdays,
      [WEEKDAY_MEDIUM_FORMAT]: mediumWeekdays,
      [WEEKDAY_SHORT_FORMAT]: shortWeekdays,
      [WEEKDAY_1CHAR_FORMAT]: charWeekdays
    };

    /**
    * The general data structure of this._cachedCalendars
    *
    * {
    *   'year': {
    *     'month': [
    *       [ customDateStructureForTheWeek... ],
    *       [ customDateStructureForTheWeek... ],
    *       [ customDateStructureForTheWeek... ],
    *       [ customDateStructureForTheWeek... ],
    *       [ customDateStructureForTheWeek... ]
    *     ]
    *   }
    * }
    *
    * Check test _buildCalendarForYearMonth to see the structure of this._cachedCalendars
    */
    this._cachedCalendars = {};
  }

  getAllWeekdays(format = WEEKDAY_SHORT_FORMAT) {
    return this._translations[format];
  }

  formatMonth(month, monthFormat = MONTH_LONG_FORMAT) {
    if (typeof month !== 'number') return `${month}`;
    const uppercaseMonthFormat = `${monthFormat}`.toUpperCase();
    const translationsForMonthFormat = this._translations[uppercaseMonthFormat];
    if (translationsForMonthFormat) return translationsForMonthFormat[month];

    const humanReadableMonth = month + 1; // offset by 1 for human because January = 0
    return (monthFormat === MONTH_SHORT_FORMAT) ? padLeft(humanReadableMonth, 2, '0') : `${humanReadableMonth}`;
  }

  formatWeekDay(weekDay, weekDayFormat = WEEKDAY_SHORT_FORMAT) {
    if (typeof weekDay !== 'number') return '';
    const uppercaseWeekDayFormat = `${weekDayFormat}`.toUpperCase();
    const translationsForMonthFormat = this._translations[uppercaseWeekDayFormat];
    if (translationsForMonthFormat) return translationsForMonthFormat[weekDay];
    return `${weekDay}`;
  }

  formatDateForHuman(year, month, dayInMonth) {
    return this._convertDateToLabel({ year, month, dayInMonth });
  }

  getCalendarForDate(date) {
    if (!isDate(date)) return EMPTY_CALENDAR;
    const year = date.getFullYear();
    const month = date.getMonth();
    const cachedCalendar = this._getCachedCalendar(year, month);
    const isNotEmptyCalendar = cachedCalendar !== EMPTY_CALENDAR;
    if (isNotEmptyCalendar) return cachedCalendar;
    return this._buildCalendarForYearMonth(year, month);
  }

  getCalendarTitleForDate(
    date,
    unorderedYearMonthFormatSeparatedBySpace = this._calendarTitleFormat
  ) {
    if (!isDate(date)) return '';
    const uppercasedFormat = unorderedYearMonthFormatSeparatedBySpace.toUpperCase();
    const year = date.getFullYear();
    const month = date.getMonth();
    return uppercasedFormat.replace(YEAR_MONTH_IDENTIFIER_REGEX, (matchFormat) => {
      const isMonthMatched = matchFormat.includes(MONTH_IDENTIFIER);
      if (isMonthMatched) return this.formatMonth(month, matchFormat);

      const isYearMatched = matchFormat.includes(YEAR_IDENTIFIER);
      if (isYearMatched) return formatYear(year, matchFormat);
      return matchFormat;
    });
  }

  _cacheCalendar(year, month, calendar) {
    const cacheHasExistingValueForYearMonth = this._cachedCalendars[year] &&
      this._cachedCalendars[year][month];
    if (cacheHasExistingValueForYearMonth) return;

    const cacheDoesNotHaveYearDefaultValue = !this._cachedCalendars[year];
    if (cacheDoesNotHaveYearDefaultValue) this._cachedCalendars[year] = {};

    const cachedYear = this._cachedCalendars[year];
    if (!cachedYear[month]) cachedYear[month] = calendar;
  }

  _getCachedCalendar(year, month) {
    if (!year || !month) return EMPTY_CALENDAR;
    const cachedCalendar = this._cachedCalendars[year] && this._cachedCalendars[year][month];
    if (cachedCalendar) return cachedCalendar;
    return EMPTY_CALENDAR;
  }

  _convertDateToLabel({
    year, month, dayInMonth, dayInWeek, format = this._dateFormat
  }) {
    return format.replace(ALL_IDENTIFIERS_REGEX, (matchFormat) => {
      const isMonthMatched = matchFormat.includes(MONTH_IDENTIFIER);
      if (isMonthMatched) {
        return this.formatMonth(month, matchFormat);
      }

      const isYearMatched = matchFormat.includes(YEAR_IDENTIFIER);
      if (isYearMatched) {
        return formatYear(year, matchFormat);
      }

      const isWeekdayMatched = matchFormat.includes(WEEKDAY_IDENTIFIER);
      if (isWeekdayMatched) {
        return this.formatWeekDay(dayInWeek, matchFormat);
      }

      const isDayMatched = matchFormat.includes(DAY_IDENTIFIER);

      if (isDayMatched) {
        return formatDay(dayInMonth, matchFormat);
      }
      return matchFormat;
    }).trim();
  }

  _buildCustomDateStructure(year, month, dayInMonth, dayInWeek) {
    return {
      month,
      year,
      ariaLabel: this._convertDateToLabel({
        year, month, dayInMonth, dayInWeek, format: this._fullDateFormat
      }),
      day: dayInMonth,
      value: this.formatDateForHuman(year, month, dayInMonth)
    };
  }

  _buildCalendarForYearMonth(year, month) {
    const [numberOfDaysInMonth, dayOfLastDateOfMonth] =
      getNumberOfDaysInMonthAndWeekdayOfLastDay(year, month);

    const calendar = [];
    let numberOfDaysLeft = numberOfDaysInMonth;
    while (numberOfDaysLeft > 0) {
      const currentWeek = Array(NUMBER_OF_DAYS_IN_WEEK);

      for (let dayInWeek = SATURDAY; dayInWeek >= SUNDAY; dayInWeek--) {
        const isLastWeekOfMonth = calendar.length === 0;
        const isInNextMonthRange = isLastWeekOfMonth && dayInWeek > dayOfLastDateOfMonth;
        const isInPreviousMonthRange = numberOfDaysLeft === 0;
        if (isInNextMonthRange || isInPreviousMonthRange) {
          continue;
        } else {
          currentWeek[dayInWeek] =
            this._buildCustomDateStructure(year, month, numberOfDaysLeft, dayInWeek);
        }
        if (numberOfDaysLeft > 0) numberOfDaysLeft--;
      }
      calendar.unshift(currentWeek);
    }
    this._cacheCalendar(year, month, calendar);

    return calendar;
  }
}
