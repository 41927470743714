export function findTypeOfQuestion(questions, matchType) {
  let id;
  const matchingQuestion = questions.find((question, idx) => {
    const isOfType = question.blockType === matchType;
    if (!isOfType) return false;
    id = idx;
    return true;
  });
  return [id, matchingQuestion];
}

export function findQuestionWithMatch(questions, matchFunc) {
  let id;
  const questionFound = questions.find((question, idx) => {
    const isMatched = matchFunc(question);
    if (!isMatched) return false;
    id = idx;
    return true;
  });
  return [id, questionFound];
}

// eslint-disable-next-line consistent-return
export function shouldValidatorPass(predicateResult, severity, retries) {
  if (typeof predicateResult !== 'boolean') {
    throw new Error('shouldValidatorPass: predicateResult should be a boolean');
  }
  if (predicateResult) return true;
  if (!predicateResult && severity === 'error') return false;
  if (!predicateResult && severity === 'warning' && retries > 0) return false;
  if (!predicateResult && severity === 'warning' && retries === 0) return true;
}
