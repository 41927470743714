/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import stripHtml from '../../utils/stripHtml/index';

import Select from '../Select';

import styles from './dropdown.scss';
import { dropdownListType, selectedType } from './types';

const { bool, string, func } = PropTypes;

export default class Dropdown extends Component {
  static contextTypes = {
    translations: PropTypes.object
  };

  //----------------------------------------------------------------------
  // LIFECYCLE
  //----------------------------------------------------------------------

  constructor(props) {
    super(props);
    const options = this.getOptions();
    this.state = { options };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.dropdownList, this.props.dropdownList)) {
      const newOptions = this.getOptions();
      this.setState({ options: newOptions });
    }
  }

  //----------------------------------------------------------------------
  // UTILS
  // ----------------------------------------------------------------------

  getValue = () => {
    if (!isNil(this.props.selected)) {
      const item = this.props.dropdownList.find(option => option.id === this.props.selected.id);

      // TODO: Improve this.
      if (!isNil(item)) {
        return this.normalizeDropdownData(item);
      }

      return null;
    }

    return null;
  };

  getOptions = () => this.props.dropdownList.map(item =>
    this.normalizeDropdownData(item));

  normalizeSelectData = item => ({
    id: item.value,
    text: item.label
  });

  normalizeDropdownData = item => ({
    value: item.id,
    label: stripHtml(item.text)
  });

  //----------------------------------------------------------------------
  // EVENTS
  //----------------------------------------------------------------------

  onChange = (item) => {
    const mappedItem = this.normalizeSelectData(item);
    this.props.onChange(mappedItem.id);
  };

  //----------------------------------------------------------------------
  // RENDER
  //----------------------------------------------------------------------

  render() {
    const { isDropdownLabelInactive, label, elementId } = this.props;
    const disabled = this.props.dropdownList.length === 0;
    const labelClasses = classNames(styles.dropdownLabel, {
      [styles.dropdownLabel_isInactive]: isDropdownLabelInactive
    });

    return (
      <div>
        {label && (
          <label htmlFor={elementId} className={labelClasses}>
            {label}
          </label>
        )}

        <Select
          aria-label={label}
          id={elementId}
          isDisabled={disabled}
          options={this.state.options}
          value={this.getValue()}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

Dropdown.propTypes = {
  dropdownList: dropdownListType,
  label: string,
  isDropdownLabelInactive: bool,
  selected: selectedType,
  onChange: func,
  elementId: string
  // requiredField: string,
  // validationEmpty: string,
  // validationFailed: string,
  // isMultiValued: bool,
};

Dropdown.defaultProps = {
  dropdownList: [],
  label: '',
  isDropdownLabelInactive: false,
  selected: null,
  onChange: noop,
  elementId: ''
  // requiredField: '',
  // validationEmpty: '',
  // validationFailed: '',
  // isMultiValued: false,
};
