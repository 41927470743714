import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './indicatorCircle.scss';

export default function IndicatorCircle(props) {
  if (!props.shouldRender) return null;
  const { caption, containerStyles, isSelected } = props;
  const mainContainerStyles = classNames(styles.indicatorCircleContainer, containerStyles, 'indicatorCircle');
  const circleStyles = classNames(styles.circle, { [styles.circle_isSelected]: isSelected });
  const labelStyles = classNames(styles.label, { [styles.label_isSelected]: isSelected });
  return (
    <div aria-hidden className={mainContainerStyles}>
      <div className={circleStyles} />
      <span className={labelStyles} dangerouslySetInnerHTML={{__html: caption}} />
    </div>
  );
}

const { bool, string } = PropTypes;

IndicatorCircle.propTypes = {
  caption: string,
  containerStyles: string,
  isSelected: bool,
  shouldRender: bool
};

IndicatorCircle.defaultProps = {
  shouldRender: true
};
