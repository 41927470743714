/* eslint-disable no-continue */
import stripHtml from '../../../utils/stripHtml';
import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';
import { MATCH_TOOLTIP, extractTooltipData } from '../tooltip';
import { MATCH_TEXTFIELD, MATCH_TEXTAREA, extractTextfieldData } from '../textfield';
import { createEqConditionBlock } from '../../../services/conditionGenerator';

export const MATCH_LONGFORM = 'longform';
export const MATCH_NDD_LONGFORM = 'nestedDropdowns';
export const MATCH_LONGFORM_SINGLE_SELECT = 'radioRow';
export const MATCH_LONGFORM_MULTI_SELECT = 'check-row';
export const MATCH_LONGFORM_GROUP = 'group';
export const MATCH_LONGFORM_NESTED_CONTENT = 'groupSimple';

export const stripV6Artifact = (parts) => {
  // there is always an empty array at the end from v6.
  // We're going to pop it and check.
  // Remove this in the future when it is no longer necessary and the JSON
  // has been cleaned up
  const migrationArtifactFromV6 = parts.pop();
  if (!migrationArtifactFromV6) return parts;
  let newParts = parts;
  if (migrationArtifactFromV6.constructor !== Array) {
    // this is the case when the artifact has been cleaned up by backend
    newParts = [...newParts, migrationArtifactFromV6];
  }
  return newParts;
};

export const extractNestedTextfieldAndTooltip = ({ hasContent, content, questionId }) => {
  if (!hasContent) return {};

  let tooltip;
  let textfield;

  if (MATCH_LONGFORM_NESTED_CONTENT === content.blockType) {
    content.parts.forEach((tooltipOrTextfield) => {
      const { blockType } = tooltipOrTextfield;
      if (MATCH_TOOLTIP === blockType) {
        tooltip = extractTooltipData(tooltipOrTextfield);
      }
      if (MATCH_TEXTFIELD === blockType || MATCH_TEXTAREA === blockType) {
        textfield = extractTextfieldData(questionId, tooltipOrTextfield);
        textfield.caption = tooltipOrTextfield.caption;
        textfield.blockType = blockType;
      }
    });
  } else {
    textfield = extractTextfieldData(questionId, content);
    textfield.caption = content.caption;
    textfield.blockType = content.blockType;
  }

  return { tooltip, textfield };
};

export const determineTypeOfLongform = (firstOption) => {
  if (firstOption) {
    switch (firstOption.blockType) {
      case MATCH_LONGFORM_MULTI_SELECT:
        return {
          isSingleSelect: false,
          isMultiSelect: true,
          htmlInputType: HTML_INPUT_TYPES.CHECKBOX
        };

      default:
      case MATCH_LONGFORM_SINGLE_SELECT:
        return {
          isSingleSelect: true,
          isMultiSelect: false,
          htmlInputType: HTML_INPUT_TYPES.RADIO
        };
    }
  } else {
    return {
      isSingleSelect: false,
      isMultiSelect: false,
      htmlInputType: null
    };
  }
};

export const extractQuestion = ({
  id,
  caption,
  isMultiSelect,
  isSingleSelect,
  requiredField,
  selectAllThatApplyMessage,
  validationEmpty,
  validationFailed,
  blockType,
  isMultiSelectWithManyOptions
}) => {
  const isRequired = Boolean(requiredField || (validationFailed && validationEmpty));
  const hasValidationFailure = Boolean(validationFailed);
  const hasValidationFailureForEmpty = Boolean(validationEmpty);
  const ariaLabel = isMultiSelect ?
    stripHtml(`${caption} ${selectAllThatApplyMessage}`).trim() :
    stripHtml(caption).trim();
  const type =
    blockType === MATCH_NDD_LONGFORM ?
      QUESTION_TYPES.NND_LONGFORM :
      QUESTION_TYPES.LONGFORM;

  return {
    id,
    ariaLabel,
    caption,
    isRequired,
    isSingleSelect,
    isMultiSelect,
    hasValidationFailure,
    hasValidationFailureForEmpty,
    selectAllThatApplyMessage,
    type,
    isMultiSelectWithManyOptions
  };
};

export const extractGroupOption = ({ group, question }) => ({
  caption: group.caption,
  hasChildren: true,
  question: question.id
});

export const extractHtmlInput = ({ optionData, question, htmlInputType }) => {
  const { formValue } = optionData;
  const formKey = String(optionData.formKey);
  const checked = Boolean(optionData.checked);
  return {
    id: formKey,
    type: htmlInputType,
    value: checked ? formValue : undefined,
    possibleValues: new Set([formValue]),
    question: question.id
  };
};

export const extractGroups = ({ parts = [] }) => {
  const hasNoGroups = (parts[0] || {}).blockType !== MATCH_LONGFORM_GROUP;
  if (hasNoGroups) {
    return [{
      caption: '',
      options: stripV6Artifact(parts)
    }];
  }

  const groups = [];
  for (let i = 0; i < parts.length; i++) {
    const groupData = parts[i];
    if (groupData.constructor === Array) continue;
    groups.push({
      caption: groupData.caption,
      options: stripV6Artifact(groupData.parts)
    });
  }
  return groups;
};

export const extractOptionAndNestedTextfield = ({
  optionData, groupOption, question, htmlInput
}) => {
  const { isMultiSelect } = question;
  const {
    checked, content, formValue, offValue, hasContent
  } = optionData;

  const textfieldAndTooltip = extractNestedTextfieldAndTooltip({
    hasContent,
    content,
    questionId: question.id
  });
  const { textfield } = textfieldAndTooltip;
  let { tooltip } = textfieldAndTooltip;

  const caption = textfield && textfield.caption ? textfield.caption : optionData.caption;
  if (textfield && textfield.caption) {
    textfield.caption = '';
  }
  const hasTooltipChild = Boolean(tooltip);
  if (hasTooltipChild) {
    tooltip = {
      tooltipCaption: tooltip.caption,
      tooltipImagePath: tooltip.imagePath,
      tooltipAriaLabel: tooltip.ariaLabel
    };
  }
  const ariaLabel = stripHtml(caption).trim();

  const hasOtherOption = Boolean(textfield);
  const otherOptionType = hasOtherOption ? textfield.blockType : null;

  return {
    optionData: {
      ariaLabel,
      caption,
      hasOtherOption,
      otherOptionType,
      hasParent: true,
      hasTooltipChild,
      htmlInput: htmlInput.id,
      isMultiSelect,
      parent: groupOption.id,
      question: question.id,
      value: formValue,
      offValue,
      hasAnswer: checked,
      ...tooltip
    },
    textfieldData: textfield
  };
};

export const createOtherTextFakeCondition = createEqConditionBlock;

export default class Longform {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize =
      questionData.blockType === MATCH_LONGFORM ||
      questionData.blockType === MATCH_NDD_LONGFORM;
    return shouldNormalize;
  }

  normalize({ id, questionData, rawData = {} }) {
    if (!this.shouldNormalize({ questionData })) return undefined;

    const resourcesManager = new ResourcesRelationshipManager();
    const translations = rawData.translations || {};
    const selectAllThatApplyMessage = translations.SELECT_ALL_THAT_APPLY_MESSAGE;

    const groups = extractGroups(questionData);
    const firstNonEmptyGroup = groups.find(group => group.options && group.options.length);
    const firstNonEmptyOption = firstNonEmptyGroup &&
      firstNonEmptyGroup.options.find(option => option);
    const { isSingleSelect, isMultiSelect, htmlInputType } =
      determineTypeOfLongform(firstNonEmptyOption);
    let isMultiSelectWithManyOptions = false;
    if (isMultiSelect && groups.length > 0 && groups[0].options.length > 1) {
      isMultiSelectWithManyOptions = true;
    }

    const question = resourcesManager.createOrUpdateQuestion(extractQuestion({
      id,
      htmlInputType,
      isMultiSelect,
      isSingleSelect,
      selectAllThatApplyMessage,
      numOfGroups: groups.length,
      ...questionData,
      isMultiSelectWithManyOptions
    }));

    resourcesManager.createCondition(questionData.condition, id);

    let initialValue = null;
    const allOptionsLength = groups
      .reduce((carry, group) => [...carry, ...group.options], [])
      .length;
    let optionPosition = 1;
    groups.forEach((group) => {
      const groupOptionParams = extractGroupOption({ group, question });
      const groupOption = resourcesManager.createOrUpdateOption(groupOptionParams);

      group.options.forEach((optData) => {
        const htmlInputParams = extractHtmlInput({
          optionData: optData,
          question,
          htmlInputType
        });
        const htmlInput = resourcesManager.createOrUpdateHtmlInput(htmlInputParams);

        const optionAndMaybeNestedTextfield = extractOptionAndNestedTextfield({
          optionData: optData,
          groupOption,
          question,
          htmlInput,
          isMultiSelectWithManyOptions
        });
        const { optionData, textfieldData } = optionAndMaybeNestedTextfield;

        const option = resourcesManager.createOrUpdateOption(optionData);
        question.options.delete(option.id);

        resourcesManager.createCondition(optData.condition, option.id);

        option.ariaSetSize = allOptionsLength;
        option.ariaPosInSet = optionPosition++;

        // Longform with multiselect mode is a list of checkboxes, but each checkbox
        // has a different field
        if (optionData.isMultiSelect) {
          const multiInitialValue = optionData.hasAnswer ? optionData.value : optionData.offValue;
          resourcesManager.createField(optData.fieldName, multiInitialValue, id, option.id);
        } else {
          resourcesManager.createAlternative(
            firstNonEmptyOption.fieldName,
            optionData.value,
            option.id
          );
          if (optionData.hasAnswer) {
            initialValue = optionData.value;
          }
        }

        if (textfieldData) {
          resourcesManager.createOrUpdateHtmlInput({
            id: textfieldData.htmlInput,
            disableAutocomplete: Boolean(textfieldData.disableAutocomplete),
            hasValidationFailure: textfieldData.hasValidationFailure,
            hasValidationFailureForEmpty: textfieldData.hasValidationFailureForEmpty,
            isRequired: textfieldData.isRequired,
            question: question.id,
            type: HTML_INPUT_TYPES.TEXT,
            value: textfieldData.value || ''
          });

          const textfieldOption = resourcesManager.createOrUpdateOption(textfieldData);

          const textInitialValue = textfieldOption.hasAnswer ? textfieldOption.value : '';
          resourcesManager.createField(
            textfieldData.fieldName,
            textInitialValue,
            id,
            textfieldOption.id
          );

          // An admin may write a condition based on this text field
          // but without considering whether the selected answer is "Other"
          // as part of the condition
          // We need to fake a condition here :/
          if (optData.fieldName) {
            const fakeCondition = createOtherTextFakeCondition(optData.fieldName, optionData.value);
            resourcesManager.createCondition(fakeCondition, textfieldOption.id);
          }

          textfieldOption.hasParent = true;
          textfieldOption.parent = option.id;
          question.options.delete(textfieldOption.id);

          option.caption = textfieldOption.caption || option.caption;
          textfieldOption.caption = '';

          option.options.add(textfieldOption.id);
          option.hasChildren = true;
        }
      });
    });

    if (!question.isMultiSelect && firstNonEmptyOption) {
      resourcesManager.createField(firstNonEmptyOption.fieldName, initialValue, id);
    }

    return resourcesManager.resources();
  }
}
