/**
 * There are some cases where we need to add a fake condition to some components.
 * A fake condition looks and works exactly like a real condition, but it was not configured by
 * the admin, i.e., it's not part of the spec.
 *
 * For the time being, not all generators are needed. We'll add them on demand.
 * Also, if the need arise to have more generators, we should also improve the architecture.
 */
import isNil from 'lodash/isNil';
import eqGenerator, { eqConditionStringGenerator } from './generators/eq';
import multiValuedSetGenerator, { multiValuedSetConditionStringGenerator } from './generators/multiValuedSet';

function createEqConditionBlock(field, value) {
  if (!field || isNil(value)) return null;

  return {
    condition: eqGenerator(field, value),
    fields: {},
    condition_string: eqConditionStringGenerator(field, value)
  };
}

function createMultiValuedSetAllConditionBlock(field, ...values) {
  if (!field || !values.length) return null;

  return {
    condition: multiValuedSetGenerator(field, values, 'all'),
    fields: {},
    condition_string: multiValuedSetConditionStringGenerator(field, values, 'all')
  };
}

export {
  createEqConditionBlock,
  createMultiValuedSetAllConditionBlock
};
