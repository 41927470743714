import flatten from 'lodash/flatten';
import get from 'lodash/get';
import difference from 'lodash/difference';
import uniqueId from 'lodash/uniqueId';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { NODE_TYPES } from '../../services/conditionEngine/constants';

const childrenProperties = [
  'left',
  'right',
  'child',
  'children',
  'haystack',
  'needle'
];

function extendNodeWithMetadata(node) {
  if (!isObject(node)) return node;

  const extendedNode = {
    ...node,
    id: uniqueId('conditionNode_')
  };
  const fieldsForEvaluationSet = new Set();

  if (node.type === NODE_TYPES.FIELD || node.type === NODE_TYPES.MULTI_VALUE_FIELD) {
    // if node is type field or multivalue field,
    // the only field needed for evaluation is the one declared in the node
    fieldsForEvaluationSet.add(node.field);
  } else {
    // For other type of nodes, we need to check the child nodes to figure out which are
    // the fields required for evaluation.
    childrenProperties.forEach((property) => {
      if (extendedNode[property]) {
        const childNodes = [].concat(extendedNode[property]);

        // We need to extend all child nodes to get their fieldsForEvaluation
        const extendedChildNodes = childNodes.map(extendNodeWithMetadata);

        flatten(extendedChildNodes
          .map(extendedChildNode => extendedChildNode.fieldsForEvaluation))
          .forEach(field => fieldsForEvaluationSet.add(field));

        // Extend the current node and all its children.
        extendedNode[property] = isArray(extendedNode[property]) ?
          extendedChildNodes :
          extendedChildNodes[0];
      }
    });
  }

  extendedNode.fieldsForEvaluation = Array.from(fieldsForEvaluationSet);

  return extendedNode;
}

function getFieldsForEvaluation(node) {
  return (node && node.fieldsForEvaluation) ? node.fieldsForEvaluation : [];
}

function getStaticFields(fields = []) {
  if (!isArray(fields)) return {};

  return fields.reduce((staticFields, field) => {
    // eslint-disable-next-line no-param-reassign
    staticFields[field.field] = field.value.value;
    return staticFields;
  }, {});
}

function createCondition(condition) {
  if (!condition) return null;

  const newCondition = {
    id: uniqueId('condition_'),
    conditionAST: extendNodeWithMetadata(get(condition, 'condition', {})),
    staticFields: getStaticFields(condition.fields),
    conditionString: get(condition, 'condition_string', ''),
    inPageFields: []
  };

  newCondition.inPageFields = difference(
    [...getFieldsForEvaluation(newCondition.conditionAST)],
    Object.keys(newCondition.staticFields)
  );

  return newCondition;
}

export {
  createCondition,
  extendNodeWithMetadata,
  getFieldsForEvaluation,
  getStaticFields
};

export default createCondition;
