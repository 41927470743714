import HtmlInput from '../../../../models/htmlInput';

export default function textarea(state, action, htmlInput) {
  let { value = '' } = action.payload;
  value = value.substring(0, htmlInput.maxChars);
  const newHtmlInput = new HtmlInput({ ...htmlInput, value });
  return {
    ...state,
    [newHtmlInput.id]: newHtmlInput
  };
}
