import { NODE_TYPES } from '../../constants';

function constantGenerator(value) {
  return {
    type: NODE_TYPES.CONSTANT,
    value: `${value}`
  };
}

export default constantGenerator;
