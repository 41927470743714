/* eslint-disable react/forbid-component-props */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import Question from '../../commons/Question';
import layoutStyles from '../../css/layout.scss';
import RowWrapper from './RowWrapper';
import styles from './matrix.scss';
import rowWrapperStyles from './RowWrapper/rowWrapper.scss';
import ScrollableContent from '../../commons/ScrollableContent';
import Row, { ConditionedRow } from '../../commons/Table/Row';
import Cell, { ConditionedCell } from '../../commons/Table/Cell';
import Table from '../../commons/Table';
import AnchorBar from './AnchorBar';
import Anchor from '../RatingGrid/Anchor';

class Matrix extends React.Component {
  constructor(props) {
    super(props);
  }

  getRowCaption(row) {
    const { id: questionId } = this.props;
    const { id, caption } = row;
    return (
      <ConditionedRow id={id} key={`desktopRowTitle_${id}`}>
        <div id={`desktopRowTitle_${questionId}_${id}`} className={styles.leftCaptionContainer}>
          <span
            data-test-name="desktop-caption"
            id={`matrix-label-row_${id}`}
            className={styles.topCaption}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </div>
      </ConditionedRow>
    );
  }

  getMobileRowCaption(row) {
    const { id, caption } = row;
    return (
      <ConditionedRow id={id} key={`mobileRowTitle_${id}`}>
        <Cell data-test-name="mobile-caption" containerStyles={styles.topCaptionContainer} >
          <span
            id={id}
            className={styles.topCaption}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </Cell>
      </ConditionedRow>
    );
  }

  /** When two vertically continuous cells have the same anchors, the anchors
   *  are shown only obove the first one. See getInternalAnchors in Matrix container */

  getColumnCaptionByRow(row, idx) {
    const { internalAnchors = {} } = this.props;
    const { id, cells } = row;
    const anchorCellStyles = classNames(rowWrapperStyles.cell, rowWrapperStyles.internalAnchorCell);
    const anchorsContainerStyles = classNames('anchors', 'anchors_isFirstRow');
    const elements = cells.map((cell) => {
      const { columnId } = cell;
      const {
        anchors = [], shouldShow, shouldJustify, naOptionCaption
      } = get(internalAnchors[columnId], idx, {});

      const anchorBar = anchors.map((caption, anchorIdx) => (
        <Anchor key={`anchor_${columnId}_${anchorIdx}`} caption={caption} isJustified={shouldJustify} />));
      if (naOptionCaption) {
        anchorBar.push((
          <Anchor key={`anchor_${id}_${columnId}_NA`} caption={naOptionCaption} containerStyles={rowWrapperStyles.internalAnchorCellNA} />
        ));
      }
      return (
        <ConditionedCell id={columnId} key={`internalAnchor_cell_${id}_${columnId}`} containerStyles={anchorCellStyles}>
          <Table containerStyles={anchorsContainerStyles}>
            <Row>
              {shouldShow ? anchorBar : ''}
            </Row>
          </Table>
        </ConditionedCell>
      );
    });

    return (
      <ConditionedRow containerStyles={`internalAnchor_row_${id}`} key={`internalAnchor_row_${id}`}>
        {elements}
      </ConditionedRow>
    );
  }

  createRowElement(row, questionCaption) {
    const {
      id, hasValidationFailure, hasValidationFailureForEmpty, isRequired
    } = this.props;

    const commonProps = {
      questionId: id, hasValidationFailure, hasValidationFailureForEmpty, isRequired, questionCaption
    };

    return <RowWrapper key={row.id} id={row.id} {...commonProps} {...row} />;
  }

  createDesktopMatrix(questionCaption) {
    const { id, rows } = this.props;

    // Row captions at left for desktop. They are displayed one under the other in the first column
    const rowCaptions = [];
    const elements = flatten(rows.map((row, idx) => {
      const rowWrapper = this.createRowElement(row, questionCaption);
      rowCaptions.push(this.getRowCaption(row));
      return [this.getColumnCaptionByRow(row, idx), rowWrapper];
    }));

    return [
      <div key="captions" className={styles.rowCaptions}>{rowCaptions}</div>,
      <ScrollableContent className={styles.rowContent} key="scrollableContent">
        <Table hasFixedLayoutStyle={false}>
          <AnchorBar questionId={id} />
          {elements}
        </Table>
      </ScrollableContent>
    ];
  }

  createMobileMatrix(questionCaption) {
    const { rows } = this.props;
    const elements = flatten(rows.map((row) => {
      const rowWrapper = this.createRowElement(row, questionCaption);
      return [this.getMobileRowCaption(row), rowWrapper];
    }));

    return (
      <Table hasFixedLayoutStyle={false}>
        {elements}
      </Table>
    );
  }

  createMatrix(questionCaption) {
    if (this.props.isMobileMedia) {
      return this.createMobileMatrix(questionCaption);
    }
    return this.createDesktopMatrix(questionCaption);
  }


  render() {
    const {
      id,
      caption,
      isRequired,
      hasValidationFailure,
      hasValidationFailureForEmpty,
      isMobileMedia
    } = this.props;

    const questionId = id !== null ? id.toString() : '';
    const containerStyles =
      classNames(layoutStyles.answers, { [styles.container]: !isMobileMedia });

    return (
      <div id={id} className={"questionBlock"}>
        <Question
          caption={caption}
          hasValidationFailure={hasValidationFailure}
          hasValidationFailureForEmpty={hasValidationFailureForEmpty}
          isRequired={isRequired}
          captionId={questionId}
        />
        <div className={containerStyles}>
          {this.createMatrix(caption)}
        </div>
      </div>
    );
  }
}

const {
  bool,
  number,
  string,
  arrayOf,
  object
} = PropTypes;

Matrix.propTypes = {
  id: number,
  caption: string,
  rows: arrayOf(PropTypes.object),
  internalAnchors: object,
  hasValidationFailure: bool,
  hasValidationFailureForEmpty: bool,
  isRequired: bool,
  isMobileMedia: bool
};

Matrix.defaultProps = {
  id: null,
  caption: '',
  rows: [],
  internalAnchors: {},
  hasValidationFailure: false,
  hasValidationFailureForEmpty: false,
  isRequired: false,
  isMobileMedia: false
};

export default Matrix;
