import { connect } from 'react-redux';
import { compose } from 'redux';
import withCondition from '../../commons/withCondition';
import answerQuestion from '../../redux/actions/answerQuestion';
import Calendar from './Calendar';

function mapStateToProps(state, ownProps) {
  const question = (state.questions || {})[ownProps.id];
  const htmlInputId = question.htmlInputs.values().next().value;
  const htmlInput = (state.htmlInputs || {})[htmlInputId];
  const htmlInputValue = htmlInput.value || '';

  return { ...question, htmlInputId, htmlInputValue };
}

const mapDispatchToProps = { answerQuestion };

export default compose(
  withCondition,
  connect(mapStateToProps, mapDispatchToProps)
)(Calendar);
