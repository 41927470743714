import { compose } from 'redux';
import { connect } from 'react-redux';
import LanguageSelector from './LanguageSelector';
import { withData } from '../../services/surveyDataProvider';

export const mapSurveyDataToProps = surveyData => ({
  enabled: Object.keys(surveyData.cookieBanner).length > 0
});

export const mapStateToProps = ({ cookieBanner }, { enabled }) => ({
  isCookieBannerOpened: enabled && cookieBanner.isOpen
});

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps),
);

export default composedConnect(LanguageSelector);
