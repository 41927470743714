import get from 'lodash/get';
import answerQuestion from '../../redux/actions/answerQuestion';
import setComponentAnswer from '../../redux/actions/setComponentAnswer';

export const gridAnswerQuestion = params => (dispatch, getState) => {
  const state = getState();

  // Legacy answerQuestion action
  // It does support triggering the new action if the componentId and field are provided.
  // But the legacy action expects the value of the option and in the reducer
  // decides whether to use the value or deselect the option if the value received was
  // the same as before. This logic is repeated in two reducers and in that same action for
  // the Survey SDK and we're moving that logic here to have more simple reducers.
  dispatch(answerQuestion({
    ...params,
    shouldTriggerSurveyAPIEvents: false
  }));

  const { questionId, optionId, value } = params;
  const option = state.options[optionId];
  const optionHasAnswer = option.hasAnswer;
  const parentOption = state.options[option.parent];
  const field = state.fields[parentOption.field];
  let newValue;

  if (field.isMultiValued) {
    const prevValue = get(state, `components[${questionId}].values[${field.id}]`);
    newValue = optionHasAnswer ? prevValue.filter(val => val !== value) : prevValue.concat(value);
  } else {
    newValue = optionHasAnswer ? null : value;
  }

  // New state management action
  dispatch(setComponentAnswer(newValue, questionId, field.id));
};

export default gridAnswerQuestion;
