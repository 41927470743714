import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';
import Calendar, {
  YEAR_IDENTIFIER,
  MONTH_IDENTIFIER,
  DAY_IDENTIFIER,
  WEEKDAY_IDENTIFIER,
  ALL_IDENTIFIERS_REGEX
} from '../../calendar';

export const MATCH_CALENDAR = 'calendar';
export const getTranslationsForNextMonthAriaLabel = (translations = {}) =>
  translations.CAL_NEXT_MONTH || '';

export const getTranslationsForPrevMonthAriaLabel = (translations = {}) =>
  translations.CAL_PREV_MONTH || '';

export const getTranslationsForNotInTheFuture = (translations = {}) =>
  translations.CAL_NOT_IN_THE_FUTURE || '';

export const getCalendarIdentifiers = (translations = {}) => ({
  yearIdentifier: translations.CAL_YEAR_IDENTIFIER || YEAR_IDENTIFIER,
  monthIdentifier: translations.CAL_MONTH_IDENTIFIER || MONTH_IDENTIFIER,
  dayIdentifier: translations.CAL_DAY_IDENTIFIER || DAY_IDENTIFIER,
  weekdayIdentifier: translations.CAL_WEEKDAY_IDENTIFIER || WEEKDAY_IDENTIFIER
});

export const splitByCommaAndTrim = (string) => {
  if (typeof string !== 'string') return [];
  return string.split(',').map(word => word.trim());
};

export const getCalendarTitleFormat = translations =>
  (translations.MONTH_FORMAT || '').toUpperCase();

export const getCalendarAriaLabelFormat = translations =>
  (translations.CAL_ADA_DATE_FORMAT || '').toUpperCase();

export const getCalendarDateFormat = translations =>
  (translations.CAL_DATE_FORMAT || '').toUpperCase();

export const determineSelectedDate = (questionData) => {
  const { valueCanonical = '' } = questionData;
  let [month, day, year] = valueCanonical.split('/');
  month = parseInt(month, 10) || null;
  if (typeof month === 'number') month -= 1; // convert human month to computer month.
  day = parseInt(day, 10) || null;
  year = parseInt(year, 10) || null;
  return { day, year, month };
};

export const getTranslationsForCalendar = (translations = {}) => {
  const longMonths = splitByCommaAndTrim(translations.CAL_MONTHS_LONG);
  const shortMonths = splitByCommaAndTrim(translations.CAL_MONTHS_SHORT);

  const longWeekdays = splitByCommaAndTrim(translations.CAL_WEEKDAYS_LONG);
  const mediumWeekdays = splitByCommaAndTrim(translations.CAL_WEEKDAYS_MEDIUM);
  const shortWeekdays = splitByCommaAndTrim(translations.CAL_WEEKDAYS_SHORT);
  const charWeekdays = splitByCommaAndTrim(translations.CAL_WEEKDAYS_1CHAR);

  return {
    longMonths,
    shortMonths,

    longWeekdays,
    mediumWeekdays,
    shortWeekdays,
    charWeekdays
  };
};

export const createDateFormatPlaceholderLabel = (dateFormat, identifiers) => {
  const {
    yearIdentifier,
    monthIdentifier,
    dayIdentifier,
    weekdayIdentifier
  } = identifiers;
  if (!yearIdentifier || !monthIdentifier || !dayIdentifier || !weekdayIdentifier) return '';
  return dateFormat.replace(ALL_IDENTIFIERS_REGEX, (matchFormat) => {
    const isMonthMatched = matchFormat.includes(MONTH_IDENTIFIER);
    if (isMonthMatched) return monthIdentifier.repeat(matchFormat.length);

    const isYearMatched = matchFormat.includes(YEAR_IDENTIFIER);
    if (isYearMatched) return yearIdentifier.repeat(matchFormat.length);

    const isWeekdayMatched = matchFormat.includes(WEEKDAY_IDENTIFIER);
    if (isWeekdayMatched) return weekdayIdentifier.repeat(matchFormat.length);

    const isDayMatched = matchFormat.includes(DAY_IDENTIFIER);
    if (isDayMatched) return dayIdentifier.repeat(matchFormat.length);
    return matchFormat;
  });
};

export default class CalendarLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_CALENDAR;
    return shouldNormalize;
  }

  normalize({ id, questionData, rawData = {} }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();
    const translations = rawData.translations || {};
    const { textDirection } = rawData;
    const inverseLeftRightArrowMovement = textDirection === 'rtl';

    const calendarTranslations = getTranslationsForCalendar(translations);
    const calendarFullDateFormat = getCalendarAriaLabelFormat(translations);
    const calendarDateFormat = getCalendarDateFormat(translations);
    const calendarTitleFormat = getCalendarTitleFormat(translations);
    const nextMonthAriaLabel = getTranslationsForNextMonthAriaLabel(translations);
    const prevMonthAriaLabel = getTranslationsForPrevMonthAriaLabel(translations);
    const notInTheFutureAriaLabel = getTranslationsForNotInTheFuture(translations);
    const calendarIdentifiers = getCalendarIdentifiers(translations);
    const dateFormatPlaceholderLabel = createDateFormatPlaceholderLabel(
      calendarDateFormat,
      calendarIdentifiers
    );

    const calendar =  new Calendar({
      dateFormat: calendarDateFormat,
      calendarTitleFormat,
      ...calendarTranslations
    });

    const {
      caption,
      formkey,
      validationMessage,
      notInTheFuture
    } = questionData;

    const inputAriaLabel = notInTheFuture ?
      `${calendarDateFormat} ${notInTheFutureAriaLabel}`.trim() :
      `${calendarDateFormat}`.trim();

    const hasValidationFailure = Boolean(questionData.validationFailed);
    const hasValidationFailureForEmpty = questionData.validationEmpty === 'validationEmpty';
    const isRequired = Boolean(questionData.requiredField);

    const todayDate = new Date();
    const selectedDate = determineSelectedDate(questionData);
    const initSelectedValue = selectedDate.day ?
      calendar.formatDateForHuman(selectedDate.year, selectedDate.month, selectedDate.day) :
      '';
    const visibleCalendar = {
      month: selectedDate.month || todayDate.getMonth(),
      year: selectedDate.year || todayDate.getFullYear()
    };

    const question = resourcesManager.createOrUpdateQuestion({
      id,
      ariaLabelForCalendarButton: translations.CAL_BUTTON || '',
      ariaLabelForSelected: translations.CAL_SELECTED || '',
      calendarTranslations,
      calendarFullDateFormat,
      calendarDateFormat,
      calendarTitleFormat,
      caption,
      dateFormatPlaceholderLabel,
      hasValidationFailure,
      hasValidationFailureForEmpty,
      initSelectedDay: selectedDate.day,
      initSelectedValue,
      inputAriaLabel,
      isRequired,
      inverseLeftRightArrowMovement,
      nextMonthAriaLabel,
      notInTheFuture,
      prevMonthAriaLabel,
      type: QUESTION_TYPES.CALENDAR_DATE_PICKER,
      validationMessage,
      visibleCalendar
    });

    resourcesManager.createOrUpdateHtmlInput({
      id: formkey,
      type: HTML_INPUT_TYPES.TEXT,
      value: initSelectedValue,
      question: question.id,
      formkey
    });

    resourcesManager.createCondition(questionData.condition, id);

    return resourcesManager.resources();
  }
}
