// eslint-disable-next-line import/prefer-default-export
export const CARDS = [
  { id: 'card0', content: 'This is the card at index 0' },
  { id: 'card1', content: 'This is the card at index 1' },
  { id: 'card2', content: 'This is the card at index 2' },
  { id: 'card3', content: 'This is the card at index 3' },
  { id: 'card4', content: 'This is the card at index 4' }
];
export const GRABBED_ITEM_DROP = 'GRABBED_ITEM_DROP';
export const GRABBED_ITEM_MOVE = 'GRABBED_ITEM_MOVE';
export const REORDER_CANCELED = 'REORDER_CANCELED';
export const ITEM_GRABBED = 'ITEM_GRABBED';
export const VARIABLE_TO_REPLACE = '%v';

