import evaluateNode from '../..';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function substringEvaluator(node, values) {
  const { haystack, needle } = node;
  const left = evaluateNode(haystack, values);
  const right = evaluateNode(needle, values);
  if (!left || !right) {
    return false;
  }

  return left.toLowerCase().indexOf(right.toLowerCase()) >= 0;
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.SUBSTRING, substringEvaluator);
export default substringEvaluator;
