import isNil from 'lodash/isNil';
import { questionAnswered } from '../../services/api/events';
import { formKeyToQField }  from '../../utils/formKeyAndQFieldTransformations';
import { isSurveyAPIEnabled } from '../../services/featureFlags';
import setComponentAnswer from './setComponentAnswer';

export const ANSWER_QUESTION = 'ANSWER_QUESTION';

export default function answerQuestion({
  htmlInputId,
  questionId,
  optionId,
  value,
  error,
  meta,
  componentId,
  field,
  shouldTriggerSurveyAPIEvents = true,
  ...others
}) {
  return (dispatch, getState) => {
    if (process.env.NODE_ENV === 'development') {
      [questionId, htmlInputId, value].forEach((val) => {
        if (val === undefined || val === null) {
          throw new Error('answer must be defined!');
        }
      });
    }

    const currentState = getState();
    const question = currentState.questions[questionId] || {};
    const htmlInputs = currentState.htmlInputs || {};
    const { options } = currentState;

    const action = {
      type: ANSWER_QUESTION,
      payload: {
        questionId,
        htmlInputId,
        optionId,
        value,
        others,
        question,
        htmlInputs,
        options,
        componentId,
        field
      }
    };
    if (error) action.error = error;
    if (meta) action.meta = meta;
    dispatch(action);

    // As of today, component IDs are numbers, so 0 may be a valid ID
    if (!isNil(componentId)) {
      const fieldAsArgument = field || htmlInputId;
      dispatch(setComponentAnswer(value, componentId, fieldAsArgument));
      /** Avoid triggering the API event
       * because this is going to be handled by
       * setComponentAnswer function call
       */
      return action;
    }

    if (isSurveyAPIEnabled() && shouldTriggerSurveyAPIEvents) {
      const previousValue = htmlInputs[htmlInputId] ? htmlInputs[htmlInputId].value : undefined;

      let currentValue = value;
      if (htmlInputs[htmlInputId]) {
        // logic below is the same as the radio reducer:
        //   /src/redux/reducers/htmlInputs/radio/index.js
        // discuss best place for this logic, as it doesn't scale to have it here
        if (htmlInputs[htmlInputId].type === 'radio' && previousValue === value) {
          currentValue = undefined;
        }
      }
      questionAnswered(formKeyToQField(htmlInputId), currentValue, previousValue, action);
    }

    return action;
  };
}
