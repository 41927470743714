import isNil from 'lodash/isNil';

import IPCService from '../inPageConditioning';

import setComponentValidations from '../../redux/actions/setComponentValidations';
import setComponentValidation from '../../redux/actions/setComponentValidation';
import resetComponentValidations from '../../redux/actions/resetComponentValidations';

export default class ValidationsService {
  constructor() {
    this.store = null;
    this.components = new Map();
  }

  setStore(store) {
    this.store = store;
    return this;
  }

  init() {
    const { dispatch } = this.store;

    const components = Array.from(this.components.entries());

    components.forEach((component) => {
      const [id, validators] = component;
      const initialState = validators.map(validation => ({ retries: validation.retries }));

      setComponentValidations(id, initialState)(dispatch);
    });
  }

  subscribeComponent(componentId, validators) {
    this.components.set(componentId, validators);
  }

  getInstanceForComponent(componentId) {
    return this.components.get(componentId);
  }

  resetValidationsForComponent(componentId) {
    const { dispatch, getState } = this.store;
    resetComponentValidations(componentId)(dispatch, getState);
  }

  componentConformsToValidation(componentId, validationId) {
    const { components } = this.store.getState();

    const defaultRetries = this.components.get(componentId)[validationId].retries;
    const currentRetries = components[componentId].validations[validationId].retries;

    return defaultRetries === currentRetries;
  }

  /**
   * Returns whether a given component has failing validations or not and updates the store.
   * - A falsy return here means no validation concerns were found.
   * - A truthy result means it's going to be included in the array returned by `runAllValidations`.
   * @param {number} componentId Identifier for the component.
   */
  runComponentValidations(componentId) {
    const { dispatch } = this.store;

    // For now, we don't want to run validations for components that are not visible to the user.
    const isComponentHidden = !IPCService.shouldShowComponent(componentId);
    if (isComponentHidden) {
      return false;
    }

    const storeValidations = this.store.getState().components[componentId].validations;
    const instanceValidations = this.components.get(componentId);

    return instanceValidations.some((validator, validationId) => {
      const { predicate, shouldValidatorPass, severity } = validator;

      const predicateResult = predicate(componentId, this.store);
      const storeValidation = storeValidations[validationId];

      if (!isNil(storeValidation)) {
        const { retries } = storeValidation;
        const isPassing = shouldValidatorPass(predicateResult, severity, retries);

        if (!isPassing) {
          setComponentValidation(componentId, validationId, { retries: retries - 1 })(dispatch);
        }

        return !isPassing;
      }

      return false;
    });
  }

  runAllValidations() {
    return Array.from(this.components.keys()).filter(componentId =>
      this.runComponentValidations(componentId));
  }
}
