import { NODE_TYPES } from '../../constants';

function multiValueFieldGenerator(field) {
  return {
    type: NODE_TYPES.MULTI_VALUE_FIELD,
    field
  };
}

export default multiValueFieldGenerator;
