/* global browser */
// Originally taken from https://github.medallia.com/medallia/asknow

// intended to use together with object rest spread (ECMAScript 2018)
export const testName = (text, type) => ({
  'data-test-name': toTestName(text, type)
});

/**
 * @param {string} text The object's name
 * @param {string} type The object's type
 *
 * Receives an object name and its type and return
 * its corresponding data-test-name attribute. This
 * tries to set a standard for testing attributes
 *
 * Right types could be: "list", "setting"
 * For example:
 * toTestName('Default Language', 'setting') === 'setting-default-language'
 */
export const toTestName = (text, type) => {
  const name = text.toLowerCase().replace(new RegExp(' ', 'g'), '-');
  if (!type) return name;
  return `${type}-${name}`;
};

/**
 * @param {string} name The object's name
 * @param {string} type The object's type
 *
 * Receives an object name and its type and return
 * its corresponding selector by data-test-name attribute
 * tries to set a standard for testing selectors
 *
 * Right types could be: "list", "setting"
 * For example:
 * byTestName('Default Language', 'setting') === '[data-test-name="setting-default-language"]'
 */
export const byTestName = (name, type) =>
  `[data-test-name="${toTestName(name, type)}"]`;

/**
 * @param {string} selector CSS selector for the element collection you're targeting
 * @param {string} value Value of the targeted element
 * @return {string} selector for the targeted element
 */
export const byValue = (selector, value) => `${selector}[value="${value}"]`;

/**
 * @param {string} selector CSS selector for the element collection you're targeting
 * @param {string} text text of the targeted element
 * @return {string} selector for the targeted element
 */
export const byText = (selector, text) => `${selector}=${text}`;

/**
 * @param {string} selector CSS selector for the element collection you're targeting
 * @param {int} index Index of the targeted element (starting with 0)
 * @return {string} selector for the targeted element at the index passed
 */
export const byIndex = (selector, index) => `${selector}:nth-child(${index + 1})`;

/**
 * @param {string} selector CSS selector for the element collection you're targeting
 * @param {int} index Index of the targeted element considering element type (starting with 0)
 * @return {string} selector for the targeted element at the index passed
 */
export const byTypeIndex = (selector, index) => `${selector}:nth-of-type(${index + 1})`;

/**
 * @args {array} One or more strings to be joined
 *
 * Joins all the string parameters with a blank space
 * join(null, '.selector1', '[name="selector2"]') === '.selector1 [name="selector2"]'
 */
export const join = function join(...args) {
  return args.filter(item => (item || item === 0) && item.toString().length).join(' ');
  // return [].slice
  //   .call(args)
  //   .filter(item => item && item.length)
  //   .join(' ');
};

export const getUrlParts = (url) => {
  const partsBySlash = url.split(':').splice(1).join(':').split('/');
  const hostname = partsBySlash[2].replace('www.', '');
  const pathname = partsBySlash.splice(3).join('/').split('?')[0];
  const partsByQuestionSign = url.split('?');
  const search = partsByQuestionSign.length > 1 ? partsByQuestionSign.splice(1).join('?') : '';
  return {
    hostname,
    search,
    pathname: `/${pathname}`
  };
};

export const selectWithKeyboard = (selector) => {
  const element = browser.element(selector);
  while (!element.hasFocus()) {
    browser.keys('Tab');
    browser.pause(500);
  }
};
