import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import styles from './SelectOption.scss';

const SelectOption = (props) => {
  const { innerProps, data } = props;
  return (
    <components.Option
      {...props}
      innerProps={{ ...innerProps, title: data.label }}
      className={styles.container}
    >
      {data.label}
    </components.Option>
  );
};

SelectOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default SelectOption;
