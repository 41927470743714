import { connect } from 'react-redux';
import answerQuestion from '../actions';
import MobileNaOption from './MobileNaOption';

function mapStateToProps(state, ownProps) {
  if (!ownProps.id || !state.options || !state.options[ownProps.id]) return {};

  const option = state.options[ownProps.id];
  return {
    ariaLabel: option.ariaLabel,
    caption: option.caption,
    hasAnswer: option.hasAnswer,
    htmlInput: option.htmlInput,
    question: option.question,
    value: option.value
  };
}

const mapDispatchToProps = { answerQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(MobileNaOption);
