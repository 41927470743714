/* global navigator, document */

const HAS_TOUCH_CLASS_NAME = 'hasTouch';
const HAS_NO_TOUCH_CLASS_NAME = 'hasNoTouch';

export default class TouchDetector {
  static detectAndAddHasTouchClassNameToDomNode(domNode) {
    if (this.hasTouchAbility()) {
      this.addHasTouchClassNameToDomNode(domNode, HAS_TOUCH_CLASS_NAME);
    } else {
      this.addHasTouchClassNameToDomNode(domNode, HAS_NO_TOUCH_CLASS_NAME);
    }
  }

  static hasTouchAbility() {
    const nav = navigator || {};
    return 'ontouchstart' in document.documentElement ||
      (nav.MaxTouchPoints > 0) ||
      (nav.msMaxTouchPoints > 0);
  }

  static addHasTouchClassNameToDomNode(domNode, hasTouchClassName) {
    const targetElement = domNode;
    if (targetElement && !targetElement.className.includes(hasTouchClassName)) {
      targetElement.className = `${targetElement.className} ${hasTouchClassName}`.trim();
    }
  }
}
