const HTML_TAG_REGEX = /<{1}(\/)?[\w\s=!"#$%&'()*+,.:;=?@^_`{|}~-]+(\/)*>{1}/gi;

const APOSTROPHE_REGEX = /&#39;/gi;
const QUOTE_REGEX = /&quot;/gi;
const LESSTHAN_REGEX = /&lt;/gi;
const GREATERTHAN_REGEX = /&gt;/gi;
const AMPERSAND_REGEX = /&amp;/gi;

function replaceHtmlTag(matchedRegex) {
  if (matchedRegex.indexOf('br') >= 0) return ' ';
  return '';
}

export default function stripHtml(elem) {
  if (typeof elem !== 'string' ||
    elem === undefined ||
    elem === null ||
    elem === '') return '';
  if (elem === ' ') return elem;

  const string = elem.replace(APOSTROPHE_REGEX, "'")
    .replace(QUOTE_REGEX, '"')
    .replace(LESSTHAN_REGEX, '<')
    .replace(GREATERTHAN_REGEX, '>')
    .replace(AMPERSAND_REGEX, '&');

  return string.replace(HTML_TAG_REGEX, replaceHtmlTag).trim();
}
