import { connect } from 'react-redux';
import AnchorBar from './AnchorBar';

export const mapStateToProps = (state, ownProps) => {
  const options = (state.options || {});
  const option = options[ownProps.rowId];
  if (!option) return {};
  const question = state.questions && state.questions[ownProps.questionId] ?
    state.questions[ownProps.questionId] :
    {};

  const areMultipleRows = question.options.size > 1;
  const optionsId = [...option.options];
  const labels = [...question.anchors];
  const anchors = labels.map((text, index) => ({
    text,
    isSelected: !areMultipleRows &&
      options[optionsId[index]].hasAnswer &&
      labels.length === optionsId.length
  }));

  return {
    anchors,
    optionsQuantity: optionsId.length,
    hasNaOption: option.hasNaOption,
    shouldJustifyAnchors: option.shouldJustifyAnchors
  };
};

export default connect(mapStateToProps)(AnchorBar);
