import PropTypes from 'prop-types';

const {
  arrayOf,
  bool,
  oneOfType,
  shape,
  string
} = PropTypes;

export const itemShape = shape({
  active: bool,
  id: string,
  langKey: string,
  text: string
});

export const selectedType = oneOfType([itemShape, arrayOf(itemShape), null]);

export const dropdownListType = arrayOf(itemShape);
