import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { hideDOMNode, showDOMNode } from '../../utils/domNodeHelpers';

export default (Component) => {
  class withCondition extends PureComponent {
    static propTypes = {
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired,
      shouldShow: PropTypes.bool.isRequired
    };

    componentDidMount() {
      this.toggleComponent();
    }

    componentDidUpdate() {
      this.toggleComponent();
    }

    toggleComponent() {
      // Need to manually toggle classes in the DOM element so that it
      // remains compatible with modifications a custom JS may have performed
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(this);

      if (this.props.shouldShow) {
        showDOMNode(node);
      } else {
        hideDOMNode(node);
      }
    }

    render() {
      const { shouldShow, ...props } = this.props;
      return <Component {...props} />;
    }
  }

  return withCondition;
};
