import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import LanguageDropdown from './LanguageDropdown';
import NativeLanguageDropdown from './NativeLanguageDropdown';
import styles from './languageSelector.scss';

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: props.surveyLanguage
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(activeLanguageId) {
    this.setState({
      selectedLanguage: activeLanguageId
    }, () => {
      // $('[name=defPgrAction]').val('');
      const defPgrAction = document.querySelector('[name=defPgrAction]');
      if (defPgrAction) {
        defPgrAction.value = '';
      }

      // $('#screenReaderButton, #beginButton, #nextButton').prop('disabled', true);
      // TODO ids are kept as they are, but they actually have to be taken from the state,
      // since they depend on the surveyData.
      // This should be refactored in conjunction with our redux implementation.
      [
        '#screenReaderButton',
        '#beginButton',
        '#nextButton'
      ].forEach((selector) => {
        const el = document.querySelector(selector);
        if (el) {
          el.setAttribute('disabled', 'true');
        }
      });

      // $('#languageSelector').closest('form').submit();
      const form = document.querySelector('form');
      if (form) {
        form.submit();
      }
    });
  }

  render() {
    const { useNativeDropdown, isCookieBannerOpened } = this.props;

    const Dropdown = useNativeDropdown ?
      NativeLanguageDropdown :
      LanguageDropdown;

    const containerClasses = classNames({
      [styles.withCookieBanner]: isCookieBannerOpened
    });

    return (
      <span id="languageSelector" className={containerClasses}>
        <Dropdown
          {...this.props}
          surveyLanguage={this.state.selectedLanguage}
          onLanguageSelected={this.submitForm}
        />
        <input type="hidden" name="lang" id="activeLang" value={this.state.selectedLanguage} />
      </span>
    );
  }
}

LanguageSelector.propTypes = {
  useNativeDropdown: PropTypes.bool,
  surveyLanguage: PropTypes.string.isRequired,
  isCookieBannerOpened: PropTypes.bool
};

LanguageSelector.defaultProps = {
  useNativeDropdown: false,
  isCookieBannerOpened: false
};

export default LanguageSelector;
