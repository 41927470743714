import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import findLastIndex from 'lodash/findLastIndex';
import debounce from 'lodash/debounce';
import ProgressBarSteps from './../../commons/ProgressBarSteps';
import styles from './qualityMeter.scss';

const { arrayOf, shape, bool, number, string } = PropTypes;

class QualityMeter extends Component {
  static propTypes = {
    qualityMeterTexts: arrayOf(shape({
      count: number.isRequired,
      text: string.isRequired,
      barText: string.isRequired
    })).isRequired,
    characterCount: number.isRequired,
    showQualityMeter: bool.isRequired,
    id: string
  };

  static defaultProps = {
    id: '',
  };

  constructor(props) {
    super(props);
    const ariaLabel = this.getAriaLabel(props);
    this.state = { ariaLabel };

    this.debouncedUpdateAriaLabel = debounce(this.updateAriaLabel, 1000);
  }

  getActiveTextIndex(props = this.props) {
    const { characterCount, qualityMeterTexts } = props;
    return findLastIndex(qualityMeterTexts, (item, index) => {
      if (index === 0) {
        return item.count <= characterCount;
      }
      return item.count < characterCount
    });
  }

  getActiveItem(props = this.props) {
    const activeTextIndex = this.getActiveTextIndex(props);
    return activeTextIndex > -1 ? this.props.qualityMeterTexts[activeTextIndex] : null;
  }

  getCurrentLabel(props = this.props) {
    const activeItem = this.getActiveItem(props);
    return activeItem ? activeItem.text : '';
  }

  getAriaLabel(props = this.props) {
    const activeItem = this.getActiveItem(props);
    return activeItem ? [activeItem.text, activeItem.barText].join('. ') : '';
  }

  updateAriaLabel(props) {
    const ariaLabel = this.getAriaLabel(props);
    this.setState({ ariaLabel });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.characterCount !== nextProps.characterCount) {
      this.debouncedUpdateAriaLabel(nextProps);
    }
  }

  render() {
    const { qualityMeterTexts, showQualityMeter, id } = this.props;
    const numberOfSteps = qualityMeterTexts.length - 1;

    const activeTextIndex = this.getActiveTextIndex();
    const activeItem = this.getActiveItem();
    const currentLabel = this.getCurrentLabel();
    const isQualityMeterActive = showQualityMeter && activeItem;

    const qualityMeterContainerStyles = classNames(styles.qualityMeterContainer, {
      [styles.qualityMeterContainer_isActive]: isQualityMeterActive
    });

    return (
      <div className={qualityMeterContainerStyles} aria-hidden={!isQualityMeterActive} aria-live="polite" id={id} aria-label={this.state.ariaLabel}>
        <div className={styles.qualityMeterLabel} aria-hidden>{currentLabel}</div>
        <div className={styles.qualityMeterProgressBar} aria-hidden>
          <ProgressBarSteps steps={numberOfSteps} currentActive={activeTextIndex} />
        </div>
      </div>
    );
  }
}

export default QualityMeter;
