import { QUESTION_TYPES } from '../../question';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';
import stripHtml from '../../../utils/stripHtml';

export const MATCH_TOOLTIP = 'tooltip';

export const extractTooltipData = (questionData) => {
  const { imagePath, tooltipCaption, tooltipText } = questionData;
  const caption = tooltipCaption || tooltipText;

  return {
    ariaLabel: stripHtml(caption),
    caption,
    imagePath
  };
};

export default class TooltipLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return MATCH_TOOLTIP === questionData.blockType;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const tooltipData = extractTooltipData(questionData);

    resourcesManager.createOrUpdateQuestion({
      id,
      ...tooltipData,
      isRequired: false,
      type: QUESTION_TYPES.TOOLTIP
    });

    return resourcesManager.resources();
  }
}
