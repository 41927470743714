import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import { MATCH_TEXTFIELD } from '../textfield';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';
import stripHtml from '../../../utils/stripHtml';
import { createMultiValuedSetAllConditionBlock } from '../../../services/conditionGenerator';

export const MATCH_CHOOSE_MANY = 'choose';

export const createAriaLabelWithMultiSelectMessage =
  (caption = '', selectAllThatApplyMessage) =>
    `${caption} ${selectAllThatApplyMessage}`.trim();

export const createOtherTextFakeCondition = createMultiValuedSetAllConditionBlock;

export default class ChooseManyLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_CHOOSE_MANY;
    return shouldNormalize;
  }

  normalize({ id, questionData, rawData = {} }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();
    const translations = rawData.translations || {};
    const selectAllThatApplyMessage = translations.SELECT_ALL_THAT_APPLY_MESSAGE || '';

    const {
      caption,
      formKey,
      fieldName,
      requiredField,
      validationFailed,
      validationEmpty
    } = questionData;
    const row = questionData.row || [];

    const ariaLabel = createAriaLabelWithMultiSelectMessage(
      caption,
      selectAllThatApplyMessage
    );

    const question = resourcesManager.createOrUpdateQuestion({
      id,
      ariaLabel: stripHtml(ariaLabel),
      isRequired: Boolean(requiredField),
      hasValidationFailure: Boolean(validationFailed),
      hasValidationFailureForEmpty: Boolean(validationEmpty),
      caption,
      isMultiSelect: true,
      isMultiSelectWithManyOptions: row.length > 1,
      selectAllThatApplyMessage,
      type: QUESTION_TYPES.CHOOSE_MANY
    });

    resourcesManager.createCondition(questionData.condition, id);

    const groupOption = resourcesManager.createOrUpdateOption({
      question: id,
      hasChildren: true
    });

    const initialValue = [];

    row.forEach((r) => {
      const {
        checked,
        otherOptionDetails,
        text,
        condition
      } = r;

      const hasOtherOption = Boolean(otherOptionDetails);

      const htmlInput = resourcesManager.createOrUpdateHtmlInput({
        formKey,
        id: `${formKey}_${r.id}`,
        type: HTML_INPUT_TYPES.CHECKBOX,
        value: checked ? r.id : undefined,
        possibleValues: new Set([r.id]),
        question: question.id
      });

      const optionData = {
        ariaLabel: stripHtml(text),
        caption: text,
        hasAnswer: checked,
        hasChildren: hasOtherOption,
        hasParent: true,
        htmlInput: htmlInput.id,
        isMultiSelect: true,
        parent: groupOption.id,
        question: question.id,
        value: r.id,
        condition
      };
      if (hasOtherOption) {
        optionData.hasOtherOption = true;
        optionData.otherOptionType = MATCH_TEXTFIELD;
      }

      const option = resourcesManager.createOrUpdateOption(optionData);
      question.options.delete(option.id);

      resourcesManager.createCondition(optionData.condition, option.id);

      resourcesManager.createAlternative(fieldName, optionData.value, option.id);
      if (optionData.hasAnswer) {
        initialValue.push(optionData.value);
      }

      if (hasOtherOption) {
        question.hasOtherOption = true;

        const textfieldData  = otherOptionDetails;
        const textfieldId    = textfieldData.formkey;
        const textfieldValue = textfieldData.value;

        let textfieldCaption = textfieldData.caption;
        textfieldCaption = (textfieldCaption === undefined || textfieldCaption === null) ?
          '' :
          String(textfieldCaption);

        // sometimes the caption is embedded in the child textfield and not the
        // parent so we use its caption instead
        if (textfieldCaption.length > 0) {
          option.caption = textfieldCaption;
          option.ariaLabel = stripHtml(textfieldCaption);
        }

        const hasAnswer = Boolean(textfieldValue);

        const textfieldHtmlInput = resourcesManager.createOrUpdateHtmlInput({
          id: textfieldId,
          type: HTML_INPUT_TYPES.TEXT,
          question: id,
          value: textfieldValue,
          hasValidationFailure: Boolean(textfieldData.validationFailed),
          hasValidationFailureForEmpty: Boolean(textfieldData.validationEmpty),
          disableAutocomplete: Boolean(textfieldData.disableAutocomplete)
        });

        if (hasAnswer) question.htmlInputsWithAnswer.add(textfieldHtmlInput.id);

        const textfieldOption = resourcesManager.createOrUpdateOption({
          hasAnswer,
          caption: '',
          hasParent: true,
          htmlInput: textfieldId,
          parent: option.id,
          question: id,
          value: textfieldValue
        });
        question.options.delete(textfieldOption.id);

        const textInitialValue = textfieldOption.hasAnswer ? textfieldOption.value : '';
        resourcesManager.createField(
          textfieldData.fieldName,
          textInitialValue,
          id,
          textfieldOption.id
        );

        // An admin may write a condition based on this text field
        // but without considering whether the selected answer is "Other"
        // as part of the condition
        // We need to fake a condition here :/
        if (fieldName) {
          const fakeCondition = createOtherTextFakeCondition(fieldName, optionData.value);
          resourcesManager.createCondition(fakeCondition, textfieldOption.id);
        }
      }
    });

    resourcesManager.createMultiValuedField(fieldName, initialValue, id);

    return resourcesManager.resources();
  }
}
