import PropTypes from 'prop-types';
import React from 'react';
import Item from './Item';
import styles from './List.scss';

export const TEST_NAME_LIST = 'TEST_NAME_LIST';

export default function List(props) {
  const {
    items,
    setAriaLiveMessage,
    setItemRef,
    setListRef,
    updatePositions
  } = props;

  const renderable = items.map(({ id, selected, text }, idx, arr) => (
    <Item
      id={id}
      key={id}
      optionsLength={arr.length}
      selected={selected}
      setAriaLiveMessage={setAriaLiveMessage}
      setItemRef={setItemRef}
      text={text}
      updatePositions={updatePositions}
    />
  ));

  return (
    <div
      className={styles.list}
      data-test-name={TEST_NAME_LIST}
      ref={setListRef}
    >
      {renderable}
    </div>
  );
}

const {
  arrayOf,
  shape,
  string,
  number,
  instanceOf,
  func
} = PropTypes;

List.propTypes = {
  items: arrayOf(shape({
    id: string,
    text: string,
    ref: shape({ current: instanceOf(Element) }),
    defaultPosition: number,
    position: number,
    selected: shape({ id: string, text: string })
  })).isRequired,
  setAriaLiveMessage: func.isRequired,
  setItemRef: func.isRequired,
  setListRef: func.isRequired,
  updatePositions: func.isRequired
};
