import { SET_COMPONENT_ANSWER } from '../../actions/setComponentAnswer';
import { SET_COMPONENT_DATA } from '../../actions/setComponentData';
import { SET_COMPONENT_VALIDATIONS } from '../../actions/setComponentValidations';
import { SET_COMPONENT_VALIDATION } from '../../actions/setComponentValidation';

export default function components(state = {}, action = {}) {
  switch (action.type) {
    case SET_COMPONENT_ANSWER: {
      const newState = state;
      const { componentId, field, value } = action.payload;

      if (componentId == null || !field) return newState;

      if (!newState[componentId]) {
        newState[componentId] = {
          values: {}
        };
      }

      return {
        ...newState,
        [componentId]: {
          ...newState[componentId],
          values: {
            ...newState[componentId].values,
            [field]: value
          }
        }
      };
    }

    case SET_COMPONENT_DATA: {
      const newState = state;
      const { componentId, data } = action.payload;

      if (componentId == null || !data) return newState;

      return {
        ...newState,
        [componentId]: {
          ...newState[componentId],
          data: { ...data }
        }
      };
    }

    case SET_COMPONENT_VALIDATIONS: {
      const newState = state;
      const { componentId, validations } = action.payload;

      if (componentId == null || !validations) return newState;

      return {
        ...newState,
        [componentId]: {
          ...newState[componentId],
          validations
        }
      };
    }

    case SET_COMPONENT_VALIDATION: {
      const { componentId, validationId, newValue } = action.payload;

      const newValidations = [...state[componentId].validations];
      newValidations[validationId] = newValue;

      return {
        ...state,
        [componentId]: {
          ...state[componentId],
          validations: newValidations
        }
      };
    }

    default: return state;
  }
}
