/* eslint-disable no-continue */
import get from 'lodash/get';
import convertToInt from '../../../utils/convertToInt';
import stripHtml from '../../../utils/stripHtml';
import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';

export const MATCH_RATING_GRID = 'grid';

export const normalizeCaption = ({ questionData }) => {
  const {
    hasCaption, gridCaption, isScaleQuestion, scaleCaption, simpleQuestionGrid
  } = questionData;
  if (isScaleQuestion && !simpleQuestionGrid) return scaleCaption;
  if (hasCaption) return gridCaption;
  return null;
};

export const normalizeAnchors = ({ spans, shouldHaveAnNaOption, isAnchorOverridingAltSetName }) => {
  const anchors = new Set();
  if (!spans || spans.length === 0) return anchors;

  // if there is a span name, the anchors are overridden
  for (let i = 0; i < spans.length; i++) {
    const span = spans[i];

    const numValueIsNull = get(span, 'gridCol.alt.numValue', null) === null;
    if (shouldHaveAnNaOption && numValueIsNull) continue;

    let name;
    // overriden anchor has priority over alt text
    if (isAnchorOverridingAltSetName) name = get(span, 'name', '');
    else name = get(span, 'gridCol.alt.name', '');

    // guard against empty anchor scenarios
    if (
      name.indexOf('[blank]') >= 0 || // service team sets name as [blank] in survey config
      name === '' ||
      Boolean(name.match(/^\s+$/)) || // sometimes [blank] comes out as ' '
      anchors.has(name) // already included
    ) continue;

    anchors.add(name);
  }

  return anchors;
};

export const shouldJustifyAnchors = ({ numOfAnchors, numOfCellOptionsWithValue }) => {
  if (numOfAnchors === 2 || numOfAnchors === 3) return true;
  if (numOfAnchors !== numOfCellOptionsWithValue) return true;
  return false;
};

export const sterilizeHeader = (header) => {
  if (header.match(/(?:<|\[)(?:em|blank)(?:>|])\s*(?:<|\[)\/(?:em|blank)(?:>|])/)) return '';
  return header;
};

export const createHiddenLegendLabelForADA = (questionCaption, rowCaption) => {
  const questionCaptionString = questionCaption || '';
  const rowCaptionString = rowCaption || '';
  return stripHtml(`${questionCaptionString} ${rowCaptionString}`).trim();
};

export const rowShouldHaveAnNaOption = (isNumeric, cells) => {
  /**
   * Explanation from: https://jira.medallia.com/browse/COCO-1083
   *
   * If there is at least one alternative with non-null numeric value, the
   * attribute isNumeric is set to true in the JSON. In this case there is only
   * one alternative with numValue == null: the Not Applicable option.
   * Every grid we faced until now was of this kind.
   *
   * If all of the alternatives have numValue == null, isNumeric is set to false.
   * The REPEAT_SELECTED_QUESTIONS node uses an alternative like this. This is
   * a case we did not account for when implementing the grid. There is no way
   * to distinguish the Not Applicable option from the other ones. What V6 does
   * in this case (and we should emulate) is rendering all the available options,
   * and not try to do anything special about the N/A
   */
  let countOfCellWithNullNumValue = 0;
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    if (cell.numValue === null || cell.numValue === undefined) countOfCellWithNullNumValue++;
  }

  return isNumeric && countOfCellWithNullNumValue === 1;
};

export const createRatingUnitAriaLabelByCombiningValueWithHeader = ({
  showNumbers, isNaOption, value, caption, header
}) => {
  let sanitizedHeader = header;
  let sanitizedValue = value;
  let sanitizedCaption = caption;
  if (sanitizedHeader === undefined || sanitizedHeader === null) sanitizedHeader = '';
  if (sanitizedValue === undefined || sanitizedValue === null) sanitizedValue = '';
  if (sanitizedCaption === undefined || sanitizedCaption === null) sanitizedCaption = '';
  sanitizedHeader = sterilizeHeader(sanitizedHeader);

  if (isNaOption) return `${sanitizedHeader}`.trim();

  if (showNumbers && sanitizedCaption === sanitizedHeader) {
    return sanitizedHeader.trim();
  }
  if (showNumbers) {
    return `${sanitizedCaption} ${sanitizedHeader}`.trim();
  }

  return sanitizedHeader.trim() || sanitizedValue.toString().trim();
};

export default class RatingGridLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_RATING_GRID;
    return shouldNormalize;
  }

  normalize({ id, questionData, mainComponentId }) {
    if (!this.shouldNormalize({ questionData })) return undefined;

    const resourcesManager = new ResourcesRelationshipManager();

    const showNumbers = Boolean(questionData.showExtraHeaders);
    const showStars = Boolean(questionData.starsGrid);

    // a scale question is a rating grid question where there is only one row
    const isScaleQuestion = Boolean(questionData.isScaleQuestion);

    // cast to array because questionData.rows can be an object or an array based on
    // how many rows there are: 1 = object, more than 1 = array
    // Array.isArray() is slower than array.constructor === Array
    let rows = questionData.rows || [];
    rows = rows.constructor === Array ? rows : [rows];

    // cast to array because questionData.spans can be an object or an array
    // if the alt text values are the same for all columns -> questionData.spans is object
    // if the alt text values are different -> questionData.spans is array
    let spans = questionData.spans || [];
    spans = spans.constructor === Array ? spans : [spans];

    const isAnchorOverridingAltSetName = spans ? ((name) => {
      if (
        name.indexOf('[blank]') >= 0 ||
        name === '' ||
        Boolean(name.match(/^\s+$/))
      ) return false;
      return true;
    })(get(spans, '[0].name', '')) : false;
    const { isNumeric } = questionData;
    const shouldHaveAnNaOption = rowShouldHaveAnNaOption(isNumeric, get(rows, '[0].cells', []));
    const anchors = normalizeAnchors({
      spans,
      shouldHaveAnNaOption,
      isAnchorOverridingAltSetName
    });
    const caption = normalizeCaption({ questionData });

    const question = resourcesManager.createOrUpdateQuestion({
      id,
      ariaLabel: stripHtml(caption),
      caption,
      anchors,
      hasAnswer: false,
      isScaleQuestion,
      type: QUESTION_TYPES.RATING_GRID,
      simpleQuestionGrid: questionData.simpleQuestionGrid,
      mainComponentId
    });

    const rowShouldUseFieldSet = rows.length > 1 && !isScaleQuestion;
    question.useFieldset = !rowShouldUseFieldSet;

    let rowOption;
    rows.forEach((row) => {
      const hasValidationFailure = Boolean(row.validationFailed);
      const hasValidationFailureForEmpty = row.validationEmpty === 'validationEmpty';
      const isRequired = get(row, 'row.required', false) || Boolean(row.requiredField);
      const isMultiValued = get(row, 'row.isMultiValued', false);
      const formKey = get(row, 'cells[0].formKey'); // All cells have the same formKey
      let rowHtmlInput;
      if (hasValidationFailure) {
        question.hasValidationFailure = hasValidationFailure;
      }
      if (hasValidationFailureForEmpty) {
        question.hasValidationFailureForEmpty = hasValidationFailureForEmpty;
      }
      if (isRequired) question.isRequired = isRequired;

      rowOption = resourcesManager.createOrUpdateOption({
        isRequired,
        showNumbers,
        showStars,
        caption: question.isScaleQuestion && !question.simpleQuestionGrid ? '' : row.caption,
        debugInfo: 'RatingGrid__Row',
        hasAnswer: false,
        hasChildren: true,
        hasNaOption: false,
        hasParent: false,
        naOption: undefined,
        naValue: undefined,
        question: id,
        useFieldset: rowShouldUseFieldSet,
        isMultiValued,
        ...(isMultiValued ? {} : { htmlInput: formKey })
      });
      rowOption.hiddenLegendForADA = createHiddenLegendLabelForADA(
        question.caption,
        rowOption.caption,
      );

      if (rows.length === 1) question.ariaLabel = rowOption.hiddenLegendForADA;

      const cells = row.cells || [];
      let initialValue = isMultiValued ? [] : null;
      cells.forEach((cell, idx) => {
        const { numValue, checked } = cell;
        const value = convertToInt(cell.formValue);

        // cell is naOption if value is undefined or null
        const isNaOption = shouldHaveAnNaOption && (numValue === undefined || numValue === null);
        const hasAnswer = Boolean(checked);
        const header = isAnchorOverridingAltSetName ? get(cell, 'header') : get(cell, 'col.alt.name');

        const ariaLabel = stripHtml(createRatingUnitAriaLabelByCombiningValueWithHeader({
          caption: cell.caption,
          header,
          isNaOption,
          showNumbers,
          value
        }));

        let cellHtmlInput = null;
        if (isMultiValued) {
          cellHtmlInput = resourcesManager.createOrUpdateHtmlInput({
            id: `${formKey}_${idx}`,
            type: HTML_INPUT_TYPES.CHECKBOX,
            isSingleSelect: false,
            isMultiSelect: true,
            formKey,
            fieldName: questionData.fieldName,
            question: id
          });
        } else if (idx === 0) { // Single select should create only one html input
          rowHtmlInput = resourcesManager.createOrUpdateHtmlInput({
            id: formKey,
            type: HTML_INPUT_TYPES.RADIO,
            isSingleSelect: true,
            isMultiSelect: false,
            question: id,
            fieldName: questionData.fieldName,
            value: undefined
          });
        }
        const htmlInput = isMultiValued ? cellHtmlInput : rowHtmlInput;

        const cellOption = resourcesManager.createOrUpdateOption({
          ariaLabel,
          ariaPosInSet: idx + 1,
          ariaSetSize: cells.length,
          hasAnswer,
          isNaOption,
          isRequired,
          value,
          caption: cell.caption,
          debugInfo: isNaOption ? 'RatingGrid__NaCell' : 'RatingGrid__Cell',
          hasParent: true,
          htmlInput: htmlInput.id,
          parent: rowOption.id,
          question: id,
          isMultiSelect: isMultiValued,
          field: row.row.field.id
        });
        question.options.delete(cellOption.id); // only keep rowOptions

        if (isNaOption) {
          htmlInput.hasNaOption = isNaOption;
          htmlInput.naOption = cellOption.id;
          htmlInput.naValue = value;

          rowOption.hasNaOption = isNaOption;
          rowOption.naValue = value;
          rowOption.naOption = cellOption.id;
          rowOption.naOptionCaption = cell.caption;
          rowOption.options.delete(cellOption.id);

          question.hasNaOption = isNaOption;
        }

        if (hasAnswer) {
          if (isMultiValued) {
            initialValue.push(value);
          } else {
            initialValue = value;
          }
          htmlInput.value = value;
          rowOption.hasAnswer = hasAnswer;
          question.hasAnswer = hasAnswer;
          question.htmlInputsWithAnswer.add(htmlInput.id);
        }
      });

      rowOption.shouldJustifyAnchors = shouldJustifyAnchors({
        numOfAnchors: anchors.size,
        numOfCellOptionsWithValue: rowOption.options.size
      });

      if (rowHtmlInput) rowHtmlInput.options.delete(rowOption.id); // only keep cellOptions

      if (isMultiValued) {
        resourcesManager.createMultiValuedField(row.row.field.id, initialValue, id, rowOption.id);
      } else {
        resourcesManager.createField(row.row.field.id, initialValue, id, rowOption.id);
      }
      resourcesManager.createCondition(row.condition, rowOption.id);
    });
    resourcesManager.createCondition(questionData.condition, id);

    return resourcesManager.resources();
  }
}
