/* eslint-disable jsx-a11y/href-no-hash */
/* global document */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import IndicatorCircle from '../../../commons/IndicatorCircle';
import {
  isArrowDownKey,
  isArrowRightKey,
  isArrowLeftKey,
  isArrowUpKey,
  isEsc,
  isSpaceKey,
  isTab
} from '../../../utils/detectKey';
import styles from './day.scss';
import { testName } from "../../../utils/testUtils";

const {bool, func, number, shape, string} = PropTypes;

export default class Day extends PureComponent {
  static propTypes = {
    ariaLabel: string,
    ariaLabelForSelected: string,
    day: number,
    focusThisDay: number,
    inverseLeftRightArrowMovement: bool,
    isSelected: bool,
    month: number,
    notInTheFuture: bool,
    onDayClick: func,
    onDayTabPress: func,
    onKeyPressFocusDay: func,
    tabbableDay: number,
    year: number,
    today: shape({
      year: number,
      month: number,
      day: number
    }),
    value: string
  };

  static defaultProps = {
    isSelected: false
  };

  constructor(props) {
    super(props);
    this.setRefToAnchor = this.setRefToAnchor.bind(this);
    this.handleOnClickSelectDate = this.handleOnClickSelectDate.bind(this);
    this.handleOnMouseLeaveBlurAnchor = this.handleOnMouseLeaveBlurAnchor.bind(this);
    this.handleKeyDownDayNavigation = this.handleKeyDownDayNavigation.bind(this);
    this.handleKeyDownDayNavigation = this.handleKeyDownDayNavigation.bind(this);
  }

  handleKeyDownDayNavigation(e) {
    const {
      day,
      year,
      month,
      today,
      inverseLeftRightArrowMovement,
      notInTheFuture,
      onDayTabPress
    } = this.props;

    if (isArrowRightKey(e)) {
      const dayAdjustment = inverseLeftRightArrowMovement ? -1 : 1;
      const isInTheFuture = this.isInFutureDate({ year, month, today, day: day + dayAdjustment });
      if (notInTheFuture && isInTheFuture) return e.preventDefault();
      this._navigateToNumberOfDays(e, day, dayAdjustment);
    } else if (isArrowLeftKey(e)) {
      const dayAdjustment = inverseLeftRightArrowMovement ? 1 : -1;
      this._navigateToNumberOfDays(e, day, dayAdjustment);
    } else if (isArrowDownKey(e)) {
      const isInTheFuture = this.isInFutureDate({ year, month, today, day: day + 7 });
      if (notInTheFuture && isInTheFuture) return this._navigateToNumberOfDays(e, day, today.day - day);
      this._navigateToNumberOfDays(e, day, 7);
    } else if (isArrowUpKey(e)) {
      this._navigateToNumberOfDays(e, day, -7);
    } else if (isSpaceKey(e)) {
      e.preventDefault();
    } else if ((!e.shiftKey && isTab(e)) || isEsc(e)) {
      if (onDayTabPress) onDayTabPress(e);
    }
  }

  _navigateToNumberOfDays(e, currentDay, numberOfDays) {
    e.preventDefault();
    const { onKeyPressFocusDay } = this.props;
    if (onKeyPressFocusDay) onKeyPressFocusDay(currentDay + numberOfDays);
  }

  handleOnClickSelectDate(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const {
      year,
      month,
      day,
      notInTheFuture,
      today,
      value,
      onDayClick
    } = this.props;
    if (notInTheFuture && this.isInFutureDate({year, month, day, today})) return;
    if (onDayClick) onDayClick(value, day);
  }

  handleOnMouseLeaveBlurAnchor() {
    if (this._$anchor && document.activeElement === this._$anchor) {
      this._$anchor.blur();
    }
  }

  setRefToAnchor($anchor) {
    this._$anchor = $anchor;
  }

  isInFutureDate({ year, month, day, today }) {
    return (year > today.year)
      || (year === today.year && month > today.month)
      || (year === today.year && month === today.month && day > today.day);
  }

  componentDidUpdate() {
    const { focusThisDay, day } = this.props;
    if (focusThisDay === day && this._$anchor) this._$anchor.focus();
  }

  render() {
    const {
      ariaLabel,
      ariaLabelForSelected,
      isSelected,
      notInTheFuture,
      tabbableDay,
      today,
      year,
      day,
      month
    } = this.props;

    const isEmpty = !day;
    const dayContainerStyles = classNames(
      styles.dayContainer,
      {
        [styles.dayContainer_isEmpty]: isEmpty
      }
    );

    let dayStyles = styles.day;
    if (notInTheFuture) {
      const inFuture = this.isInFutureDate({year, month, day, today });
      if (inFuture) dayStyles = classNames(dayStyles, styles.day_isDisabled);
    }

    const tabIndex = (tabbableDay === day) ? 0 : -1;

    return (
      <td aria-hidden={isEmpty} className={dayContainerStyles} onMouseLeave={this.handleOnMouseLeaveBlurAnchor}>
        { day && (
          <a
            href=""
            aria-label={isSelected ? `${ariaLabelForSelected} ${ariaLabel}` : ariaLabel}
            className={dayStyles}
            onClick={this.handleOnClickSelectDate}
            onKeyDown={this.handleKeyDownDayNavigation}
            ref={this.setRefToAnchor}
            tabIndex={tabIndex}
            {...testName(`${day}`, 'day')}
          >
            <IndicatorCircle containerStyles={styles.dayLabel} caption={`${day}`} isSelected={isSelected} />
          </a>
        )}
      </td>
    );
  }
}
