import { saveSessionConsent, saveSessionOpenStatus } from '../../services/cookieBannerSessionData';
import { setFocusedElement } from './setPageStatus';

export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const TOGGLE_BANNER = 'TOGGLE_BANNER';

export const toggleBanner = trigger => (dispatch, getState, { getSurveyData }) => {
  const { cookieConsentId } = getSurveyData().cookieBanner;
  const { isOpen, opener } = getState().cookieBanner;
  saveSessionOpenStatus(cookieConsentId, !isOpen);
  dispatch({
    type: TOGGLE_BANNER,
    payload: {
      opener: trigger
    }
  });
  if (isOpen && opener) {
    dispatch(setFocusedElement(opener));
  }
};

export const acceptCookies = (date = new Date()) => (dispatch, getState, { getSurveyData }) => {
  const { cookieConsentId } = getSurveyData().cookieBanner;
  const { opener } = getState().cookieBanner;

  const consentDate = date.toISOString();

  saveSessionConsent(cookieConsentId, consentDate);
  saveSessionOpenStatus(cookieConsentId, false);
  dispatch({
    type: ACCEPT_COOKIES,
    payload: {
      consentDate
    }
  });
  if (opener) {
    dispatch(setFocusedElement(opener));
  }
};
