/* eslint-disable react/sort-comp */
import React from 'react';
import { Async } from 'react-select';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

import SelectMenuList from './SelectMenuList';
import SelectOption from './SelectOption';

import { selectPropTypes, customStyles, filterOptions, optionsToMap } from './utils';

class Select extends React.Component {
  //----------------------------------------------------------------------
  // LIFECYCLE
  //----------------------------------------------------------------------

  constructor(props) {
    super(props);

    const options = optionsToMap(props.options);
    let selectedOptionIndex = 0;
    let focusedOptionIndex = 0;

    if (!isNil(props.value)) {
      const scrollTargetIndex = options.get(props.value.value).idx;

      selectedOptionIndex = scrollTargetIndex;
      focusedOptionIndex = scrollTargetIndex;
    }

    this.state = {
      focusedOptionIndex,
      selectedOptionIndex,
      options,
      inputValue: '',
      isUserNavigatingTheListWithKeyboard: false
    };
  }

  componentDidMount() {
    this.lastFocusedOption = this.getFocusedOption();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.options, this.props.options)) {
      const newOptions = optionsToMap(this.props.options);
      this.setState(currentState => ({
        ...currentState,
        options: newOptions
      }));
    }
  }

  //----------------------------------------------------------------------
  // UTILS
  //----------------------------------------------------------------------

  isMenuOpen() {
    return this._$selectRef.select.state.menuIsOpen;
  }

  selectOption = (option) => {
    return this._$selectRef.select.select.selectOption(option);
  }

  selectFocusedOption() {
    return this.selectOption(this.getFocusedOption());
  }

  loadOptions = (inputValue, reactSelectCallback) => {
    const result = filterOptions(this.props.options)(inputValue);

    this.onOptionsChange(result);
    reactSelectCallback(result);
  };

  getFocusedOption() {
    return this._$selectRef.select.select.state.focusedOption;
  }

  resetOptions = () => {
    const originalOptions = optionsToMap(this.props.options);
    this.setState({ options: originalOptions });
  };

  setSelectRef = (selectRef) => {
    this._$selectRef = selectRef;
  };

  //----------------------------------------------------------------------
  // EVENTS
  //----------------------------------------------------------------------

  onSpaceKeyDown = (event) => {
    if (!this.isMenuOpen()) return;

    if (this.state.isUserNavigatingTheListWithKeyboard) {
      this.selectFocusedOption();
      event.preventDefault();
    }
  }

  onKeyboardNavigationKeyDown() {
    this.setState({ isUserNavigatingTheListWithKeyboard: true });
  }

  onInputChangeKeyDown() {
    this.setState({ isUserNavigatingTheListWithKeyboard: false });
  }

  onMenuCloseKeyDown() {
    this.setState({ isUserNavigatingTheListWithKeyboard: false });
  }

  onKeyDown = (event) => {
    // Overriding react-select behavior for some keys
    // All keys being handled were taken from
    // https://github.com/JedWatson/react-select/blob/v2.4.4/src/Select.js#L1125
    switch (event.key) {
      case ' ':
        this.onSpaceKeyDown(event);
        break;
      // Keyboard navigation keys
      case 'ArrowUp':
      case 'ArrowDown':
      case 'PageUp':
      case 'PageDown':
      case 'Home':
      case 'End':
        this.onKeyboardNavigationKeyDown();
        break;
      // Keys that close the menu either with selection or not
      case 'Escape':
      case 'Enter':
      case 'Tab':
        this.onMenuCloseKeyDown();
        break;
      // Explicitly considering these keys below as "doing something" on the input
      // In this case, they move the caret
      case 'ArrowLeft':
      case 'ArrowRight':
        this.onInputChangeKeyDown();
        break;
      // Explicitly considering these keys below as writing on the input
      case 'Delete':
      case 'Backspace':
        this.onInputChangeKeyDown();
        break;
      // Every other key is considered as the user writing on the input
      default:
        this.onInputChangeKeyDown();
        break;
    }

    // Note: This is done asynchronously because `onKeyDown`
    // event is fired before the focused option has been changed.
    Promise.resolve().then(() => {
      const focusedOption = this.getFocusedOption();
      if (this.isMenuOpen() && this.lastFocusedOption !== focusedOption) {
        this.lastFocusedOption = focusedOption;
        this.onFocus(focusedOption);
      }
    });
  };

  onFocus = (focusedOption) => {
    const { options } = this.state;

    if (
      !isEmpty(options) &&
      !isNil(focusedOption) &&
      !isNil(focusedOption.value)
    ) {
      const focusedOptionIndex = this.state.options.get(focusedOption.value).idx;
      this.setState({ focusedOptionIndex });
    }
  };

  onOptionsChange = (newOptions) => {
    const newOptionsMap = optionsToMap(newOptions);
    this.setState(prevState => ({
      ...prevState,
      options: newOptionsMap
    }));
  };

  onInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    this.resetOptions();
    return newValue;
  };

  onChange = (newValue) => {
    const selectedOptionIndex = this.state.options.get(newValue.value).idx;
    this.setState({ selectedOptionIndex });
    this.resetOptions();
    this.props.onChange(newValue);
  };

  onMenuOpen = () => {
    // Workaround suggested here: https://github.com/JedWatson/react-select/pull/3813#issuecomment-548395715
    // Related issues:
    // - https://github.com/JedWatson/react-select/pull/3868
    // - https://github.com/JedWatson/react-select/pull/3813
    // - https://github.com/JedWatson/react-select/issues/3535
    // - https://github.com/JedWatson/react-select/issues/3648
    setTimeout(() => {
      const selected = this.props.value;
      if (selected && selected.value) {
        this._$selectRef.select.scrollToFocusedOptionOnUpdate = true;
        this._$selectRef.select.setState({
          focusedOption: selected
        });
      }
    }, 0);
  };

  //----------------------------------------------------------------------
  // RENDER
  //----------------------------------------------------------------------

  render() {
    // react-select v2 docs at:
    // https://web.archive.org/web/20190731061950/https://react-select.com/home
    return (
      <Async
        {...this.props}
        // Options
        defaultOptions={this.props.options}
        loadOptions={this.loadOptions}
        // State
        inputValue={this.state.inputValue}
        focusedOptionIndex={this.state.focusedOptionIndex}
        selectedOptionIndex={this.state.selectedOptionIndex}
        // Custom Components
        styles={{ ...customStyles }}
        components={{ MenuList: SelectMenuList, Option: SelectOption }}
        loadingMessage={() => 'Loading...'}
        // Custom Handlers
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onChange={this.onChange}
        // TODO: This is WIP for OA-26527.
        // onMenuOpen={this.onMenuOpen}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        // Misc
        captureMenuScroll={false}
        ref={this.setSelectRef}
      />
    );
  }
}

Select.propTypes = { ...selectPropTypes };

export default Select;
