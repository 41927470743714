import { ANSWER_QUESTION } from '../../actions/answerQuestion';
import hasPayloadAndResourcesAreLinked from '../../../utils/hasPayloadAndResourcesAreLinked';
import checkbox from './checkbox';
import hidden from './hidden';
import text from './text';
import textarea from './textarea';
import radio from './radio';

export function determineReducerForHtmlInputBaseOnType(htmlInput) {
  if (htmlInput.isRadioType()) return radio;
  if (htmlInput.isCheckboxType()) return checkbox;
  if (htmlInput.isTextType()) return text;
  if (htmlInput.isTextareaType()) return textarea;
  if (htmlInput.isHiddenType()) return hidden;
  return undefined;
}

export default function htmlInputs(state = {}, action) {
  if (!hasPayloadAndResourcesAreLinked(state, action)) return state;
  const htmlInput = state[action.payload.htmlInputId];

  switch (action.type) {
    case ANSWER_QUESTION: {
      const reducer = determineReducerForHtmlInputBaseOnType(htmlInput);
      if (reducer) return reducer(state, action, htmlInput);
      return state;
    }
    default: return state;
  }
}
