import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../../commons/Table';
import Row from '../../../commons/Table/Row';
import Anchor from '../Anchor';
import classNames from 'classnames';
import "./anchorBar.global.scss";

const { bool, string, array } = PropTypes;

function createAnchors(anchors, shouldJustifyAnchors, isFirstRow, anchorStyles, questionId) {
  return anchors.map((anchor, idx) => (
    <Anchor
      key={idx}
      caption={anchor.text}
      containerStyles={anchorStyles}
      isJustified={shouldJustifyAnchors}
      isOnFirstRow={isFirstRow}
      isSelected={anchor.isSelected}
      questionId={questionId}
      id={idx}
    />
  ));
}

export default function AnchorBar(props) {
  const {
    anchors,
    hasNaOption,
    isFirstRow,
    isForcedDesktopShown,
    shouldJustifyAnchors,
    optionsQuantity,
    questionId,
  } = props;

  const anchorStyles = `anchor_${optionsQuantity}column${hasNaOption ? '_na' : ''}`;

  const anchorsContainerStyles = classNames('anchors', {
    anchors_isFirstRow: isFirstRow,
    anchors_isForcedDesktopShown: isForcedDesktopShown
  });

  return (
    <Table containerStyles={anchorsContainerStyles}>
      <Row>
        {createAnchors(anchors, shouldJustifyAnchors, isFirstRow, anchorStyles, questionId)}
      </Row>
    </Table>
  );
}

AnchorBar.propTypes = {
  anchors: array,
  anchorStyles: string,
  isFirstRow: bool,
  isForcedDesktopShown: bool,
  shouldJustifyAnchors: bool
};

AnchorBar.defaultProps = {
  anchors: [],
  anchorStyles: ''
};
