const translations = {};

export const setTranslation = (key, value) => {
  translations[key] = value;
};

export const defineTranslations = definition =>
  Object.keys(definition).forEach(key => setTranslation(key, definition[key]));

export const getTranslation = key => translations[key] || '';

export const replaceVariables = (string, variableToReplace, arrayOfReplacements) => {
  if (!string) return '';
  let stringWithReplacements = string;

  arrayOfReplacements.forEach((replaceValue) => {
    stringWithReplacements = stringWithReplacements.replace(variableToReplace, replaceValue);
  });
  return stringWithReplacements;
};

export const getTranslationWithVariables = (key, variableToReplace, arrayOfReplacements) => (
  replaceVariables(translations[key], variableToReplace, arrayOfReplacements)
);
