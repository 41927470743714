/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

import React from 'react';
import className from 'classnames';
import Title from '../common/Title';
import Description from '../common/Description';
import {
  LEGAL_NOTICE_PANEL_ID,
  COOKIE_CONSENT_PANEL_ID,
  TECHNICALLY_NECESSARY_PANEL_ID
} from '../constants';
import Button, { BUTTON_STYLES } from '../../../commons/Button';
import styles from './mainPanel.scss';
import Icon, { ICON_TYPES } from '../../../commons/Icon';

const MainPanel = ({
  acceptButtonText,
  consentInfoLinkText,
  description,
  legalInfoLinkText,
  onAccept,
  openSecondaryPanel,
  technicallyNecessaryLinkText,
  title
}) => {
  const createGoToSection = panel => (e) => {
    e.preventDefault();
    openSecondaryPanel(panel);
  };

  const acceptButtonStyles = className(BUTTON_STYLES.PRIMARY, styles.acceptButton);

  return (
    <div className={styles.container}>
      <Title text={title} />
      <div className={styles.description}>
        <Description text={description} />
      </div>

      <div className={styles.sectionItems}>
        <a href="#" className={styles.linkButton} onClick={createGoToSection(LEGAL_NOTICE_PANEL_ID)}>
          <span dangerouslySetInnerHTML={{ __html: legalInfoLinkText }} />
          <Icon type={ICON_TYPES.ARROW_RIGHT} />
        </a>

        <a href="#" className={styles.linkButton} onClick={createGoToSection(COOKIE_CONSENT_PANEL_ID)}>
          <span dangerouslySetInnerHTML={{ __html: consentInfoLinkText }} />
          <Icon type={ICON_TYPES.ARROW_RIGHT} />
        </a>
      </div>

      <a href="#" className={styles.categoryItem} onClick={createGoToSection(TECHNICALLY_NECESSARY_PANEL_ID)}>
        <span dangerouslySetInnerHTML={{ __html: technicallyNecessaryLinkText }} />
        <Icon type={ICON_TYPES.ARROW_RIGHT} />
      </a>

      <Button
        type="button"
        caption={acceptButtonText}
        id="cookieBannerConfirmationButton"
        onClickCallBack={onAccept}
        styling={acceptButtonStyles}
        name="cookieBannerConfirmation"
      />
    </div>
  );
};

MainPanel.propTypes = {
  acceptButtonText: PropTypes.string.isRequired,
  consentInfoLinkText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  legalInfoLinkText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  openSecondaryPanel: PropTypes.func.isRequired,
  technicallyNecessaryLinkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default MainPanel;
