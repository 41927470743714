import get from 'lodash/get';
import LegalNotice from './LegalNotice';
import { withData } from '../../../../services/surveyDataProvider';

export const mapSurveyDataToProps = (surveyData) => {
  const { cookieBanner } = surveyData;
  return {
    title: get(cookieBanner, 'legalInfo.title', ''),
    description: get(cookieBanner, 'legalInfo.message', '')
  };
};

export default withData(mapSurveyDataToProps)(LegalNotice);
