import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../common/Title';
import styles from './CookieConsent.scss';

const buildDetailList = (
  descriptionKey, description,
  consentIdKey, consentId,
  consentDateKey, consentDate
) => ([
  { title: descriptionKey, value: description },
  { title: consentIdKey, value: consentId },
  { title: consentDateKey, value: consentDate }
]);

const CookieConsent = ({
  title: cookieConsentTitle,
  descriptionLabel,
  description,
  consentIdLabel,
  consentId,
  consentDateLabel,
  consentDate
}) => {
  const details = buildDetailList(
    descriptionLabel, description,
    consentIdLabel, consentId,
    consentDateLabel, consentDate
  );

  return (
    <div className={styles.container}>
      <Title text={cookieConsentTitle} />
      <div className={styles.description}>
        {details.map(({ title, value }) => (
          <p className={styles.row} key={title}>
            <span className={styles.title} dangerouslySetInnerHTML={{ __html: `${title}:` }} />
            <span dangerouslySetInnerHTML={{ __html: value }} />
          </p>
        ))}
      </div>
    </div>
  );
};

CookieConsent.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  consentIdLabel: PropTypes.string.isRequired,
  consentId: PropTypes.string.isRequired,
  consentDateLabel: PropTypes.string.isRequired,
  consentDate: PropTypes.string
};

CookieConsent.defaultProps = {
  consentDate: (new Date()).toISOString()
};

export default CookieConsent;
