import defaultsDeep from 'lodash/defaultsDeep';
import merge from 'lodash/merge';
import RandomStringGenerator from '../randomStringGenerator';
import validateAttributesPresence from '../../utils/validateAttributesPresence';
import validateAttributesType from '../../utils/validateAttributesType';

const REQUIRED_ATTRIBUTES = ['caption', 'question'];
const TYPED_ATTRIBUTES = { options: Set };

/**
 * value from a textfield will be long so we slice everything to just the
 * first value.
 *
 * undefined --> 'un'
 * null      --> 'nu'
 * 1         --> '1'
 * 12        --> '12'
 */
export const generateId = ({ questionId, htmlInputId = '', value = '' }) => {
  const valueId = String(value).substring(0, 2);
  return `${questionId}_${htmlInputId}_${valueId}_${RandomStringGenerator.generate()}`;
};

function generateDefaultAttributes() {
  return {
    options: new Set(),
    hasAnswer: false,
    hasChildren: false,
    hasParent: false,
    isRequired: false,
    isValid: true,
    isShown: true,
    conditions: []
  };
}

export default class Option {
  constructor(args = {}) {
    if (typeof args !== 'object') {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('[Option]: args must be an object');
      }
      return {};
    }
    merge(this, args);
    defaultsDeep(this, generateDefaultAttributes());

    if (this.id === undefined || this.id === null) {
      this.id = generateId({
        questionId: this.question,
        htmlInputId: this.htmlInput,
        value: this.value
      });
    }

    validateAttributesPresence(this, REQUIRED_ATTRIBUTES);
    validateAttributesType(this, TYPED_ATTRIBUTES);
  }

  hasSameId(optionOrId) {
    const id = optionOrId.id ? optionOrId.id : optionOrId;
    return this.id === id;
  }

  addCondition(condition) {
    if (!condition) return;

    this.conditions.push(condition.id);
  }
}
