/* eslint-disable react/jsx-closing-tag-location */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { DragLayer } from 'react-dnd';
import { getTranslateFromCoordinates } from './helpers';
import styles from './CustomDragLayer.scss';

const {
  shape,
  number,
  bool,
  func
} = PropTypes;
export class BareDragLayer extends Component {
  static propTypes = {
    /** Starting mouse position, will be passed in automatically */
    startingOffset: shape({
      x: number.isRequired,
      y: number.isRequired
    }),
    /** Function that receives the element index and returns the preview for that element */
    getPreview: func,
    /** Item is being dragged */
    isDragging: bool,
    /** Set item is being dragged */
    setIsDragging: func,
    /** Marks drag operation ended */
    didDrop: bool,
    /** Dragged item CSS height */
    draggedItemHeight: number,
    /** Marks dragging down operation */
    isDraggingDown: bool,
    /** Marks dragging up operation */
    isDraggingUp: bool,
    /** Area taken by list */
    listBoundingReact: shape({
      top: number,
      right: number,
      bottom: number,
      left: number
    }),
    /** Index of dragged item */
    rowIndex: number,
    /** Index of drop target */
    renderableDropTargetIndex: number
  };

  static defaultProps = {
    startingOffset: null,
    isDragging: null,
    getPreview: null,
    didDrop: null,
    draggedItemHeight: null,
    isDraggingDown: null,
    isDraggingUp: null,
    listBoundingReact: null,
    rowIndex: null,
    renderableDropTargetIndex: null,
    setIsDragging: null
  };

  componentWillReceiveProps(nextProps) {
    const {
      setIsDragging,
      isDragging,
      didDrop
    } = nextProps;

    setIsDragging(isDragging && !didDrop);
  }

  render() {
    const {
      didDrop,
      draggedItemHeight,
      getPreview,
      isDragging,
      isDraggingDown,
      isDraggingUp,
      listBoundingReact,
      rowIndex,
      startingOffset,
      renderableDropTargetIndex
    } = this.props;

    return isDragging && !didDrop && (
      <div style={{ pointerEvents: 'none' }} >
        {<div
          className={styles.drag_layer}
          style={{
            transform:
              getTranslateFromCoordinates(startingOffset, listBoundingReact, draggedItemHeight)
          }}
        >
          <div>
            {
              getPreview({
                rowIndex,
                dropTargetIndex: renderableDropTargetIndex,
                isDraggingUp,
                isDraggingDown
              })
            }
          </div>
        </div>}
      </div>
    );
  }
}

export const CustomDragLayer = DragLayer((monitor) => {
  const {
    dropTargetIndex,
    isDraggingDown,
    isDraggingUp,
    rowIndex,
    renderableDropTargetIndex
  } = monitor.getItem() || {};

  return (
    {
      didDrop: monitor.didDrop(),
      dropTargetIndex,
      isDragging: monitor.isDragging(),
      isDraggingDown,
      isDraggingUp,
      rowIndex,
      startingOffset: monitor.getSourceClientOffset(),
      renderableDropTargetIndex
    }
  );
})(BareDragLayer);
