import { compose } from 'redux';
import { connect } from 'react-redux';
import { withData } from '../../services/surveyDataProvider';
import withCondition from '../../commons/withCondition';
import RankingOrder from './RankingOrder';
import {
  getAllOptionsFilteredByIPC,
  getSelectedOptionsFilteredByIPC,
  getUnselectedOptionsFilteredByIPC
} from './selectors';
import {
  setAlternativeAtFactory,
  firstSelectionOnDnDFactory,
  unselectAlternativeFactory
} from './actions';

export const mapStateToProps = (state, ownProps) => ({
  row: getAllOptionsFilteredByIPC(state, ownProps),
  selected: getSelectedOptionsFilteredByIPC(state, ownProps),
  unselected: getUnselectedOptionsFilteredByIPC(state, ownProps)
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  setAlternativeAt: setAlternativeAtFactory(dispatch, ownProps),
  firstSelectionOnDnD: firstSelectionOnDnDFactory(dispatch, ownProps),
  unselectAlternative: unselectAlternativeFactory(dispatch, ownProps)
});

export const mapSurveyDataToProps = (surveyData, ownProps) => ({
  ...surveyData.blocks[ownProps.id]
});

export default compose(
  withCondition,
  withData(mapSurveyDataToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(RankingOrder);

