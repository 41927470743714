import { connect } from 'react-redux';
import Geolocation from './Geolocation';
import { setPageFetchingStatus, setPageReadyStatus } from '../../redux/actions/setPageStatus';

const mapStateToProps = state => ({
  fetchTimeout: state.pageSettings.geolocationTimeout
});

const actions = {
  setPageFetchingStatus,
  setPageReadyStatus
};

export default connect(mapStateToProps, actions)(Geolocation);
