import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Icon, { ICON_TYPES } from '../../commons/Icon';
import Question from '../../commons/Question';
import styles from './fileupload.scss';
import FileRow from './FileRow';
import { isSameFiles } from './helpers/file';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.handleDroppedFiles = this.handleDroppedFiles.bind(this);
    this.handleUploadButton = this.handleUploadButton.bind(this);
    this.setComponentContainerRef = this.setComponentContainerRef.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('drop', this.handleDroppedFiles);
  }

  shouldComponentUpdate(nextProps) {
    const { 
      actionButtonLabel: nextActionButtonLabel,
      files: nextFiles,
      fileMaxError: nextFileMaxError
    } = nextProps;
    const { 
      actionButtonLabel,
      files,
      fileMaxError
    } = this.props;
    return !isSameFiles(files, nextFiles)
      || fileMaxError !== nextFileMaxError
      || actionButtonLabel !== nextActionButtonLabel;
  }

  componentWillUnmount() {
    document.removeEventListener('drop', this.handleDroppedFiles);
  }

  setInputRef($uploadInput) {
    this.uploadInputRef = $uploadInput;
  }

  setComponentContainerRef($divContainer) {
    this.containerRef = $divContainer;
  }

  handleDroppedFiles(e) {
    e.preventDefault();
    const isDroppedOn = e.target === this.containerRef || this.containerRef.contains(e.target);
    if (!isDroppedOn) return;

    const { files } = e.dataTransfer;
    if (!files.length) return;

    this.uploadInputRef.value = '';
    this.props.onFileUpload({ target: { files } });
  }

  handleUploadButton(e) {
    e.stopPropagation();
    e.preventDefault();
    this.uploadInputRef.value = '';
    this.props.onUploadButtonClick(e);
    this.uploadInputRef.click();
  }

  render() {
    const {
      acceptMultipleFiles,
      fields,
      fileMaxError,
      files,
      isMobile,
      onClearMaxError,
      onFileDelete,
      onFileError,
      onFileUpload,
      questionId,
      reachedMaxUpload,
      requiredField,
      showUploadIcon,
      validationEmpty,
      validationFailed,
      validationMessages,

      actionButtonLabel,
      consentLabel,
      fileFormatLabel,
      inputAcceptExtensionsLabel,
      maxFileSizeLabel,
      maxFilesLabel,
      prefixActionButtonLabel,
      questionLabel
    } = this.props;

    const disabledButtonStyle = { [styles.disabledButton]: reachedMaxUpload };

    return (
      <div ref={this.setComponentContainerRef}>
        <Question
          caption={questionLabel}
          requiredField={requiredField}
          validationEmpty={validationEmpty}
          validationFailed={validationFailed}
          validationMessages={validationMessages}
        />
        <div className={styles.uploadFormContainer}>
          <ul className={styles.fileListContainer}>
            {files.map(file => (
              <FileRow
                key={`${file.filename || ''}${file.order}`}
                fields={fields}
                file={file}
                onFileDelete={onFileDelete}
                onFileError={onFileError}
              />
            ))}
            {fileMaxError && (
              <FileRow
                key={`${questionId}max-error`}
                file={fileMaxError}
                onFileDelete={onFileDelete}
                onFileError={onClearMaxError}
              />
            )}
          </ul>
          {showUploadIcon && (
            <Icon type={ICON_TYPES.ARROW_UP} containerStyles={styles.uploadIcon} />
          )}
          {prefixActionButtonLabel ? (
            <p className={styles.actionText}>
              {prefixActionButtonLabel}
              <button
                className={classNames(styles.inlineBrowseFilesButton, disabledButtonStyle)}
                disabled={reachedMaxUpload}
                onClick={this.handleUploadButton}
              >
                {actionButtonLabel}
              </button>
            </p>
          ) : (
            <button
              className={classNames(styles.browseFilesButton, disabledButtonStyle)}
              disabled={reachedMaxUpload}
              onClick={this.handleUploadButton}
            >
              {actionButtonLabel}
            </button>
          )}
          <p className={styles.metaInfoText}>{`${fileFormatLabel}`}</p>
          {isMobile ? (
            <div>
              <p className={styles.metaInfoText}>{maxFilesLabel}</p>
              <p className={styles.metaInfoText}>{maxFileSizeLabel}</p>
            </div>
          ) : (
            <p className={styles.metaInfoText}>
              {maxFilesLabel} {maxFileSizeLabel}
            </p>
          )}
          <p className={styles.consentText}>{consentLabel}</p>
          <input
            id={`${questionId}-upload-input`}
            ref={this.setInputRef}
            hidden
            type="file"
            multiple={acceptMultipleFiles}
            accept={inputAcceptExtensionsLabel}
            onChange={onFileUpload}
          />
        </div>
      </div>
    );
  }
}

const {
  any,
  arrayOf,
  bool,
  func,
  number,
  shape,
  string
} = PropTypes;

FileUpload.propTypes = {
  acceptMultipleFiles: bool.isRequired,
  fields: shape({ [string]: string }),
  fileMaxError: shape({
    error: number,
    errorMessage: string
  }),
  files: arrayOf(any),
  isMobile: bool.isRequired,
  onClearMaxError: func.isRequired,
  onFileDelete: func.isRequired,
  onFileError: func.isRequired,
  onFileUpload: func.isRequired,
  onUploadButtonClick: func.isRequired,
  questionId: string.isRequired,
  reachedMaxUpload: bool.isRequired,
  requiredField: string,
  showUploadIcon: bool.isRequired,
  validationEmpty: string,
  validationFailed: string,
  validationMessages: arrayOf(string),

  // labels
  actionButtonLabel: string,
  consentLabel: string,
  fileFormatLabel: string,
  inputAcceptExtensionsLabel: string,
  maxFileSizeLabel: string,
  maxFilesLabel: string,
  prefixActionButtonLabel: string,
  questionLabel: string
};

FileUpload.defaultProps = {
  fields: {},
  fileMaxError: null,
  files: [],
  requiredField: '',
  validationEmpty: '',
  validationFailed: '',
  validationMessages: [],

  // labels
  actionButtonLabel: '',
  consentLabel: '',
  fileFormatLabel: '',
  inputAcceptExtensionsLabel: '',
  maxFileSizeLabel: '',
  maxFilesLabel: '',
  prefixActionButtonLabel: '',
  questionLabel: ''
};
