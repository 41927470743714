import { isSurveyAPIEnabled } from '../../services/featureFlags';
import { questionAnswered } from '../../services/api/events';

export const SET_COMPONENT_ANSWER = 'SET_COMPONENT_ANSWER';

const setComponentAnswer = (value, componentId, field, extraParams = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_COMPONENT_ANSWER,
      payload: {
        value,
        componentId,
        field
      }
    });

    if (isSurveyAPIEnabled()) {
      const { components } = getState();
      const component = components[componentId] || {};
      const previousValue = component.values && component.values[field];
      questionAnswered(field, value, previousValue, extraParams);
    }
  };

export default setComponentAnswer;
