import { combineReducers } from 'redux';
import mediaQuery from './mediaQueryWatcher';
import htmlInputs from './htmlInputs';
import options from './options';
import questions from './questions';
import pageSettings from './pageSettings';
import surveyStatus from './surveyStatus';
import components from './components';
import cookieBanner from './cookieBanner';

const rootReducer = combineReducers({
  pageSettings,
  htmlInputs,
  options,
  questions,
  mediaQuery,
  surveyStatus,
  components,
  cookieBanner,
  navigation: (state = {}) => state,
  conditions: (state = {}) => state,
  fields: (state = {}) => state,
  fieldControlledBy: (state = {}) => state
});

export default rootReducer;
