import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { getStore as getSurveyData } from '../../services/surveyDataProvider';

const services = { getSurveyData };

const enhancedThunk = thunk.withExtraArgument(services);

export default function createStoreWithInitialState(initialState) {
  return createStore(rootReducer, initialState, applyMiddleware(enhancedThunk));
}
