import { ANSWER_QUESTION } from '../../actions/answerQuestion';
import hasPayloadAndResourcesAreLinked from '../../../utils/hasPayloadAndResourcesAreLinked';
import Option from '../../../models/option';
import isDefined from '../../../utils/isDefined';

export function determineNewValue({ htmlInput, option, answerValue }) {
  return htmlInput.isTextAnswer() ? answerValue : option.value;
}

export function determineHasAnswer({ htmlInput, option, answerValue }) {
  if (htmlInput.isTextAnswer()) {
    return answerValue && answerValue.length && isDefined(answerValue);
  }
  if (htmlInput.isCheckboxType()) {
    const isSameValue = answerValue === option.value;
    return option.hasAnswer ? !isSameValue : isSameValue;
  }
  if (htmlInput.isRadioType()) {
    const isSameValue = answerValue === option.value;
    if (!option.hasAnswer && isSameValue) {
      return true;
    }
    return false;
  }
  return isDefined({ value: answerValue });
}

export function updateOptions({
  state,
  optionIds,
  newOptions = {},
  htmlInputs,
  answerValue
}) {
  const updatedOptions = newOptions;
  optionIds.forEach((id) => {
    const option = state[id];
    const htmlInput = htmlInputs[option.htmlInput];
    if (!htmlInput) return;

    const value = determineNewValue({ htmlInput, option, answerValue });
    const hasAnswer = determineHasAnswer({
      htmlInput,
      option,
      value,
      answerValue
    });
    const newOption = new Option({ ...option, hasAnswer, value });
    updatedOptions[newOption.id] = newOption;

    if (option.hasChildren) {
      updateOptions({
        state,
        optionIds:
        option.options,
        newOptions: updatedOptions,
        htmlInputs
      });
    }

    // for ratingGrid
    if (option.hasNaOption) {
      updateOptions({
        state,
        optionIds: [option.naOption],
        newOptions: updatedOptions,
        htmlInputs
      });
    }
  });
  return updatedOptions;
}

export default function options(state = {}, action) {
  if (!hasPayloadAndResourcesAreLinked(state, action)) return state;
  switch (action.type) {
    case ANSWER_QUESTION: {
      const { htmlInputId, htmlInputs, value } = action.payload;
      const htmlInput = htmlInputs[htmlInputId];
      const newOptions = updateOptions({
        state,
        optionIds: htmlInput.options,
        htmlInputs,
        answerValue: value
      });
      return {
        ...state,
        ...newOptions
      };
    }
    default: return state;
  }
}
