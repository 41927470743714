/* eslint-disable react/forbid-component-props */
import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import styles from './table.scss';
import commonStyles from '../../css/outline.scss';
import { isAdvancedAccessibilityEnabled } from '../../services/featureFlags';

function mainContainerStyles({
  containerStyles,
  hasBorderStyle,
  hasFixedLayoutStyle,
  hasHoverStyle
}) {
  return classNames(styles.table, containerStyles, {
    [commonStyles.hasBorder]: hasBorderStyle,
    [commonStyles.hasHover]: hasHoverStyle,
    [styles.table_hasFixedLayout]: hasFixedLayoutStyle
  });
}

export default function Table(props) {
  if (!props.shouldRender) return null;
  const {
    id,
    ariaHidden,
    ariaRequired,
    ariaLabel,
    ariaRole,
    children,
    containerElement,
    containerStyles,
    hasBorderStyle,
    hasFixedLayoutStyle,
    hasHoverStyle,
    onClick,
    onMouseLeave,
    onMouseOut,
    onMouseOver
  } = props;

  const tableContainerStyles = mainContainerStyles({
    containerStyles,
    hasBorderStyle,
    hasFixedLayoutStyle,
    hasHoverStyle
  });
  const Container = containerElement;
  const ariaRequiredProp = isAdvancedAccessibilityEnabled() && ariaRequired ? { 'aria-required': true } : {};
  return (
    <Container
      id={id}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      {...ariaRequiredProp}
      className={tableContainerStyles}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      role={ariaRole}
      >
      {children}
    </Container>
  );
}

const {bool, func, node, string} = PropTypes;

Table.propTypes = {
  id: string,
  ariaHidden: bool,
  ariaLabel: string,
  ariaRole: string,
  children: node,
  containerStyles: string,
  containerElement: string,
  hasBorderStyle: bool,
  hasFixedLayoutStyle: bool,
  hasHoverStyle: bool,
  onClick: func,
  onMouseLeave: func,
  onMouseOut: func,
  onMouseOver: func,
  shouldRender: bool
};

Table.defaultProps = {
  containerElement: 'div',
  hasFixedLayoutStyle: true,
  shouldRender: true
};
