import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ResizeObserver } from '@juggle/resize-observer';
import smoothscroll from 'smoothscroll-polyfill';
import styles from './scrollableContent.scss';
import Icon, { ICON_TYPES } from '../Icon/index';

smoothscroll.polyfill();

export const SCROLL_DIRECTION = { LEFT: 'LEFT', RIGHT: 'RIGHT' };
const OVERFLOW_TO_SHOW_SCROLL = 5;
const SCROLL = 300;

class ScrollableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInStart: true,
      isInEnd: false,
      hasOverflow: false
    };
    this.onScroll = this.onScroll.bind(this);
    this.onArrowClick = this.onArrowClick.bind(this);
    this.setRef = this.setRef.bind(this);
    this.evaluateContentOverflow = this.evaluateContentOverflow.bind(this);
    this.contentSizeObserver = new ResizeObserver(this.evaluateContentOverflow);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    this.contentSizeObserver.observe(node);
  }

  componentWillUnmount() {
    this.contentSizeObserver.disconnect();
  }

  setRef(container) {
    this.$container = container;
  }

  onScroll({ target }) {
    if (!target || this.$container !== target) return;
    const { scrollLeft, scrollWidth, clientWidth } = this.$container;
    const scrollableSpace = scrollWidth - clientWidth;
    this.setState({
      isInStart: scrollLeft < OVERFLOW_TO_SHOW_SCROLL,
      isInEnd: (scrollableSpace - scrollLeft) < OVERFLOW_TO_SHOW_SCROLL
    });
  }

  onArrowClick(direction) {
    if (!this.$container) return;
    const { scrollLeft, scrollWidth, clientWidth } = this.$container;
    const scrollableSpace = scrollWidth - clientWidth;

    const position = direction === SCROLL_DIRECTION.LEFT ?
      Math.max(0, scrollLeft - (clientWidth-SCROLL)) :
      Math.min(scrollableSpace, scrollLeft + (clientWidth-SCROLL));

    this.$container.scrollTo({
      top: 0,
      left: position,
      behavior: 'smooth'
    });
  }

  evaluateContentOverflow() {
    if (!this.$container) return;
    const { hasOverflow: prevValue } = this.state;
    const { scrollWidth, clientWidth } = this.$container || {};
    const hasOverflow = scrollWidth > clientWidth;
    if (hasOverflow === prevValue) return;
    this.setState({ hasOverflow });
  }

  render() {
    const { children, className } = this.props;
    const { isInStart, isInEnd, hasOverflow } = this.state;
    const mainContainerStyles = classNames(styles.mainContainer, className);
    const wrapperStyles = classNames(styles.contentWrapper, styles.overflowYVisibleWorkaround, {
      [styles.hideLeftIndicator]: !hasOverflow || isInStart,
      [styles.hideRightIndicator]: !hasOverflow || isInEnd
    });
    return (
      <div className={mainContainerStyles}>
        <div className={wrapperStyles} onScroll={this.onScroll} ref={this.setRef}>
          <span className={`${styles.icon} ${styles.leftIcon}`} onClick={() => this.onArrowClick(SCROLL_DIRECTION.LEFT)} role="button" id={`leftIconButton`}>
            <Icon type={ICON_TYPES.ARROW_DROPDOWN} containerStyles={styles.leftIconContent} />
          </span>
          {children}
          <span className={`${styles.icon} ${styles.rightIcon}`} onClick={() => this.onArrowClick(SCROLL_DIRECTION.RIGHT)} role="button" id={`rightIconButton`}>
            <Icon type={ICON_TYPES.ARROW_DROPDOWN} containerStyles={styles.rightIconContent} />
          </span>
        </div>
      </div>
    );
  }
}

ScrollableContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ScrollableContent;
