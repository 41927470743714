import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import Question from '../../commons/Question';
import stripHtml from '../../utils/stripHtml';
import layoutStyles from '../../css/layout.scss';
import styles from './textField.scss';
import { isEnterKey } from '../../utils/detectKey';
import { isTextfieldWithoutFieldsetEnabled } from '../../services/featureFlags';

export default class TextField extends Component {

  static propTypes = {
    formkey: PropTypes.string,
    caption: PropTypes.string,
    maxChars: PropTypes.number,
    validationEmpty: PropTypes.string,
    validationFailed: PropTypes.string,
    requiredField: PropTypes.string,
    validationMessages: PropTypes.arrayOf(PropTypes.string),
    setComponentAnswer: PropTypes.func,
    isSubComponent: PropTypes.bool
  };

  static defaultProps = {
    formkey: '',
    caption: '',
    maxChars: 0,
    validationEmpty: null,
    validationFailed: null,
    validationMessages: [],
    requiredField: null,
    isSubComponent: false
  };

  constructor(props) {
    super(props);

    this.state = { value: this.props.value || '' };
    this.handleChange = this.handleChange.bind(this);
    this.debouncedSetAnswer = debounce(this.setAnswer.bind(this), 300);
  }

  setAnswer(value) {
    this.props.setComponentAnswer(value);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ value });
    this.debouncedSetAnswer(value);
  }

  onKeyPress(e) {
    return isEnterKey(e) && e.preventDefault();
  }

  render() {
    const {
      formkey,
      caption,
      maxChars,
      validationEmpty,
      validationFailed,
      requiredField,
      validationMessages,
      isSubComponent
    } = this.props;
    const ariaRequired = (requiredField || validationEmpty) && { 'aria-required': true };
    const ariaInvalid = (validationFailed || validationEmpty) && { 'aria-invalid': true };
    const maxLength = maxChars ? { maxLength: maxChars } : {};
    const textFieldContainerStyles = classNames(styles.textfieldContainer, layoutStyles.answer);

    const component = [
      !isSubComponent && <Question
        key="question"
        ariaId={formkey}
        hasLegendCaption={!isTextfieldWithoutFieldsetEnabled()}
        caption={caption}
        validationEmpty={validationEmpty}
        validationFailed={validationFailed}
        requiredField={requiredField}
        validationMessages={validationMessages}
      />,
      <div key="input" className={classNames({ [layoutStyles.answers]: !isSubComponent })}>
        <div className={textFieldContainerStyles}>
          <input
            id={formkey}
            name={formkey}
            type="text"
            title={stripHtml(caption)}
            className={styles.textField}
            onChange={this.handleChange}
            value={this.state.value}
            onKeyPress={this.onKeyPress}
            {...maxLength}
            {...ariaRequired}
            {...ariaInvalid}
          />
        </div>
      </div>
    ];

    if (isTextfieldWithoutFieldsetEnabled()) {
      return (
        <div className="questionBlock textfieldQuestion">
          {component}
        </div>
      );
    }

    return (
      <div className="questionBlock textfieldQuestion">
        <fieldset>
          {component}
        </fieldset>
      </div>
    );
  }
}
