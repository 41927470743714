import camelCase from 'lodash/camelCase';
import { QUESTION_TYPES } from '../../question';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';

export const MATCH_TRIP_ADVISOR = 'tripAdvisorWidgetPage';

export const extractTripAdvisorTranslations = (rawData) => {
  const { translations } = rawData;
  if (!translations && typeof translations !== 'object') return {};

  const tripAdvisorAttributes = {};

  // capture trip advisor patterns like TA_CITY, TA_COUNTRY where CITY and COUNTRY
  // will be in capture group 1
  const tripAdvisorAttributeRegex = /^TA_(.+)/; // has capture group 1

  Object.keys(translations).forEach((key) => {
    const matchedAttributes = key.match(tripAdvisorAttributeRegex);
    if (matchedAttributes) {
      const attribute = camelCase(matchedAttributes[1]); // camelcase regex capture group 1
      tripAdvisorAttributes[attribute] = translations[key];
    }
  });
  return tripAdvisorAttributes;
};

export default class TripAdvisorLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_TRIP_ADVISOR;
    return shouldNormalize;
  }

  normalize({ id, questionData, rawData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;

    const resourcesManager = new ResourcesRelationshipManager();
    const { requestUrl, debugInfo, html_TA_WHATS_THIS_DETAIL: popupInfo } = questionData;
    const widgetInfo = extractTripAdvisorTranslations(rawData);
    resourcesManager.createOrUpdateQuestion({
      caption: '',
      type: QUESTION_TYPES.TRIP_ADVISOR,
      id,
      requestUrl,
      debugInfo,
      popupInfo,
      widgetInfo
    });

    return resourcesManager.resources();
  }
}
