import { compose } from 'redux';
import { connect } from 'react-redux';
import answerQuestion from '../actions';
import Option from './option';
import withCondition from '../../../commons/withCondition';

function mapStateToProps(state, ownProps) {
  const options = state.options || {};
  const option = options[ownProps.id] || {};
  const optionProps = { ...option };
  // for ChooseMany
  const htmlInputs = state.htmlInputs || {};
  const htmlInput = htmlInputs[option.htmlInput] || {};
  optionProps.htmlInputFormKey = htmlInput.formKey;
  return optionProps;
}

const mapDispatchToProps = { answerQuestion };

export default compose(
  withCondition,
  connect(mapStateToProps, mapDispatchToProps)
)(Option);
