import PropTypes from 'prop-types';

//----------------------------------------------------------------------
// STYLES
//----------------------------------------------------------------------

const flex = '1 1 auto';

export const customStyles = {
  option: (base, { isFocused }) => ({
    ...base,
    flex,
    cursor: isFocused ? 'pointer' : 'default'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    flex,
    border: 'none',
    boxShadow: 'none',
    outline: isFocused ? 'solid 1px #3b4158' : 'none'
  }),
  singleValue: base => ({ ...base }),
  container: base => ({
    ...base,
    // width: '30em',
    flex
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: base => ({
    ...base,
    width: '100%'
  }),
  menu: base => ({
    ...base,
    zIndex: 1000
  })
};

//----------------------------------------------------------------------
// MISC
//----------------------------------------------------------------------

export const filterOptions = options => inputValue =>
  options.filter(items =>
    items.label.toLowerCase().includes(inputValue.toLowerCase()));

export const optionsToMap = arr =>
  new Map(arr.map((item, idx) => [item.value, { ...item, idx }]));

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

export const selectPropTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired
};
