/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Calendar, {
  getNumberOfDaysInMonthAndWeekdayOfLastDay
} from '../../models/calendar';
import CalendarTitle from './CalendarTitle';
import WeekdayHeaders from './WeekdayHeaders';
import Week from './Week';
import layoutStyles from '../../css/layout.scss';
import styles from './calendarDatePicker.scss';

const { bool, func, number, object, shape, string } = PropTypes;

export default class CalendarDatePicker extends PureComponent {
  static propTypes = {
    ariaLabelForSelected: string,
    calendarFullDateFormat: string,
    calendarDateFormat: string,
    calendarTranslations: object,
    calendarTitleFormat: string,
    containerStyles: string,
    initSelectedDay: number,
    initSelectedValue: string,
    inverseLeftRightArrowMovement: bool,
    nextMonthAriaLabel: string,
    notInTheFuture: bool,
    onDayClick: func,
    onDayTabPress: func,
    prevMonthAriaLabel: string,
    initialState: object,
    visibleCalendar: shape({
      month: number,
      year: number
    })
  };

  constructor(props) {
    super(props);
    const {
      calendarDateFormat,
      calendarFullDateFormat,
      calendarTitleFormat,
      calendarTranslations,
      initSelectedDay,
      initSelectedValue,
      visibleCalendar
    } = props;

    const todayDate = new Date();
    const today = {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth(),
      day: todayDate.getDate()
    };
    const visibleCalendarDate = new Date(visibleCalendar.year, visibleCalendar.month, 1);
    const calendarCalculator = new Calendar({
      dateFormat: calendarDateFormat,
      fullDateFormat: calendarFullDateFormat,
      calendarTitleFormat,
      ...calendarTranslations
    });
    this._calendarCalculator = calendarCalculator;

    const calendarTitle = calendarCalculator.getCalendarTitleForDate(visibleCalendarDate, calendarTitleFormat);
    const calendar = calendarCalculator.getCalendarForDate(visibleCalendarDate);

    if (props.initialState) {
      this.state = props.initialState;
    } else {
      this.state = {
        calendar,
        calendarTitle,
        selectedValue: initSelectedValue,
        tabbableDay: initSelectedDay || today.day,
        currentMonth: visibleCalendar.month,
        currentYear: visibleCalendar.year,
        focusThisDay: null,
        today
      };
    }

    this.handleChangeCalendarToDate = this.handleChangeCalendarToDate.bind(this);
    this.handleChangeCalendarToPreviousMonth = this.handleChangeCalendarToDate.bind(this, -1);
    this.handleChangeCalendarToNextMonth = this.handleChangeCalendarToDate.bind(this, 1);

    this.handleOnDayClickUpdateSelectedValue = this.handleOnDayClickUpdateSelectedValue.bind(this);
    this.handleKeyboardNavigationToFocusNextDay = this.handleKeyboardNavigationToFocusNextDay.bind(this);
  }

  shouldShowNextMonthButton({notInTheFuture, today, currentMonth, currentYear}) {
    if (!notInTheFuture) return true;
    return (currentYear < today.year) || (currentYear === today.year && currentMonth < today.month);
  }

  handleOnDayClickUpdateSelectedValue(newSelectedValue, day) {
    const {onDayClick} = this.props;
    const {selectedValue} = this.state;
    if (selectedValue === newSelectedValue) {
      this.setState({selectedValue: null, focusThisDay: day, tabbableDay: day}, () => {
        if (onDayClick) onDayClick(null);
      });
    } else {
      this.setState({selectedValue: newSelectedValue, focusThisDay: day, tabbableDay: day}, () => {
        if (onDayClick) onDayClick(newSelectedValue);
      });
    }
  }

  handleKeyboardNavigationToFocusNextDay(nextDay) {
    const nextDayIsInsideRangeOfAllMonths = (nextDay >= 1 && nextDay <= 28);
    if (nextDayIsInsideRangeOfAllMonths) return this.setState({focusThisDay: nextDay, tabbableDay: nextDay});

    const {currentMonth, currentYear} = this.state;
    const {calendarTitleFormat} = this.props;

    if (nextDay <= 0) {
      const nextState = this._calculateNextStateForDay({currentYear, currentMonth, nextDay, calendarTitleFormat});
      return this.setState(nextState);
    }

    const [numberOfDaysInMonth] = getNumberOfDaysInMonthAndWeekdayOfLastDay(currentYear, currentMonth);
    if (nextDay <= numberOfDaysInMonth) return this.setState({focusThisDay: nextDay, tabbableDay: nextDay});

    const nextState = this._calculateNextStateForDay({currentYear, currentMonth, nextDay, calendarTitleFormat});
    return this.setState(nextState);
  }

  _calculateNextStateForDay({currentYear, currentMonth, nextDay, calendarTitleFormat = this.props.calendarTitleFormat}) {
    const newDateInPreviousOrNextMonth = new Date(currentYear, currentMonth, nextDay);
    const newCalendar = this._calendarCalculator.getCalendarForDate(newDateInPreviousOrNextMonth);
    const newCalendarTitle = this._calendarCalculator.getCalendarTitleForDate(newDateInPreviousOrNextMonth, calendarTitleFormat);
    const focusThisDay = newDateInPreviousOrNextMonth.getDate();
    return {
      calendar: newCalendar,
      calendarTitle: newCalendarTitle,
      currentMonth: newDateInPreviousOrNextMonth.getMonth(),
      currentYear: newDateInPreviousOrNextMonth.getFullYear(),
      focusThisDay,
      tabbableDay: focusThisDay
    };
  }

  handleChangeCalendarToDate(nextMonthOrPreviousMonth, e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    const {calendarTitleFormat} = this.props;
    const {currentMonth, currentYear} = this.state;
    const newMonth = currentMonth + nextMonthOrPreviousMonth;
    const newMonthDate = new Date(currentYear, newMonth, 1);
    const newCalendar = this._calendarCalculator.getCalendarForDate(newMonthDate);
    const newCalendarTitle = this._calendarCalculator.getCalendarTitleForDate(newMonthDate, calendarTitleFormat);

    let {tabbableDay} = this.state;
    if (tabbableDay > 28) {
      const [numberOfDaysInMonthInNewMonth] = getNumberOfDaysInMonthAndWeekdayOfLastDay(newMonthDate.getFullYear(), newMonthDate.getMonth());
      if (tabbableDay > numberOfDaysInMonthInNewMonth) tabbableDay = numberOfDaysInMonthInNewMonth;
    }

    this.setState({
      calendar: newCalendar,
      calendarTitle: newCalendarTitle,
      currentMonth: newMonthDate.getMonth(),
      currentYear: newMonthDate.getFullYear(),
      focusThisDay: null,
      tabbableDay
    });
  }

  focusTabbableDay() {
    const {tabbableDay} = this.state;
    if (!tabbableDay) return;
    this.setState({focusThisDay: null}, () => {
      this.setState({focusThisDay: tabbableDay});
    });
  }

  updateSelectedValue(value, date) {
    if (!(date instanceof Date)) {
      console.log('not a valid date');
      return;
    }

    const newState = this._calculateNextStateForDay({
      currentYear: date.getFullYear(),
      currentMonth: date.getMonth(),
      nextDay: date.getDate()
    });
    newState.selectedValue = value;
    this.setState(newState);
  }

  removeSelectedValue() {
    this.setState({selectedValue: ''});
  }

  render() {
    const {
      ariaLabelForSelected,
      containerStyles,
      inverseLeftRightArrowMovement,
      nextMonthAriaLabel,
      notInTheFuture,
      onDayTabPress,
      prevMonthAriaLabel
    } = this.props;

    const {
      calendar,
      calendarTitle,
      currentYear,
      currentMonth,
      focusThisDay,
      selectedValue,
      tabbableDay,
      today
    } = this.state;

    const allWeekDayTitles = this._calendarCalculator.getAllWeekdays();

    const weeks = [];
    for (let week = 0; week < calendar.length; week++) {
      weeks.push(
        <Week
          key={week}
          ariaLabelForSelected={ariaLabelForSelected}
          days={calendar[week]}
          focusThisDay={focusThisDay}
          inverseLeftRightArrowMovement={inverseLeftRightArrowMovement}
          notInTheFuture={notInTheFuture}
          onDayClick={this.handleOnDayClickUpdateSelectedValue}
          onDayTabPress={onDayTabPress}
          onKeyPressFocusDay={this.handleKeyboardNavigationToFocusNextDay}
          selectedValue={selectedValue}
          tabbableDay={tabbableDay}
          today={today}
          />
      );
    }

    const shouldShowNextMonthButton = this.shouldShowNextMonthButton({
      notInTheFuture,
      today,
      currentMonth,
      currentYear
    });

    const mainContainerStyles = classNames(
      styles.calendarContainer,
      layoutStyles.answers,
      containerStyles,
      'calendarDatePicker'
    );
    return (
      <table role="application" cellSpacing="0" cellPadding="0" className={mainContainerStyles}>
        <CalendarTitle
          calendarTitle={calendarTitle}
          prevMonthAriaLabel={prevMonthAriaLabel}
          nextMonthAriaLabel={nextMonthAriaLabel}
          onClickNextMonth={this.handleChangeCalendarToNextMonth}
          onClickPrevMonth={this.handleChangeCalendarToPreviousMonth}
          shouldShowNextMonthButton={shouldShowNextMonthButton}
          />
        <WeekdayHeaders weekdayHeaders={allWeekDayTitles}/>
        {weeks}
      </table>
    );
  }
}
