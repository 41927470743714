import { NODE_TYPES } from '../../constants';
import evaluatorRegistry from '../registry';
import countEvaluator, { COUNT_OPERATIONS } from '../count';

function andEvaluator(node, values) {
  const countConditionNode = {
    ...node,
    op: COUNT_OPERATIONS.EQUAL,
    compare_to: node.children.length
  };

  return countEvaluator(countConditionNode, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.AND, andEvaluator);
export default andEvaluator;
