import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Question from '../../commons/Question';
import noop from 'lodash/noop';
import validationStyles from '../../commons/ValidationMessage/validation.scss';
import Group from './Group';
import styles from './longform.scss';
import layoutStyles from '../../css/layout.scss';
import { isAdvancedAccessibilityEnabled, isWCAG21Enabled } from '../../services/featureFlags';

const {
  bool, instanceOf, string, number, arrayOf
} = PropTypes;

class Longform extends PureComponent {
  static propTypes = {
    id: number, // TODO convert to string in id assignment (PageBody.jsx)
    ariaLabel: string,
    caption: string,
    hasValidationFailure: bool,
    hasValidationFailureForEmpty: bool,
    isHorizontal: bool,
    isMultiSelect: bool,
    isMultiSelectWithManyOptions: bool,
    isRequired: bool,
    isSingleSelect: bool,
    isSubComponent: bool,
    options: instanceOf(Set),
    selectAllThatApplyMessage: string,
    qFields: arrayOf(string),
    validationMessages: arrayOf(string)
  };

  static defaultProps = {
    id: -1,
    caption: '',
    hasValidationFailure: false,
    hasValidationFailureForEmpty: false,
    isHorizontal: false,
    isMultiSelect: false,
    isMultiSelectWithManyOptions: false,
    isRequired: false,
    isSingleSelect: false,
    isSubComponent: false,
    options: new Set(),
    selectAllThatApplyMessage: 'Select all that apply',
    qFields: [],
    validationMessages: []
  };

  constructor(props, context) {
    super(props, context);
    this.state = { showMultiSelectMessage: false };
    this.handleShowingMultiSelectMessage = !isWCAG21Enabled() ? this.handleSelectAllMessageVisibility.bind(this, true) : noop;
    this.handleHidingMultiSelectMessage = !isWCAG21Enabled() ? this.handleSelectAllMessageVisibility.bind(this, false) : noop;

    if (!props.isMultiSelect) this.handleShowingMultiSelectMessage = null;
  }

  getSelectAllStyles() {
    return classNames(
      styles.multiSelectMessage,
      {
        [styles.multiSelectMessage_isShown]:  !isWCAG21Enabled() ?
          this.state.showMultiSelectMessage :
          this.props.isMultiSelect
      },
      validationStyles.validationBlock,
      // Hide the 'Select all' message using display none to avoid changing the html structure
      // For more info check https://jira.medallia.com/browse/OA-19451
      styles.multiSelectMessageHidden
    );
  }

  handleSelectAllMessageVisibility(shouldShow) {
    this.setState({ showMultiSelectMessage: shouldShow });
  }

  render() {
    const {
      ariaLabel,
      caption,
      hasValidationFailure,
      hasValidationFailureForEmpty,
      isMultiSelect,
      isRequired,
      isSingleSelect,
      options,
      selectAllThatApplyMessage,
      isMultiSelectWithManyOptions,
      id,
      qFields,
      isHorizontal,
      isSubComponent,
      validationMessages
    } = this.props;

    const longFormContainerStyles = classNames(
      styles.longformContainer,
      'questionBlock',
      {
        singleSelectQuestion: isSingleSelect,
        multiSelectQuestion: isMultiSelect
      },
      // let's support only single select for now
      isSingleSelect ? qFields.map(qField => `container_${qField}`) : '',
    );

    const containerStyles = classNames(`${layoutStyles.answers}`.trim(), { [styles.group]: !isSubComponent });
    const captionId = (id > 0) ? `${id}` : null;
    const ariaRequired = isAdvancedAccessibilityEnabled() && isRequired ? { 'aria-required': true } : {};
    const role = isAdvancedAccessibilityEnabled() ? {
      role: isMultiSelect ? 'group' : 'radiogroup'
    } : {};

    return (
      <div className={longFormContainerStyles} onBlur={this.handleHidingMultiSelectMessage}>
        <fieldset {...ariaRequired} {...role}>
          {caption &&
          <Question
            captionId={captionId}
            ariaLabel={ariaLabel}
            caption={caption}
            isRequired={isRequired}
            hasValidationFailure={hasValidationFailure}
            hasValidationFailureForEmpty={hasValidationFailureForEmpty}
            validationMessages={validationMessages}
          >
            {
              isMultiSelectWithManyOptions &&
              <span className={this.getSelectAllStyles()}>
                <span className={validationStyles.caption}>{selectAllThatApplyMessage}</span>
              </span>
            }
          </Question>}
          <div className={isHorizontal ? styles.groupContainerHorizontal : styles.groupContainer}>
            {[...options].map((optionId, index) => (
              <Group
                id={optionId}
                key={index}
                containerStyles={containerStyles}
                isMultiSelectWithOneOption={isMultiSelect && !isMultiSelectWithManyOptions}
                onFocus={this.handleShowingMultiSelectMessage}
                describedById={captionId}
                isSubComponent={isSubComponent}
                isHorizontal={isHorizontal}
              />
            ))}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default Longform;
