import PropTypes from 'prop-types';
import React from 'react';
import Row from '../../../commons/Table/Row';
import Anchor from '../../RatingGrid/Anchor';
import withCondition from '../../../commons/withCondition';
import "../../RatingGrid/AnchorBar/anchorBar.global.scss";

const {
  shape, arrayOf, string, number
} = PropTypes;

export const ConditionedAnchor = withCondition(Anchor);

export default function AnchorBar(props) {
  const { anchors, questionId } = props;

  const anchorElements = anchors.map(({ caption, columnId }) =>
    <ConditionedAnchor key={columnId} caption={caption} id={columnId} questionId={questionId} isOnFirstRow />);

  return (
    <Row>
      {anchorElements}
    </Row>
  );
}

AnchorBar.propTypes = {
  anchors: arrayOf(shape({
    caption: string.isRequired,
    columnId: number.isRequired
  }))
};

AnchorBar.defaultProps = {
  anchors: [],
  anchorStyles: ''
};
