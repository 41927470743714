/* eslint-disable camelcase */
import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import Option from '../Option';
import styles from './group.scss';
import {
  isAdvancedAccessibilityEnabled,
  isWCAG21_2_Enabled,
  isWCAG21_3_Enabled_FF
} from '../../../services/featureFlags';

function Group(props) {
  const {
    id,
    containerStyles,
    onFocus,
    isBazaarVoiceTerms,
    options,
    hasSiblings,
    describedById,
    isRequired,
    isSubComponent,
    isHorizontal
  } = props;

  const mainGroupStyles = classNames(
    `${styles.group}`.trim(),
    {
      [`${styles.isBazaarVoiceTerms}`]: isBazaarVoiceTerms
    }
  );

  // As long as the subcomponents have no groups, the name of the only group is forced to not be shown
  const caption = !isSubComponent && props.caption;

  const optionsShouldShowDescribedBy = Boolean(((hasSiblings && caption) || describedById) &&
    !isWCAG21_3_Enabled_FF());
  const ariaRequired = isRequired && isAdvancedAccessibilityEnabled();


  let containerClasses;
  let list;

  if (isWCAG21_2_Enabled() || isSubComponent) {
    containerClasses = classNames(containerStyles, mainGroupStyles, {
      [styles.tableRow]: isHorizontal
    });

    // as per COCO-3817, we're removing ul/li elements
    list = [...options].map((optionId, idx) => (
      <Option
        id={optionId}
        key={idx}
        containerStyles={styles.optionContainer}
        isBazaarVoiceTerms={isBazaarVoiceTerms}
        onFocus={onFocus}
        shouldShowDescribedBy={optionsShouldShowDescribedBy}
        describedById={describedById}
        ariaRequired={ariaRequired}
        isHorizontal={isHorizontal}
        isSubComponent={isSubComponent}
      />
    ));
  } else {
    containerClasses = containerStyles;

    list = (
      <ul className={mainGroupStyles}>
        {[...options].map((optionId, idx) => (
          <li key={idx} className={styles.optionContainer}>
            <Option
              id={optionId}
              isBazaarVoiceTerms={isBazaarVoiceTerms}
              onFocus={onFocus}
              shouldShowDescribedBy={optionsShouldShowDescribedBy}
              describedById={describedById}
              ariaRequired={ariaRequired}
            />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className={containerClasses}>
      {caption && (
        <div id={`${id}`} className={styles.groupCaption}>{caption}</div>
      )}
      {list}
    </div>
  );
}

const {
  func,
  instanceOf,
  string,
  bool,
  oneOfType,
  number
} = PropTypes;

Group.propTypes = {
  id: oneOfType([string, number]),
  caption: string,
  containerStyles: string,
  onFocus: func,
  options: instanceOf(Set),
  hasSiblings: bool,
  isMultiSelectWithOneOption: bool,
  describedById: string,
  isHorizontal: bool,
  isRequired: bool,
  isSubComponent: bool
};

Group.defaultProps = {
  caption: '',
  isHorizontal: false,
  isSubComponent: false
};

export default Group;
