/* global document */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import {isEnterKey} from '../../utils/detectKey';
import classNames from 'classnames';
import DateEntryValidator from '../../models/dateEntryValidator';
import CalendarDatePicker from '../../commons/CalendarDatePicker';
import Question from '../../commons/Question';
import Input from '../../commons/Input';
import Icon, {ICON_TYPES} from '../../commons/Icon';
import layoutStyles from '../../css/layout.scss';
import styles from './calendar.scss';
import { testName } from "../../utils/testUtils";

const calendarStyles = `${styles.calendarContainer} questionBlock calendarQuestion`;
const calendarTextfieldStyles = `${styles.calenderTextfieldInput} ${layoutStyles.answer} calendarTextfieldInput`;
const contentContainerStyles = `${styles.contentContainer} ${layoutStyles.answers}`;

const {bool, func, number, object, shape, string} = PropTypes;

class Calendar extends PureComponent {
  static propTypes = {
    id: number,
    answerQuestion: func,
    ariaLabelForCalendarButton: string,
    ariaLabelForSelected: string,
    calendarFullDateFormat: string,
    calendarTranslations: object,
    calendarTitleFormat: string,
    calendarDateFormat: string,
    caption: string,
    dateFormatPlaceholderLabel: string,
    hasValidationFailure: bool,
    hasValidationFailureForEmpty: bool,
    htmlInputId: string,
    htmlInputValue: string,
    initSelectedDay: number,
    initSelectedValue: string,
    inputAriaLabel: string,
    isRequired: bool,
    inverseLeftRightArrowMovement: bool,
    nextMonthAriaLabel: string,
    notInTheFuture: bool,
    prevMonthAriaLabel: string,
    validationMessage: string,
    visibleCalendar: shape({
      month: number,
      year: number
    })
  };

  constructor(props) {
    super(props);
    this.state = {calendarIsVisible: false, visibleCalendar: props.visibleCalendar};
    this.setRefToCalendarDatePicker = this.setRefToCalendarDatePicker.bind(this);
    this.setRefToInput = this.setRefToInput.bind(this);
    this.handleInputValidation = this.handleInputValidation.bind(this);
    this.handleOnBlurInputValueValidation = this.handleOnBlurInputValueValidation.bind(this);
    this.handleOnDayClick = this.handleOnDayClick.bind(this);
    this.handleCalendarToggling = this.handleCalendarToggling.bind(this);
    this.handleClosingCalendar = this.handleClosingCalendar.bind(this);
    this.handleTextfieldOnChangeRemoveCalendarSelection = this.handleTextfieldOnChangeRemoveCalendarSelection.bind(this);
    this.forceCalendarDatePickerToFocusOnDay = this.forceCalendarDatePickerToFocusOnDay.bind(this);
    this._dateEntryValidator = new DateEntryValidator({dateFormat: props.calendarDateFormat});
    this._today = new Date();

    document.addEventListener('click', this.handleClosingCalendar);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClosingCalendar);
  }

  setRefToCalendarDatePicker(calendarDatePickerComponent) {
    this._calendarDatePickerComponent = calendarDatePickerComponent;
  }

  setRefToInput(inputComponent) {
    this._inputComponent = inputComponent;
  }

  forceCalendarDatePickerToFocusOnDay() {
    const {calendarIsVisible} = this.state;
    if (!calendarIsVisible) return;
    if (this._calendarDatePickerComponent) this._calendarDatePickerComponent.focusTabbableDay();
  }

  handleCalendarToggling(e) {
    const calendarIsVisible = !this.state.calendarIsVisible;

    if (e.type === 'click') {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      this.setState({calendarIsVisible});
    }

    if (isEnterKey(e)) {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      this.setState({calendarIsVisible}, this.forceCalendarDatePickerToFocusOnDay);
    }
  }

  handleClosingCalendar() {
    if (!this.state.calendarIsVisible) return;
    this.setState({calendarIsVisible: false});
  }

  handleOnDayClick(value) {
    const {
      id,
      htmlInputId,
      answerQuestion
    } = this.props;
    if (value === null || value === undefined) value = '';
    answerQuestion({htmlInputId, questionId: id, value});
    const waitUntilAnimationIsDoneToCloseCalendar = () => {
      this.setState({calendarIsVisible: false}, () => {
        if (this._inputComponent) this._inputComponent.focus();
      });
    };
    setTimeout(waitUntilAnimationIsDoneToCloseCalendar, 200);
  }

  handleOnBlurInputValueValidation(e) {
    const {
      id,
      htmlInputId,
      answerQuestion,
      calendarDateFormat,
      notInTheFuture
    } = this.props;

    const date = e.target.value || '';
    const dateIsValidLength = date.length === calendarDateFormat.length;

    if (dateIsValidLength) {
      const extractedDate = this._dateEntryValidator.extractMonthDayYear(date);
      if (!extractedDate.month || !extractedDate.day || !extractedDate.year) {
        return answerQuestion({htmlInputId, questionId: id, value: ''});
      }

      const actualDate = new Date(extractedDate.year, extractedDate.month - 1, extractedDate.day);

      if (notInTheFuture && actualDate > this._today) {
        return answerQuestion({htmlInputId, questionId: id, value: ''});
      }

      if (this._calendarDatePickerComponent) {
        this._calendarDatePickerComponent.updateSelectedValue(date, actualDate);
      }
      answerQuestion({htmlInputId, questionId: id, value: date});
    } else {
      answerQuestion({htmlInputId, questionId: id, value: ''});
    }
  }

  handleInputValidation(e, value) {
    return this._dateEntryValidator.transformToValidValue(value);
  }

  handleTextfieldOnChangeRemoveCalendarSelection(e) {
    const {
      id,
      htmlInputId,
      answerQuestion
    } = this.props;
    const date = e.target.value || '';
    answerQuestion({ 
      htmlInputId, 
      questionId: id, 
      value: this.handleInputValidation(undefined, date) 
    });

    if (date !== '') return;
    this._calendarDatePickerComponent.removeSelectedValue();
  }

  render() {
    const {
      id,
      ariaLabelForCalendarButton,
      ariaLabelForSelected,
      calendarFullDateFormat,
      calendarTranslations,
      calendarDateFormat,
      calendarTitleFormat,
      caption,
      dateFormatPlaceholderLabel,
      hasValidationFailure,
      hasValidationFailureForEmpty,
      htmlInputId,
      htmlInputValue,
      initSelectedDay,
      initSelectedValue,
      inputAriaLabel,
      isRequired,
      inverseLeftRightArrowMovement,
      nextMonthAriaLabel,
      notInTheFuture,
      prevMonthAriaLabel,
      validationMessage
    } = this.props;

    const popoverCalendarStyles = classNames(styles.calendarDatePickerContainer, {
      [styles.calendarDatePickerContainer_isVisible]: this.state.calendarIsVisible
    });

    const calendarIconStyles = classNames(styles.calendarIconContainer, 'calendarDatePickerButton', {
      [styles.calendarIconContainer_isActive]: this.state.calendarIsVisible,
      [styles.calendarIconContainer_inActive]: !this.state.calendarIsVisible,
      [`${layoutStyles.answer}`]: !this.state.calendarIsVisible
    });

    return (
      <fieldset className={calendarStyles}>
        <Question
          ariaId={`${id}`}
          caption={caption}
          isRequired={isRequired}
          hasValidationFailure={hasValidationFailure}
          hasValidationFailureForEmpty={hasValidationFailureForEmpty}
          validationMessage={validationMessage}
          />
        <div className={contentContainerStyles}>
          <Input
            isTextfield
            ref={this.setRefToInput}
            ariaDescribedBy={inputAriaLabel}
            containerStyles={calendarTextfieldStyles}
            formKey={htmlInputId}
            formValue={htmlInputValue}
            maxLength={calendarDateFormat.length}
            onBlur={this.handleOnBlurInputValueValidation}
            onChange={this.handleTextfieldOnChangeRemoveCalendarSelection}
            onChangeHandleUpdateValue={this.handleInputValidation}
            onFocus={this.handleClosingCalendar}
            placeholder={dateFormatPlaceholderLabel}
            />
          <button
            aria-expanded={this.state.calendarIsVisible}
            aria-label={ariaLabelForCalendarButton}
            className={calendarIconStyles}
            onClick={this.handleCalendarToggling}
            onKeyDown={this.handleCalendarToggling}
            tabIndex="0"
            { ...testName('date-picker', 'button')}
            >
            &nbsp;
            <Icon containerStyles={styles.calendarIcon} type={ICON_TYPES.CALENDAR}/>
          </button>
          <CalendarDatePicker
            ref={this.setRefToCalendarDatePicker}
            ariaLabelForSelected={ariaLabelForSelected}
            calendarFullDateFormat={calendarFullDateFormat}
            calendarTranslations={calendarTranslations}
            calendarDateFormat={calendarDateFormat}
            calendarTitleFormat={calendarTitleFormat}
            containerStyles={popoverCalendarStyles}
            initialState={this.state.calendarState}
            initSelectedDay={initSelectedDay}
            initSelectedValue={initSelectedValue}
            inverseLeftRightArrowMovement={inverseLeftRightArrowMovement}
            nextMonthAriaLabel={nextMonthAriaLabel}
            notInTheFuture={notInTheFuture}
            onDayClick={this.handleOnDayClick}
            onDayTabPress={this.handleClosingCalendar}
            prevMonthAriaLabel={prevMonthAriaLabel}
            visibleCalendar={this.state.visibleCalendar}
            />
        </div>
      </fieldset>
    );
  }
}

export default Calendar;
