import { NODE_TYPES } from '../../constants';
import evaluatorRegistry from '../registry';
import countEvaluator, { COUNT_OPERATIONS } from '../count';

function orEvaluator(node, values) {
  const countConditionNode = {
    ...node,
    op: COUNT_OPERATIONS.GREATER_OR_EQUAL,
    compare_to: 1
  };

  return countEvaluator(countConditionNode, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.OR, orEvaluator);
export default orEvaluator;
