import React from 'react';
import styles from './IconClose.scss';

const IconClose = () => (
  <svg viewBox="0 0 16 16" className={styles.closeButton}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" /><path d="M3.5 3.5l9 9m0-9l-9 9" stroke="#778FAE" strokeWidth="1.5" strokeLinecap="square" />
    </g>
  </svg>
);

export default IconClose;
