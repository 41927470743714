export const SET_COMPONENT_DATA = 'SET_COMPONENT_DATA';

const setComponentData = (componentId, data) =>
  (dispatch) => {
    dispatch({
      type: SET_COMPONENT_DATA,
      payload: {
        componentId,
        data
      }
    });
  };

export default setComponentData;
