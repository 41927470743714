import convertNddToLongform from '../convertNddToLongform';
import { formKeyToQField }  from '../formKeyAndQFieldTransformations';
import {
  MATRIX_BLOCKTYPE,
  NESTED_DROPDOWN_BLOCKTYPE,
  TEXTAREA_BLOCKTYPE
} from './constants';

let newId = 0;

const assignId = (object, key = 'id') => ({
  ...object,
  [key]: newId++
});

export const resetId = () => {
  newId = 0;
};

export const normalizeTextarea = envelope => ({
  ...envelope,
  fieldName: envelope.fieldName || formKeyToQField(envelope.formkey)
});

export const normalizeNdd = (envelope) => {
  if (envelope.showFullNDD) {
    return envelope;
  }
  return convertNddToLongform(envelope);
};

export const normalizeMatrix = (envelope) => {
  const { rows, columns } = envelope;
  const normalizedColumns = columns.map(column => assignId(column));
  const normalizedRows = rows.map(row => assignId(row)).map((row) => {
    const normalizedCells = row.cells.map((cell, columnIndex) => {
      const normalizedCell = {
        ...cell,
        rowId: row.id,
        columnId: normalizedColumns[columnIndex].id,
        piece: assignId(cell.piece, 'blockId')
      };
      return normalizedCell;
    });
    return {
      ...row,
      cells: normalizedCells
    };
  });

  return ({
    ...envelope,
    columns: normalizedColumns,
    rows: normalizedRows
  });
};

export const normalizersMap = {
  [TEXTAREA_BLOCKTYPE]: normalizeTextarea,
  [NESTED_DROPDOWN_BLOCKTYPE]: normalizeNdd,
  [MATRIX_BLOCKTYPE]: normalizeMatrix
};

const normalizeEnvelope = (envelope) => {
  const envelopeWithId = assignId(envelope);
  if (envelopeWithId.blockType && normalizersMap[envelopeWithId.blockType]) {
    const normalize = normalizersMap[envelopeWithId.blockType];
    return normalize(envelopeWithId);
  }
  return envelopeWithId;
};

export default normalizeEnvelope;
