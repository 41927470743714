import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LanguageSelector from './../LanguageSelector';
import ProgressBar from '../ProgressBar';
import styles from '../../css/layout.scss';
import { isAdvancedAccessibilityEnabled } from '../../services/featureFlags';

export default class PageHead extends Component {

  static propTypes = {
    pagerPage: PropTypes.number,
    pagerPercent: PropTypes.number,
    decorativeLogo: PropTypes.string,
    decorativeLogoAlt: PropTypes.string,
    splashLogo: PropTypes.string,
    splashLogoAlt: PropTypes.string,
    pageLogo: PropTypes.string,
    pageLogoAlt: PropTypes.string,
    languages: PropTypes.array,
    surveyLanguage: PropTypes.string,
    surveyTitle: PropTypes.string,
    useNativeDropdown: PropTypes.bool
  };

  static defaultProps = {
    pagerPage: 0,
    pagerPercent: 0,
    languages: []
  };

  static contextTypes = {
    translations: PropTypes.object
  };

  render() {
    const {
      decorativeLogo,
      decorativeLogoAlt,
      languages,
      pageLogo,
      pageLogoAlt,
      pagerPage,
      isFirstPage,
      pagerPercent,
      splashLogo,
      splashLogoAlt,
      surveyLanguage,
      surveyTitle,
      useNativeDropdown
    } = this.props;

    const isNotAPage = pagerPage === -1;
    const translations = this.context.translations;

    let urlForLogo;
    let altForLogo;
    if (isFirstPage) {
      urlForLogo = splashLogo || decorativeLogo || pageLogo;
      altForLogo = splashLogoAlt || decorativeLogoAlt || pageLogoAlt || '';
    } else {
      urlForLogo = pageLogo;
      altForLogo = pageLogoAlt || '';
    }

    // EEQ-76790 workaround.  Currently, Admin Suite does not allow setting of
    // a logo's alt text to blank.  Also, Admin Suite does not allow alt text
    // to be translated.  As such, this causes problems for things like
    // "Metro by T-Mobile", which needs to be translated to "Metro por
    // T-Mobile".  To get around this translations limitation, the img's alt
    // text would need to be set to blank so as to declare the logo as
    // "decorative" under WCAG guidelines; however, this circles us back around
    // to the first point.
    //
    // While a more proper fix is required in Admin Suite, in the meantime,
    // we will use a special setting of "===WCAG-DECORATIVE-IMAGE===" to force
    // the value to be blank.
    if (altForLogo == '===WCAG-DECORATIVE-IMAGE===') {
      altForLogo = '';
    }

    return (
      <header>
        {!(isFirstPage || isNotAPage) && (
          <ProgressBar pagerPercent={pagerPercent}/>
        )}
        <a className={styles.skipNav} href="#content">{translations.SKIP_TO_CONTENT}</a>
        <div className={styles.layoutContainer}>
          <div className={styles.mainHeader + ' ' + styles.clearfix}>
            <div className={styles.mainHeaderLeft}>
              { isAdvancedAccessibilityEnabled() ?
                <h1 className={styles.invisibleTitle}>{surveyTitle}</h1>
                : <h1 className={styles.invisibleTitle} tabIndex="0" aria-label={surveyTitle} />
              }
              {urlForLogo && (
                <img id="pageLogo" className={styles.logo} src={urlForLogo} alt={altForLogo}/>
              )}
            </div>
            <div className={styles.mainHeaderRight}>
              {isFirstPage && (
                <LanguageSelector
                  languages={languages}
                  surveyLanguage={surveyLanguage}
                  label={translations.CHOOSE_LANGUAGE}
                  useNativeDropdown={useNativeDropdown}
                />
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}
