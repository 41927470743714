import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from '../../assets/fonts/medallicons.scss';

export const ICON_TYPES = {
  ARROW_DROPDOWN: '',
  ARROW_LEFT: '',
  ARROW_RIGHT: '',
  ARROW_UP: '',
  CALENDAR: '',
  CHECKBOX: '',
  CLOSE: '',
  FILE_AUDIO: '',
  FILE_EXCEL: '',
  FILE_GENERIC: '',
  FILE_IMAGE: '',
  FILE_PDF: '',
  FILE_POWER_POINT: '',
  FILE_VIDEO: '',
  FILE_WORD: '',
  FILE_ZIP: '',
  INFORMATION: '',
  POWERED_BY_MEDALLIA: '',
  STAR: '',
  TRASH_BIN: '',
};

export default function Icon(props) {
  if (!props.shouldRender) return null;
  const {type, ariaHidden, containerStyles, ariaLabel} = props;
  const iconStyles = classNames(styles.medallicons, containerStyles);

  if (ariaHidden && ariaLabel) {
    return (
      <span>
        <span className={styles.medalliconLabel}>{ariaLabel}</span>
        <i aria-hidden={ariaHidden} className={iconStyles}>{type}</i>
      </span>
    );
  }
  return <i aria-hidden={ariaHidden} className={iconStyles}>{type}</i>;
}

const {bool, string} = PropTypes;

Icon.propTypes = {
  type: string,
  ariaHidden: bool,
  containerStyles: string,
  shouldRender: bool,
  ariaLabel: string
};

Icon.defaultProps = {
  ariaHidden: true,
  shouldRender: true
};
