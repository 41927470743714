/* global document */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Icon, {ICON_TYPES} from '../../commons/Icon';
import styles from './modal.scss';
import classNames from 'classnames';
import scrollTop, { setIOSScrollTop, getIOSScrollTop } from '../../utils/scrollTop';
import { isSafariMobile } from '../../utils/browserAgent';
import { isIosDropdownScrollToTopFixEnabled } from '../../services/featureFlags';

const {bool, func, node, number, string} = PropTypes;

export default class Modal extends Component {
  static propTypes = {
    cancelButtonLabel: string,
    children: node,
    label: string,
    modalBackdropStyles: string,
    modalBodyStyles: string,
    modalContainerStyles: string,
    modalHeaderStyles: string,
    onClickHide: func,
    scrollTop: number,
    shouldRender: bool
  };

  static defaultProps = {
    scrollTop: 0,
    shouldRender: true
  };

  constructor(props) {
    super(props);

    this._$surveyDOM = document.getElementById('survey');
    this.state = {
      children: props.children
    };

    this.handleOnClickHide = this.handleOnClickHide.bind(this);
    this.setRefCancelButton = this.setRefCancelButton.bind(this);
  }

  componentWillMount() {
    const container = document.createElement('div');
    document.body.appendChild(container);
    if (isSafariMobile() && isIosDropdownScrollToTopFixEnabled()) {
      this.safariScrollTop = getIOSScrollTop();
    }
    this._$container = container;
  }

  componentDidMount() {
    const bodyClassNames = document.body.className.split(' ');
    const idxOfModalOpen = bodyClassNames.indexOf('modal_modalOpen');
    if (idxOfModalOpen !== -1) return;

    bodyClassNames.push('modal_modalOpen');
    document.body.className = bodyClassNames.join(' ');
    document.body.style.top = '-' + this.props.scrollTop + 'px';
    if (this._$surveyDOM) this._$surveyDOM.setAttribute('aria-hidden', 'true');

    this.renderDOMElements();
    if (this._$cancelButton) this._$cancelButton.focus();
  }

  componentWillUnmount() {
    const bodyClassNames = document.body.className.split(' ');
    const idxOfModalOpen = bodyClassNames.indexOf('modal_modalOpen');
    if (idxOfModalOpen === -1) return;

    bodyClassNames.splice(idxOfModalOpen, 1);
    document.body.className = bodyClassNames.join(' ');
    document.body.style.top = '';
    scrollTop(this.props.scrollTop);
    if (isSafariMobile() &&
      (this.safariScrollTop || this.safariScrollTop === 0) &&
      isIosDropdownScrollToTopFixEnabled()) {
      setIOSScrollTop(this.safariScrollTop)
    }
    if (this._$surveyDOM) this._$surveyDOM.removeAttribute('aria-hidden');
    this.removeDisplacedModalFromBody();
  }

  setRefCancelButton(cancelButton) {
    this._$cancelButton = cancelButton;
  }

  handleOnClickHide(e) {
    e.preventDefault();
    if (this.props.onClickHide) this.props.onClickHide();
  }

  removeDisplacedModalFromBody() {
    ReactDOM.unmountComponentAtNode(this._$container);
    this._$container.parentNode.removeChild(this._$container);
  }

  componentWillReceiveProps(newProps) {
    this.setState({children: newProps.children}, ()=>{
      this.renderDOMElements();
    });
  }

  renderDOMElements() {
    if (!this.props.shouldRender) return null;
    const {
      cancelButtonLabel,
      label,
      modalBackdropStyles,
      modalBodyStyles,
      modalContainerStyles,
      modalHeaderStyles
    } = this.props;

    const containerStyles = classNames(styles.modalContainer, modalContainerStyles);
    const backdropStyles = classNames(styles.modalBackdrop, modalBackdropStyles);
    const bodyStyles = classNames(styles.modalBody, modalBodyStyles);
    const headerStyles = classNames(styles.modalHeader, modalHeaderStyles);

    const modal = (
      <div role="dialog" className={backdropStyles}>
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span className={styles.modalLabel} aria-hidden="true" dangerouslySetInnerHTML={{__html: label}}/>
            <a
              href=""
              aria-label={cancelButtonLabel}
              className={styles.closeIconContainer}
              onClick={this.handleOnClickHide}
              ref={this.setRefCancelButton}
              role="button"
              tabIndex="0"
              >
              <Icon containerStyles={styles.closeIcon} type={ICON_TYPES.CLOSE}/>
            </a>
          </div>
          <div className={bodyStyles}>
            {this.state.children}
          </div>
        </div>
      </div>
    );

    ReactDOM.render(modal, this._$container);
  }

  render() {
    return false;
  }
}
