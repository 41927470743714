import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './indicatorStar.scss';
import '../../assets/fonts/star-scales.scss';

export default function IndicatorStar(props) {
  const {
    containerStyles,
    isSelected,
  } = props;

  const mainContainerStyles = classNames(
    styles.indicatorStarContainer,
    containerStyles,
    'indicatorStar',
  );

  const starStyles = classNames(styles.star, { [styles.star_isSelected]: isSelected });
  const labelStyles = classNames(styles.label, { [styles.label_isSelected]: isSelected });

  return (
    <div aria-hidden className={mainContainerStyles}>
      <div className={starStyles} />
      <span className={labelStyles}>
        {"\uE800"}
      </span>
    </div>
  );
}

const { bool, string } = PropTypes;

IndicatorStar.propTypes = {
  containerStyles: string,
  isSelected: bool
};
