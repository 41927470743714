import { connect } from 'react-redux';
import { BAZAAR_VOICE } from '../../../redux/reducers/pageSettings/constants/pageTypes';
import Group from './group';

function mapStateToProps(state, ownProps) {
  const { options, pageSettings } = state;
  const { isRequired } = ownProps;
  const option = options && options[ownProps.id] ? options[ownProps.id] : {};
  const hasSiblings = Object.keys(options).filter((key) => {
    const { question, hasChildren, options: children } = options[key];
    return key !== ownProps.id && question === option.question && hasChildren && children.size;
  }).length > 0;
  const isBazaarVoiceTerms = pageSettings && pageSettings.type === BAZAAR_VOICE &&
    option.options &&
    option.options.size === 1;
  return {
    ...option,
    hasSiblings,
    isRequired,
    isBazaarVoiceTerms
  };
}

export { mapStateToProps };
export default connect(mapStateToProps)(Group);
