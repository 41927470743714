export function getOptionDescribedBy(props) {
  const {
    mainCaption,
    id,
    questionId,
    caption
  } = props;
  /** The grid has not a main question. I.E. the caption is at
   * Left of the selection bar */
  if (!mainCaption && caption) {
    return id.toString();
  }

  /** The grid question has been moved to the top
   * because not main question has been defined. */
  if (!caption && mainCaption) {
    return questionId.toString();
  }

  /** The grid has main and row questions.
   * This is the most common case */
  if (mainCaption && caption) {
    return `${questionId} ${id}`;
  }

  return null;
}

export default getOptionDescribedBy;
