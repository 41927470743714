import { NODE_TYPES } from '../../constants';
import fieldGenerator from '../field';
import constantGenerator from '../constant';

function eqGenerator(field, value) {
  return {
    type: NODE_TYPES.EQ,
    left: fieldGenerator(field),
    right: constantGenerator(value)
  };
}

function eqConditionStringGenerator(field, value) {
  return `${field} = ${value}`;
}

export { eqConditionStringGenerator };
export default eqGenerator;
