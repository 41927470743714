import { connect } from 'react-redux';
import RowWrapper from './RowWrapper';
import withCondition from '../../../commons/withCondition';

export const mapStateToProps = (state) => {
  const mediaQuery = state.mediaQuery || {};
  const isMobileMedia = !mediaQuery.isDesktopMedia && mediaQuery.isMobileMedia;
  return {
    isMobileMedia
  };
};

const ConditionedRowWrapper = withCondition(RowWrapper);

export default connect(mapStateToProps)(ConditionedRowWrapper);

