import forOwn from 'lodash/forOwn';

const shouldFreeze = nestedValue => (
  (nestedValue !== null && (typeof nestedValue === 'object')) ||
  typeof nestedValue === 'function') && !Object.isFrozen(nestedValue);

export const deepFreeze = (item) => {
  const itemCopy = Array.isArray(item) ? [...item] : { ...item };
  if (itemCopy === undefined) {
    return itemCopy;
  }

  forOwn(itemCopy, (value, prop) => {
    if (shouldFreeze(value)) {
      itemCopy[prop] = deepFreeze(itemCopy[prop]);
    }
  });
  Object.freeze(itemCopy);
  return itemCopy;
};

export default deepFreeze;

