import { compose } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import Matrix from './Matrix';
import withCondition from '../../commons/withCondition';
import { withData } from '../../services/surveyDataProvider';
import IPCService from '../../services/inPageConditioning';


/** Set the grid components anchors for each cell. When two vertically
 * adjacent cells have the same anchors they are only show at the top
 * of the first cell. It also allows to center the left caption (row caption)
 * vertically with the grids and other components
 */

export const getInternalAnchors = (rows, { questions = [], options = [] }) => {
  const matrixAnchors = {};
  rows.forEach(row => row.cells.reduce((acc, { piece, columnId }) => {
    if (!acc[columnId]) acc[columnId] = [];
    const { blockId } = piece;
    const question = questions[blockId] || {};
    const mainOptionId = question.options && Array.from(question.options)[0];
    const questionOptions = mainOptionId ? Array.from(options[mainOptionId].options) : [];
    const anchors = Array.from(question.anchors ||
        questionOptions.map(option => options[option].caption));
    const isFirstOfKind = !acc[columnId]
      .some(data => data.shouldShow && isEqual(data.anchors, anchors));
    const shouldShow = isFirstOfKind && IPCService.shouldShowComponent(columnId);
    const subComponentCellsAmount = questionOptions.length;
    const shouldJustify = anchors.length === 2 ||
        anchors.length === 3 ||
        anchors.length !== subComponentCellsAmount;
    const naOptionCaption = mainOptionId ? options[mainOptionId].naOptionCaption : '';

    acc[columnId].push({
      anchors, shouldShow, shouldJustify, naOptionCaption
    });
    return acc;
  }, matrixAnchors));
  return matrixAnchors;
};

export const normalizeErrors = (question) => {
  const errors = {
    hasValidationFailureForEmpty: null,
    hasValidationFailure: null
  };

  question.rows.forEach((row) => {
    const validationEmptyPerRow = row.cells.some(cell => Boolean(cell.piece.validationEmpty));
    if (validationEmptyPerRow) {
      errors.hasValidationFailureForEmpty = validationEmptyPerRow;
    }

    const validationFailurePerRow = row.cells.some(cell => Boolean(cell.piece.validationEmpty));
    if (validationFailurePerRow) {
      errors.hasValidationFailure = validationFailurePerRow;
    }
  });

  return errors;
};

// eslint-disable-next-line arrow-body-style
export const getRequiredValue = (cell) => {
  if (cell.piece.requiredField) {
    return true;
  }

  if (cell.piece.rows) {
    return cell.piece.rows[0].requiredField;
  }

  return false;
};

export const normalizeRequiredQuestion = (question) => {
  let isRequired = question.isRequired || !!question.requiredField;
  if (!isRequired) {
    question.rows.forEach((row) => {
      if (row.cells.some(getRequiredValue)) { isRequired = true; }
    });
  }
  return isRequired;
};

export const mapSurveyDataToProps = (surveyData, ownProps) => {
  const question = surveyData.blocks[ownProps.id];
  const isRequired = normalizeRequiredQuestion(question);

  const { hasValidationFailure, hasValidationFailureForEmpty }  = normalizeErrors(question);

  const {
    caption, rows
  } = question;

  return {
    caption,
    rows,
    hasValidationFailure,
    hasValidationFailureForEmpty,
    isRequired
  };
};

export const mapStateToProps = (state, { rows }) => {
  const conditionedRows = rows.map(row => ({
    ...row,
    isFirstRow: rows.find(({ id }) => IPCService.shouldShowComponent(id)) === row
  }));
  const mediaQuery = state.mediaQuery || {};
  const isMobileMedia = !mediaQuery.isDesktopMedia && mediaQuery.isMobileMedia;
  return {
    isMobileMedia,
    rows: conditionedRows,
    internalAnchors: getInternalAnchors(conditionedRows, state)
  };
};

const composedConnect = compose(
  withCondition,
  withData(mapSurveyDataToProps),
  connect(mapStateToProps),
);

export default composedConnect(Matrix);
