import get from 'lodash/get';
import evaluateNode from '../conditionEngine';

export default class IPCService {
  constructor() {
    this.store = null;
    this.FieldsService = null;

    this.evaluateCondition = this.evaluateCondition.bind(this);
    this.shouldShowComponent = this.shouldShowComponent.bind(this);
  }

  setStore(store) {
    this.store = store;
    return this;
  }

  setFieldsService(FieldsService) {
    this.FieldsService = FieldsService;
    return this;
  }

  evaluateCondition(conditionId) {
    const state = this.store.getState();
    const condition = state.conditions[conditionId];
    const {
      inPageFields,
      conditionAST,
      staticFields
    } = condition;

    const inPageFieldsWithValues = inPageFields.reduce((fields, field) => {
      // eslint-disable-next-line no-param-reassign
      fields[field] = this.FieldsService.getFieldValue(field);
      return fields;
    }, {});

    try {
      return evaluateNode(conditionAST, {
        ...inPageFieldsWithValues,
        ...staticFields
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return true;
    }
  }

  shouldShowComponent(componentId) {
    const state = this.store.getState();

    const component = get(state.questions, componentId, get(state.options, componentId, {}));

    const conditions = component.conditions || [];

    return conditions.every(this.evaluateCondition);
  }

  shouldShowAlternative(questionId, fieldName, alternativeId) {
    const state = this.store.getState();
    const field = get(state.fieldControlledBy, fieldName, []);
    const question = field.find(f => f.question === questionId) || {};
    const option = get(question.alternativesControlledBy, alternativeId);
    if (!option) return true;
    return this.shouldShowComponent(option);
  }
}
