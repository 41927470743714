import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

export function mergeFieldRelationships(objVal, srcVal) {
  if (isArray(objVal) && isArray(srcVal)) return objVal.concat(srcVal);
  return undefined;
}

export default function mergeCustomizerForAllAndCount(objVal, srcVal, key) {
  if (key === 'count') return objVal + srcVal;
  if (objVal instanceof Set) return new Set([...objVal, ...srcVal]);

  if (key === 'fieldControlledBy') {
    return mergeWith(objVal, srcVal, mergeFieldRelationships);
  }
  return undefined;
}

