/* eslint-disable no-param-reassign */

export const numberToPx = number => `${number}px`;

export const getTranslateFromCoordinates = (coordinates, listBoundingReact, draggedItemHeight) => {
  let { y } = coordinates || { y: 0 };

  const {
    left: minX,
    top: minY,
    bottom: maxY
  } = listBoundingReact || {};

  if (typeof minY === 'number' && y < minY) {
    y = minY;
  }

  if (typeof maxY === 'number' && y + draggedItemHeight > maxY) {
    y = maxY - draggedItemHeight;
  }

  return `translate(${numberToPx(minX)}, ${numberToPx(y)})`;
};

export const defaultReorderHandler = (list, originIndex, targetIndex) => {
  const element = list[originIndex];
  const movingUp = originIndex < targetIndex;

  const firstCut = Math.min(originIndex, targetIndex);
  const lastCut = Math.max(originIndex, targetIndex);
  const midOffset = movingUp ? 1 : 0;

  const part1 = list.slice(0, firstCut);
  const part2 = list.slice(firstCut + midOffset, lastCut + midOffset);
  const part3 = list.slice(lastCut + 1);

  return movingUp ?
    part1.concat(part2, [element], part3) :
    part1.concat([element], part2, part3);
};
