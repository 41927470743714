import React from 'react';

import SelectDropdown from './Dropdown.select';
import RegularDropdown from './Dropdown';

import { isSearchableDropdownEnabled } from '../../services/featureFlags';

function Dropdown(props) {
  const Component = isSearchableDropdownEnabled() ? SelectDropdown : RegularDropdown;
  return <Component {...props} />;
}

export default Dropdown;
