import { compose } from 'redux';
import { connect } from 'react-redux';
import SubComponentCell from './SubComponentCell';
import { withData } from '../../../services/surveyDataProvider';
import answerQuestion from '../actions';

export const mapSurveyDataToProps = (surveyData, { questionId, columnIndex }) => {
  const question = surveyData.blocks[questionId];
  const columnCaption = question.columns[columnIndex].caption;
  return {
    columnCaption
  };
};

const actions = { answerQuestion };

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(null, actions)
);

export default composedConnect(SubComponentCell);
