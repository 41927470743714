import PropTypes from 'prop-types';
import React from 'react';
import styles from './cookieBannerTitle.scss';
import { ARIA_TITLE_ID } from '../../constants';

const Title = ({ text }) => (
  <h1
    className={styles.title}
    id={ARIA_TITLE_ID}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

Title.propTypes = {
  text: PropTypes.string.isRequired
};

export default Title;
