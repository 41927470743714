/* global document */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import Icon, { ICON_TYPES } from '../../Icon';
import stripHtml from '../../../utils/stripHtml';
import {
  getKey,
  isEnterKey,
  isSpaceKey,
  isArrowDownKey,
  isArrowUpKey,
  isEsc,
  isTab
} from '../../../utils/detectKey';
import styles from '../dropdown.scss';
import layoutStyles from '../../../css/layout.scss';
import DesktopItem from './Item';
import { isDropdownAccessibilityEnabled, isA11yWfcEnhancementEnabled } from '../../../services/featureFlags';
import { dropdownListType, selectedType } from '../types';
import { getOpenerContent, isItemSelected } from '../common';

const {
  bool, string, func
} = PropTypes;

class DesktopDropdown extends Component {
  static propTypes = {
    dropdownList: dropdownListType,
    label: string,
    isDropdownLabelInactive: bool,
    title: string,
    selected: selectedType,
    selectItem: func,
    containerStyles: string,
    validationEmpty: string,
    validationFailed: string,
    requiredField: string,
    disabled: bool,
    selectByWordWhenIsOpenOnly: bool,
    isMultiValued: bool
  };

  static defaultProps = {
    dropdownList: [],
    label: '',
    isDropdownLabelInactive: false,
    title: '',
    selected: null,
    selectItem: noop,
    disabled: false,
    selectByWordWhenIsOpenOnly: false,
    isMultiValued: false
  };

  constructor(props) {
    super(props);
    this.state = {
      listInvisible: true,
      focusedIndex: -1
    };

    this.buffer = '';

    this.handleShow = this.handleShow.bind(this);
    this.handleArrowDown = this.handleArrowDown.bind(this);
    this.handleArrowUp = this.handleArrowUp.bind(this);
    this.handleKeyPressOnContainer = this.handleKeyPressOnContainer.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleKeyPressSelectItem = this.handleKeyPressSelectItem.bind(this);
    this.isEnterSpaceArrowDownKey = this.isEnterSpaceArrowDownKey.bind(this);
    this.selectByWord = this.selectByWord.bind(this);
    this.setRefDropdownSelector = this.setRefDropdownSelector.bind(this);
    this.getItemMatchingKeyPressed = this.getItemMatchingKeyPressed.bind(this);
    this.getAriaLabelObject = this.getAriaLabelObject.bind(this);
    this.debouncedGetItemMatchingKeyPressed = debounce(this.getItemMatchingKeyPressed.bind(this), 300);
  }

  setRefDropdownSelector(dropdownSelector) {
    this._$dropdownSelector = dropdownSelector;
  }

  isEnterSpaceArrowDownKey(e) {
    return isEnterKey(e) || isSpaceKey(e) || isArrowDownKey(e);
  }

  handleKeyPressOnContainer(event) {
    if (isEsc(event) && !this.state.listInvisible) {
      return this.handleHide();
    }
    if (!this.isEnterSpaceArrowDownKey(event)) {
      const keyPressCharacter = String.fromCharCode(getKey(event)).toString();
      if (keyPressCharacter) {
        if (this.props.selectByWordWhenIsOpenOnly && this.state.listInvisible) {
          return;
        }
        this.buffer = this.buffer.concat(keyPressCharacter);
        return this.debouncedGetItemMatchingKeyPressed(!this.state.listInvisible);
      }
    }
    event.preventDefault();
    return this.handleShow(0);
  }

  /*
  * Same method is called from onClick handler without passing isFocus
  * */
  // TODO: remove with onClickOutside
  handleShow(focusedIndex = -1) {
    const { disabled, dropdownList, isMultiValued } = this.props;
    if (disabled || !dropdownList || !dropdownList.length) return;
    this.setState({
      listInvisible: false,
      focusedIndex
    });

    if (!isMultiValued) {
      document.addEventListener('click', this.handleHide);
    }
  }

  // TODO: remove with onClickOutside
  handleHide() {
    const { listInvisible } = this.state;

    if (listInvisible) {
      return;
    }

    this.setState({
      listInvisible: true,
      focusedIndex: -1
    }, () => this._$dropdownSelector && this._$dropdownSelector.focus());

    if (!this.props.isMultiValued) {
      document.removeEventListener('click', this.handleHide);
    }
  }

  handleArrowDown() {
    const { focusedIndex } = this.state;
    const { dropdownList } = this.props;
    if (focusedIndex >= dropdownList.length - 1) return;
    this.setState({
      focusedIndex: focusedIndex + 1
    });
  }

  handleArrowUp() {
    const { focusedIndex } = this.state;
    if (focusedIndex <= 0) return;
    this.setState({
      focusedIndex: focusedIndex - 1
    });
  }

  handleKeyPressSelectItem(event, item) {
    if (isArrowDownKey(event)) {
      return this.handleArrowDown();
    } else if (isArrowUpKey(event)) {
      return this.handleArrowUp();
    } else if (isEsc(event) || isTab(event)) { // esc key or tab
      return this.handleHide();
    } else if (isEnterKey(event) || isSpaceKey(event)) {
      event.preventDefault();
      this.props.selectItem(item);
      return this.handleHide();
    }
    if (!this.props.selectByWordWhenIsOpenOnly || !this.state.listInvisible) {
      this.selectByWord(event);
    }
  }

  selectByWord(event) {
    const keyPressCharacter = String.fromCharCode(getKey(event)).toString();
    if (keyPressCharacter) {
      this.buffer = this.buffer.concat(keyPressCharacter);
      this.debouncedGetItemMatchingKeyPressed(true);
    }
  }

  getItemIndexMatchingBuffer(buffer) {
    return this.props.dropdownList.findIndex((item) => {
      const matchingText = item.text && item.text.toLowerCase().substr(0, buffer.length);
      return matchingText === buffer.toLowerCase();
    });
  }

  getItemMatchingKeyPressed(focusOnly) {
    const bufferMatchedItemIndex = this.getItemIndexMatchingBuffer(this.buffer);
    this.buffer = '';
    if (bufferMatchedItemIndex === -1) return null;
    if (focusOnly) {
      return this.setState({
        focusedIndex: bufferMatchedItemIndex
      });
    }
    return this.props.selectItem(this.props.dropdownList[bufferMatchedItemIndex]);
  }

  getAriaLabelObject() {
    const { ariaLabel, selected } = this.props;
    if (!isDropdownAccessibilityEnabled()) {
      return ariaLabel;
    }
    if (!ariaLabel || !ariaLabel['aria-label']) {
      return {};
    }

    // Removes ":" at the end of the string
    const cleanAriaLabelTitle = ariaLabel['aria-label'].trim().replace(/:$/, '');

    if (isA11yWfcEnhancementEnabled()) {
      const ariaLabelTextSelectedOptionFirst = selected && selected.text ?
        `${selected.text}, ${cleanAriaLabelTitle}:` :
        cleanAriaLabelTitle;

      return { 'aria-label': ariaLabelTextSelectedOptionFirst };
    }

    const ariaLabelText = selected && selected.text ?
      `${cleanAriaLabelTitle}: ${selected.text}` :
      cleanAriaLabelTitle;

    return { 'aria-label': ariaLabelText };
  }

  handleClickOutside = () => {
    const { isMultiValued } = this.props;

    if (isMultiValued) {
      this.handleHide();
    }
  };

  render() {
    const {
      isDropdownLabelInactive,
      label,
      dropdownList,
      title,
      containerStyles,
      selectorStyles,
      selectItem,
      calendarTitleStyles,
      calendarIconStyles,
      ariaRequired,
      ariaDisabled,
      ariaInvalid,
      disabled,
      selected,
      elementId,
      isMultiValued,
      isSubComponent
    } = this.props;

    const dropdownSelectorClasses = classNames(
      styles.dropdownSelector,
      {
        [styles.dropdownSelector_isDisable]: disabled,
        [styles.dropdownSelector_isInactive]: this.state.listInvisible
      }
    );

    const labelClasses = classNames(styles.dropdownLabel, {
      [styles.dropdownLabel_isInactive]: isDropdownLabelInactive
    });

    const listClasses = classNames(styles.dropdownList, {
      [styles.dropdownList_isActive]: !this.state.listInvisible
    });

    const openerAriaAttributes = {
      role: 'button',
      tabIndex: '0',
      'aria-haspopup': 'true',
      'aria-expanded': !this.state.listInvisible,
      ...ariaInvalid,
      ...ariaRequired,
      ...ariaDisabled
    };

    const ariaLabelObject = this.getAriaLabelObject();

    const openerAttributes = isDropdownAccessibilityEnabled() ? {
      type: 'button',
      id: elementId,
      ...ariaLabelObject
    } : {
      title: stripHtml(title),
      ...ariaLabelObject
    };

    const DropdownOpener = isDropdownAccessibilityEnabled() ? 'button' : 'div';
    const OpenerContent = isDropdownAccessibilityEnabled() ? 'span' : 'div';
    const listRole = isDropdownAccessibilityEnabled() ? 'listbox' : 'menu';
    const listContainerRoleObject = isDropdownAccessibilityEnabled() ? {} : { role: 'application' };

    const labelComponent = isDropdownAccessibilityEnabled() ?
      <label htmlFor={elementId} className={labelClasses}>{label}</label> :
      <div className={labelClasses}>{label}</div>;

    return (
      <div className={layoutStyles.notMobileScreenOnly}>
        {label && labelComponent}
        <div className={`${styles.dropdownContainer} ${containerStyles}`}>
          <DropdownOpener
            className={classNames(dropdownSelectorClasses, selectorStyles)}
            ref={this.setRefDropdownSelector}
            onClick={this.handleShow}
            onKeyDown={this.handleKeyPressOnContainer}
            {...openerAriaAttributes}
            {...openerAttributes}
          >
            <OpenerContent className={calendarTitleStyles}>
              {getOpenerContent(this.props)}
            </OpenerContent>
            <Icon containerStyles={calendarIconStyles} type={ICON_TYPES.ARROW_DROPDOWN} />
          </DropdownOpener>
          <div {...listContainerRoleObject}>
            <ul role={listRole} className={listClasses} tabIndex={isA11yWfcEnhancementEnabled() ? '-1' : null}>
              {
                dropdownList.map((item, index) => (
                  <DesktopItem
                    key={item.id || index}
                    selected={isItemSelected({ itemId: item.id, isMultiValued, selected })}
                    selectItem={selectItem}
                    item={item}
                    handleKeyPressSelectItem={this.handleKeyPressSelectItem}
                    focused={index === this.state.focusedIndex}
                    isMultiValued={isMultiValued}
                    isSubComponent={isSubComponent}
                  />
                  ))
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(DesktopDropdown);
