import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function multiValueFieldEvaluator(node, values) {
  return values[node.field];
}

evaluatorRegistry
  .registerEvaluatorForNodeType(NODE_TYPES.MULTI_VALUE_FIELD, multiValueFieldEvaluator);
export default multiValueFieldEvaluator;
