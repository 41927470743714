/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Dropdown from '../../../commons/Dropdown';
import { EMPTY_TEXT, EMPTY_VALUE } from './DropdownLayout';
import styles from './DropdownWrap.scss';

export const TEST_NAME_DROPDOWN_WRAP = 'DROPDOWN_WRAP';

const {
  func,
  number,
  shape,
  string
} = PropTypes;

export default class DropdownWrap extends Component {
  static propTypes = {
    id: string.isRequired,
    optionsLength: number.isRequired,
    selected: shape({ id: string, text: string }).isRequired,
    setAriaLiveMessage: func.isRequired,
    text: string.isRequired,
    updatePositions: func.isRequired,
    label: string
  }

  static defaultProps = {
    label: null
  }

  static getOptions(length) {
    const options = Array(length).fill().reduce((acc, item, idx) => {
      acc.push({
        text: `${idx + 1}`,
        id: `${idx + 1}` // increment to match Dropdown logic
      });
      return acc;
    }, [
      {
        text: EMPTY_TEXT,
        id: EMPTY_VALUE
      }
    ]);

    return options;
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /* I have no good idea of how to test it at the time of writing the code */
  /* istanbul ignore next */
  onChange(newVal) {
    const {
      props: {
        id,
        text,
        setAriaLiveMessage,
        updatePositions
      }
    } = this;

    const isReset = newVal === EMPTY_VALUE;
    const positionNewVal = isReset ? null : Number(newVal) - 1; // zero based
    const ariaNewVal = isReset ? null : Number(newVal);

    updatePositions(id, positionNewVal);
    setAriaLiveMessage(text, ariaNewVal);
  }

  render() {
    const {
      props: {
        label,
        selected,
        optionsLength
      },
      onChange
    } = this;

    const options = DropdownWrap.getOptions(optionsLength);

    return (
      <div
        data-test-name={TEST_NAME_DROPDOWN_WRAP}
      >
        <Dropdown
          isDropdownLabelInactive
          dropdownList={options}
          selected={selected}
          onChange={onChange}
          containerStyles={styles.dropdownContainer}
          selectorStyles={styles.dropdownSelector}
          label={label}
          modalHeaderStyles={styles.modalHeaderStyles}
        />
      </div>
    );
  }
}
