import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SingleDropdown from './SingleDropdown';
import withCondition from '../../commons/withCondition';
import answerQuestion from '../../redux/actions/answerQuestion';
import IPCService from '../../services/inPageConditioning';

const mapStateToProps = (state, {
  id,
  fieldName,
  alt = [],
  formkey: htmlInputId,
  isMultiValued
}) => {
  let selectedAltId;
  let filteredAlt;

  if (fieldName) {
    filteredAlt = alt.filter(a => IPCService.shouldShowAlternative(id, fieldName, a.id));
    selectedAltId = get(state.components, [id, 'values', fieldName]);
  } else {
    filteredAlt = alt;
    selectedAltId = get(state, ['htmlInputs', htmlInputId, 'value']);
  }

  let selected = isMultiValued ? [] : undefined;

  // some elements are selected
  if (selectedAltId) {
    if (isMultiValued) {
      selected = selectedAltId.map(selectedId => filteredAlt.find(a => a.id === selectedId));
    } else {
      selected = filteredAlt.find(a => a.id === selectedAltId);
    }
  }

  return { alt: filteredAlt, selected };
};

const mapDispatchToProps = (dispatch, { id, fieldName, formkey: htmlInputId }) => ({
  onChange: (activeValue) => {
    dispatch(answerQuestion({
      htmlInputId,
      questionId: id,
      value: activeValue,
      componentId: id,
      field: fieldName
    }));
  }
});

export default compose(
  withCondition,
  connect(mapStateToProps, mapDispatchToProps)
)(SingleDropdown);
