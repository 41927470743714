import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';

export const MATCH_TEXTAREA = 'textarea';

export default class TextareaLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_TEXTAREA;
    return shouldNormalize;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const {
      caption,
      formkey, // not camelcased by backend server
      value,
      disableAutocomplete,
      requiredField,
      maxChars,
      qualityMeterDismissCount,
      fieldName
    } = questionData;
    const hasQualityMeter = Boolean(questionData.hasQualityMeter);
    const isRequired = Boolean(requiredField);
    const qualityMeterTexts = hasQualityMeter ?
      new Set(questionData.qualityMeterTexts) :
      new Set();

    const question = resourcesManager.createOrUpdateQuestion({
      id,
      caption,
      type: QUESTION_TYPES.TEXTAREA
    });

    const htmlInput = resourcesManager.createOrUpdateHtmlInput({
      value,
      maxChars,
      id: formkey,
      disableAutocomplete: Boolean(disableAutocomplete),
      question: id,
      type: HTML_INPUT_TYPES.TEXTAREA
    });

    const hasAnswer = Boolean(value);
    if (hasAnswer) question.htmlInputsWithAnswer.add(htmlInput.id);

    resourcesManager.createOrUpdateOption({
      value,
      hasAnswer,
      isRequired,
      maxChars,
      hasQualityMeter,
      qualityMeterTexts,
      qualityMeterDismissCount,
      caption: '',
      htmlInput: formkey,
      question: id
    });

    resourcesManager.createCondition(questionData.condition, id);
    const initialValue = hasAnswer ? value : '';
    resourcesManager.createField(fieldName, initialValue, id);

    return resourcesManager.resources();
  }
}
