/* global document $ */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import kebabCase from 'lodash/kebabCase';
import { isjQueryDisabled } from '../../services/featureFlags';

export default class HtmlBlock extends Component {
  static propTypes = {
    content: PropTypes.string,
    containerClassName: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.string
    ])
  };

  static defaultProps = {
    content: '',
    containerClassName: 'htmlBlock'
  };

  constructor(props) {
    super(props);

    this.scriptTags = []; // store all script tags and remove upon unMount
  }

  componentDidMount() {
    const htmlNodeWithScript = document.createElement('div');
    htmlNodeWithScript.innerHTML = this.props.content;
    let scriptString;
    if (isjQueryDisabled() || !window.$) {
      scriptString = htmlNodeWithScript.getElementsByTagName('script');
    } else {
      scriptString = $(htmlNodeWithScript).find('script');
    }

    if (this.props && this.props.content) {
      if (scriptString) {
        for (let i = 0; i < scriptString.length; i++) {
          const scriptTag = document.createElement('script');
          scriptTag.type = 'text/javascript';
          if (scriptString[i].src) {
            scriptTag.src = scriptString[i].src;
          }
          scriptTag.text = scriptString[i].innerHTML || scriptString[i].text;

          // enable data attributes
          if (scriptString[0].dataset) {
            Object.entries(scriptString[0].dataset).map(dataAttribute => {
              scriptTag.setAttribute(`data-${kebabCase(dataAttribute[0])}`, dataAttribute[1])
            })
          }

          document.body.appendChild(scriptTag);
          this.scriptTags.push(scriptTag);
        }
      }
    }
  }

  componentDidUnMount() {
    for (let j = 0; j < this.script_tags.length; j++) {
      document.body.removeChild(this.script_tags[j]);
    }
  }

  render() {
    const { content, containerClassName, shouldShow } = this.props;
    const classNameProp = containerClassName ? {
      className: containerClassName
    } : {};
    const SCRIPT_REGEX = /(<script[\s\S]*?>[\s\S]*?<\/script>)/gi;
    const htmlcontent = content && content.replace(SCRIPT_REGEX, '').trim();
    const shouldRender = (shouldShow == null) ? true : shouldShow;

    return shouldRender && (
      <div {...classNameProp} dangerouslySetInnerHTML={{ __html: htmlcontent }} /> // eslint-disable-line react/no-danger
    );
  }
}
