/**
 * @typedef EventType
 * - READY: fires when the survey was rendered
 * - QUESTION_ANSWERED: fires when the survey taker answer a question.
 *    Currently working for Grid and Multiplechoice - One
 */
export const EventTypes = {
  READY: 'READY',
  QUESTION_ANSWERED: 'QUESTION_ANSWERED'
};

const eventHandlers = {};
const availableEvents = Object.values(EventTypes);

availableEvents.forEach((eventType) => { eventHandlers[eventType] = []; });

function checkEventType(eventType) {
  if (!EventTypes[eventType]) {
    throw new Error(`Event ${eventType} not found. Available events: ${availableEvents}. Please check your custom JS!`);
  }
}

/**
 * @param {EventType} eventType
 * @param {function} callback
 */
export const on = (eventType, callback) => {
  checkEventType(eventType);

  eventHandlers[eventType].push(callback);
};

/**
 * @param {EventType} eventType
 * @param {function} callback
 */
export const off = (eventType, callback) => {
  checkEventType(eventType);

  const indexOfCallback = eventHandlers[eventType].indexOf(callback);
  if (indexOfCallback !== -1) {
    eventHandlers[eventType].splice(indexOfCallback, 1);
  }
};

export const dispatchEvent = (eventType, eventArguments = {}) => {
  checkEventType(eventType);

  const callbacks = eventHandlers[eventType];
  callbacks.forEach(callback => callback({
    ...eventArguments,
    type: eventType
  }));
};

export const surveyIsReady = () => {
  dispatchEvent(EventTypes.READY);
};

export const questionAnswered = (questionId, currentValue, previousValue, extraParams) => {
  if (!questionId) return;

  dispatchEvent(EventTypes.QUESTION_ANSWERED, {
    questionId,
    // ensure null if answer's undefined
    currentValue: currentValue === undefined ? null : currentValue,
    previousValue: previousValue === undefined ? null : previousValue,
    extraParams
  });
};

export default {
  on,
  off
};

