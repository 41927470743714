/* global document */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import noop from 'lodash/noop';
import stripHtml from '../../utils/stripHtml';
import styles from './dropdown.scss';
import MobileDropdown from './MobileDropdown/MobileDropdown';
import DesktopDropdown from './DesktopDropdown/DesktopDropdown';
import { dropdownListType, selectedType } from './types';

const {
  bool,
  string,
  func
} = PropTypes;

export const DROPDOWN_PLACEHOLDER = '- Select -';
export default class Dropdown extends Component {
  static propTypes = {
    dropdownList: dropdownListType,
    label: string,
    isDropdownLabelInactive: bool,
    containerStyles: string,
    title: string,
    selected: selectedType,
    onChange: func,
    validationEmpty: string,
    validationFailed: string,
    requiredField: string,
    disable: bool,
    elementId: string,
    selectByWordWhenIsOpenOnly: bool,
    isMultiValued: bool,
    modalHeaderStyles: string
  };

  static contextTypes = {
    translations: PropTypes.object
  };

  static defaultProps = {
    dropdownList: [],
    label: '',
    isDropdownLabelInactive: false,
    containerStyles: '',
    title: '',
    selected: null,
    onChange: noop,
    disable: false,
    validationEmpty: '',
    validationFailed: '',
    requiredField: '',
    elementId: '',
    selectByWordWhenIsOpenOnly: false,
    isMultiValued: false,
    modalHeaderStyles: ''
  };

  constructor(props) {
    super(props);
    this.selectItem = this.selectItem.bind(this);
  }

  getSelected() {
    const { context } = this;
    const { isMultiValued, selected, dropdownList } = this.props;

    const translations = (context && context.translations) || { DROPDOWN_PLACEHOLDER };

    const placeholder = {
      text: translations.DROPDOWN_PLACEHOLDER,
      id: translations.DROPDOWN_PLACEHOLDER
    };

    if (isMultiValued) {
      return selected.length === 0 ? placeholder : selected;
    }

    const { id: selectedId } = selected || {};

    return selectedId === undefined || !dropdownList.some(d => d.id === selectedId) ?
      placeholder :
      selected;
  }

  selectItem(item) {
    const { isMultiValued, selected } = this.props;

    if (isMultiValued) {
      const selectedIds = selected.reduce((memo, { id }) => {
        // eslint-disable-next-line no-param-reassign
        memo[id] = id;
        return memo;
      }, {});

      if (selectedIds[item.id] !== undefined) {
        delete selectedIds[item.id];
      } else {
        selectedIds[item.id] = item.id;
      }

      this.props.onChange(Object.keys(selectedIds));
      return;
    }

    const { id: selectedId } = selected || {};

    if (selectedId !== item.id) {
      this.props.onChange(item.id);
    }
  }

  render() {
    const {
      validationEmpty,
      validationFailed,
      requiredField,
      isDropdownLabelInactive,
      label,
      title,
      containerStyles,
      selectorStyles,
      dropdownList,
      elementId,
      selectByWordWhenIsOpenOnly,
      isMultiValued,
      isSubComponent,
      modalHeaderStyles
    } = this.props;

    const disabled = this.props.dropdownList.length === 0;
    const ariaLabelText = label || title;
    const ariaDisabled = disabled && { 'aria-disabled': true };
    const ariaRequired = (requiredField || validationEmpty) && { 'aria-required': true };
    const ariaInvalid = (validationFailed || validationEmpty) && { 'aria-invalid': true };
    const ariaLabel = (ariaLabelText) && { 'aria-label': stripHtml(ariaLabelText) };
    const elementIdObject = elementId ? { elementId } : {};

    return (
      <div>
        <DesktopDropdown
          label={label}
          title={title}
          containerStyles={containerStyles}
          selectorStyles={selectorStyles}
          isDropdownLabelInactive={isDropdownLabelInactive}
          disabled={disabled} // Disable the dropdown because there are no options
          ariaInvalid={ariaInvalid}
          ariaRequired={ariaRequired}
          ariaDisabled={ariaDisabled}
          ariaLabel={ariaLabel}
          calendarTitleStyles={styles.dropdownTitle}
          calendarIconStyles={styles.dropdownIcon}
          selected={this.getSelected()}
          selectItem={this.selectItem}
          dropdownList={dropdownList}
          selectByWordWhenIsOpenOnly={selectByWordWhenIsOpenOnly}
          isMultiValued={isMultiValued}
          isSubComponent={isSubComponent}
          {...elementIdObject}
        />
        <MobileDropdown
          label={label}
          title={title}
          containerStyles={containerStyles}
          selectorStyles={selectorStyles}
          isDropdownLabelInactive={isDropdownLabelInactive}
          disabled={disabled} // Disable the dropdown because there are no options
          ariaInvalid={ariaInvalid}
          ariaRequired={ariaRequired}
          ariaDisabled={ariaDisabled}
          ariaLabel={ariaLabel}
          calendarTitleStyles={styles.dropdownTitle}
          calendarIconStyles={styles.dropdownIcon}
          selected={this.getSelected()}
          selectItem={this.selectItem}
          dropdownList={dropdownList}
          isMultiValued={isMultiValued}
          modalHeaderStyles={modalHeaderStyles}
        />
      </div>
    );
  }
}
