/* global window */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import { setAttribute, setInitialLoadTime } from '../../services/telemetry';
import CookieBanner from '../CookieBanner';
import styles from '../../css/layout.scss';
import GeoLocation from '../GeoLocation';
import NavigationButtons from '../NavigationButtons';
import TimeoutWarning from '../TimeoutWarning';
import CustomCss from './CustomCss';
import PageBody from './PageBody';
import PageField from './PageField';
import PageFooter from './PageFooter';
import PageHead from './PageHead';
import { isAutoSave_Enabled_FF } from '../../services/featureFlags/index';
import {noticeError} from '../../services/telemetry/index';
import runValidations from '../../utils/runValidations';
import { isA11yWfcEnhancementEnabled } from '../../services/featureFlags';

const {
  arrayOf, bool, number, object, shape, string, func
} = PropTypes;

function trackPageMetrics(props) {
  setInitialLoadTime();
  setAttribute('PageNumber', props.pagerPage + 1);
  setAttribute('SurveyId', props.surveyid);
  setAttribute('SpecId', props.specId ? props.specId.toString() : 'Unknown');
  setAttribute('ClientId', props.clientId ? props.clientId.toString() : 'Unknown');
  setAttribute('SurveyProgressPercentage', props.pagerPercent);
  setAttribute('CompanyName', props.desktop_params && props.desktop_params.clientName || 'isNull');
}

export default class PageTemplate extends Component {
  static propTypes = {
    action: string,
    setAceFields: bool,
    textDirection: string,

    timeoutEnabled: bool,
    surveyTimeoutSeconds: number,
    surveyTimeoutWarningSeconds: number,

    desktop_params: shape({
      customfootereverypage: bool,
      Decorative_logo_alt: string,
      Decorative_logo: string,
      disableTechSupport: bool,
      html_feed_ug_customfooter: string,
      Page_logo_alt: string,
      Page_logo: string,
      Splash_logo_alt: string,
      Splash_logo: string,
      techSupportLink: string,
      clientName: string
    }),
    languages: arrayOf(shape({
      active: bool,
      key: string,
      name: string
    })),
    isFirstPage: bool,
    pagerPage: number,
    pagerPercent: number,
    surveyLanguage: string,
    title: string,

    body: object,
    customMessageHandling: bool,
    defPgrAction: string,
    pagerValidationFailed: bool,
    storeUserLocation: bool,

    questionBlocks: arrayOf(object),
    translations: object,

    currentYear: string,
    surveyid: string,
    specId: string,
    clientId: string,
    isMobile: bool,
    native_lang_enabled: bool
  };

  static defaultProps = {
    currentYear: `${(new Date()).getFullYear()}`,
    desktop_params: {},
    pagerPercent: 0,
    pagerPage: -1,
    translations: {},
    native_lang_enabled: false
  };

  static childContextTypes = {
    translations: object,
    onSubmit: func
  };

  constructor(props) {
    super(props);
    trackPageMetrics(props);

    this.isFirstPage = props.isFirstPage;
    this.hasFileUploadComponent = !!props.questionBlocks.find(({ blockType }) => blockType === 'mediaStreamerFileUploader');
  }

  sendAutoSaveInfo() {
    var form = document.querySelector('[name=surveyform]');
    var formData = new FormData(form);
    var bodyForm = new URLSearchParams(formData)
    bodyForm.append('autoSave', true)

    try {
        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: bodyForm,
        })
        .then(response => response.json())
        .then(data => {
          document.querySelector('[name="ballotVer"]').value = data.ballotVer;
          trackPageMetrics(data);
        });
    } catch (err) {
      noticeError(err);
      console.log(err);
    }
  }

  isQuickStartEnabled() {
    return !!location.search.indexOf("_score");
  }

  componentDidMount() {
    if (isAutoSave_Enabled_FF() && this.isFirstPage) {
      if (this.isQuickStartEnabled()) {
        
        setTimeout(() => {
          this.sendAutoSaveInfo();
        }, 1000)
      }
    }

    if (!this.hasFileUploadComponent) return;
    document.addEventListener('dragover', this._preventDragDefault);
  }

  componentWillUnmount() {
    if (!this.hasFileUploadComponent) return;
    document.removeEventListener('dragover', this._preventDragDefault);
  }

  _preventDragDefault(e) {
    e.preventDefault();
  }

  getChildContext() {
    return { translations: this.props.translations, onSubmit: runValidations };
  }

  render() {
    const {
      action,
      setAceFields,
      textDirection,

      // TimeoutWarning
      surveyTimeoutSeconds,
      surveyTimeoutWarningSeconds,
      timeoutEnabled,

      // PageHead
      desktop_params,
      languages,
      pagerPage,
      isFirstPage,
      pagerPercent,
      surveyLanguage,
      title,

      // PageField
      body,
      customMessageHandling,
      defPgrAction,
      pagerValidationFailed,
      storeUserLocation,

      // PageBody
      questionBlocks,
      translations,

      // PageFooter
      currentYear,

      isMobile,
      native_lang_enabled
    } = this.props;

    const pageGlobalCssHook = pagerPage === -1 ? 'page-thankyou' : `page-${pagerPage}`;
    const containerStyles = classNames(
      styles[textDirection],
      textDirection,
      pageGlobalCssHook,
    );

    // wrap page with form tag if action is not null
    const Container = action ? 'form' : 'div';
    const containerProps = action ? { name: 'surveyform', action, method: 'post' } : {};

    return (
      <div className={containerStyles}>
        <CustomCss />

        <Container {...containerProps}>

          <CookieBanner />

          <div>
            {setAceFields && (<div id="acediv" />)}
            <TimeoutWarning
              shouldRender={timeoutEnabled}
              surveyTimeoutSeconds={surveyTimeoutSeconds}
              surveyTimeoutWarningSeconds={surveyTimeoutWarningSeconds}
            />

            <input type="hidden" name="is_embedded" id="is_embedded" value={window.top !== window.self} />

            <PageHead
              pagerPage={pagerPage}
              isFirstPage={isFirstPage}
              decorativeLogo={desktop_params.Decorative_logo}
              decorativeLogoAlt={desktop_params.Decorative_logo_alt}
              splashLogo={desktop_params.Splash_logo}
              splashLogoAlt={desktop_params.Splash_logo_alt}
              pageLogo={desktop_params.Page_logo}
              pageLogoAlt={desktop_params.Page_logo_alt}
              pagerPercent={pagerPercent}
              languages={languages}
              surveyLanguage={surveyLanguage}
              surveyTitle={title}
              useNativeDropdown={native_lang_enabled}
            />

            <PageField
              ballotVersion={body.ballotVer}
              ballotVersionNotFound={body.ballotVerNotFound}
              showBackButtonDisabledMessage={body.showBackButtonDisabledMessage}
              pageIdxBiggerThanLastShown={body.pageIdxBiggerThanLastShown}
              customMessageHandling={customMessageHandling}
              defPgrAction={defPgrAction}
              fieldval={body.fieldval}
              fieldname={body.fieldname}
              pageNumber={pagerPage}
              nodeId={body.nodeId}
              pagerValidationFailed={pagerValidationFailed}
            />

            <PageBody questionBlocks={questionBlocks} isMobile={isMobile} {...translations} />

            { !isA11yWfcEnhancementEnabled() && <NavigationButtons /> }

            {storeUserLocation && <GeoLocation />}
            <PageFooter
              showContent={isFirstPage || desktop_params.customfootereverypage}
              customFooter={desktop_params.html_feed_ug_customfooter}
              currentYear={currentYear}
              techSupportLink={desktop_params.techSupportLink}
              disableTechSupport={desktop_params.disableTechSupport}
            />
          </div>

        </Container>
      </div>
    );
  }
}
