import ScrollServiceInstance from '../../services/scroll';
import ValidationsServiceInstance from '../../services/validations';

export default function runValidations(event) {
  const results = ValidationsServiceInstance.runAllValidations();
  if (results.length > 0) {
    event.preventDefault();
    ScrollServiceInstance.scrollIntoComponent(results[0]);
  }
}
