import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './progressBarStep.scss';

const { number, string } = PropTypes;

export default class ProgressBarSteps extends Component {
  static propTypes = {
    steps: number,
    currentActive: number,
    containerStyles: string
  };

  render() {
    const steps = [];
    for (let i = 0; i < this.props.steps; i++) {
      const isActive = i < this.props.currentActive;

      const progressStepStyles = classNames(styles.progressStep, {
        [styles.progressStep_isActivated]: isActive
      });

      steps.push(
        <div key={i} className={progressStepStyles}/>
      );
    }

    const progressStepsContainerStyles = classNames(
      styles.progressStepsContainer,
      this.props.containerStyles
    );

    return (
      <div className={progressStepsContainerStyles}>
        {steps}
      </div>
    );
  }
}
