import evaluateNode from '../..';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function inSetEvaluator(node, values) {
  const fieldValue = evaluateNode(node.left, values);
  const set = evaluateNode(node.right, values);

  return set.includes(fieldValue);
}


evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.IN_SET, inSetEvaluator);
export default inSetEvaluator;
