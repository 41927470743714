import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

import Text from '../Text';
import ValidationMessage from '../../commons/ValidationMessage';
import LivingLensV0  from '../../commons/LivingLensV0';
import LivingLensV1 from '../../commons/LivingLensV1';
import Question from '../../commons/Question';
import { getTranslation } from '../../services/translations';
import { ERROR_CODES } from '../../services/LivingLens';
import ScrollServiceInstance from '../../services/scroll';
import ValidationsServiceInstance from '../../services/validations';
import { setAttribute } from '../../services/telemetry';
import styles from './multimediaFeedback.scss';

const ERROR_CODE_MESSAGE = 'Error code: ';

export default class MultimediaFeedback extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    apiKey: PropTypes.string.isRequired,
    channelId: PropTypes.string.isRequired,
    respondentId: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    mediaTypes: PropTypes.arrayOf(PropTypes.oneOf([
      'video',
      'audio',
      'image'
    ])).isRequired,
    countryCode: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
    minLength: PropTypes.string,
    maxLength: PropTypes.string,
    interfaceLanguageCode: PropTypes.string.isRequired,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    caption: PropTypes.string,
    instructions: PropTypes.string,
    disclaimer: PropTypes.string,
    dataUpload: PropTypes.bool,
    singleUse: PropTypes.bool,
    disableBranding: PropTypes.bool,
    widgetDomain: PropTypes.string,
    widgetEnvironment: PropTypes.string,
    widgetVersion: PropTypes.string,
    region: PropTypes.string,
    retries: PropTypes.number,
    answerQuestion: PropTypes.func.isRequired,
    fields: PropTypes.shape({
      uploadUrl: PropTypes.string.isRequired,
      uploadId: PropTypes.string.isRequired,
      mediaId: PropTypes.string.isRequired,
      mediaType: PropTypes.string.isRequired,
      noAnswer: PropTypes.string.isRequired,
      errorCode: PropTypes.string.isRequired
    }).isRequired,
    htmlInputs: PropTypes.object.isRequired,
    setComponentData: PropTypes.func.isRequired
  };

  static LivingLensWidgets = {
    V0: LivingLensV0,
    V1: LivingLensV1
  };

  constructor(props) {
    super(props);

    this.errorMessages = {
      [ERROR_CODES.ERROR_LOADING_SCRIPT]: getTranslation('LL_LOADING_ISSUE')
    };

    this.state = {
      forceLivingLensV0: false
    };

    this.setAnswer = this.setAnswer.bind(this);
    this.setHasAnswerValue = this.setHasAnswerValue.bind(this);
    this.setError = this.setError.bind(this);
    this.setErrorCode = this.setErrorCode.bind(this);
    this.setMediaStatus = this.setMediaStatus.bind(this);
    this.getMultimediaComponent = this.getMultimediaComponent.bind(this);
    this.renderErrorMessage = this.renderErrorMessage.bind(this);

    // TODO: Rethink this once we have multiple validations for this component.
    this.validationID = 0;

    this.multimediaFeedbackRef = null;
  }

  setHasAnswerValue(hasAnswer) {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.noAnswer,
      value: hasAnswer ? '' : true
    });
  }

  setErrorCode(errorCode) {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.errorCode,
      value: errorCode
    });
  }

  setAnswer(data) {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.uploadId,
      value: data.uploadId
    });
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.mediaId,
      value: data.mediaId
    });
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.uploadUrl,
      value: data.mediaUrl
    });

    ValidationsServiceInstance.resetValidationsForComponent(this.props.id);
    this.setHasAnswerValue(true);
    this.setMediaStatus('UPLOADED');

    this.collectRetriesTelemetry();

    const errorCode = this.props.htmlInputs[this.props.fields.errorCode];
    if (errorCode !== ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE) {
      this.setErrorCode('');
    }
  }

  collectRetriesTelemetry() {
    const component = ValidationsServiceInstance.components.get(this.props.id);
    const initialRetries = get(component, [this.validationID, 'retries'], 0);
    const performedRetries = initialRetries - this.props.retries;

    setAttribute('LL_UPLOADED', { retries: performedRetries });
  }

  setError(errorCode) {
    if (errorCode === ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE) {
      this.setState({ forceLivingLensV0: true });
    }
    this.setErrorCode(errorCode);
    this.setHasAnswerValue(false);
  }

  setMediaStatus(status) {
    this.props.setComponentData(this.props.id, {
      mediaStatus: status
    });
  }

  getMultimediaComponent(version) {
    if (this.state.forceLivingLensV0) {
      return LivingLensV0;
    }

    // widgetVersion property will be specified by the SE > 20CR4
    // If it's not there, then we're running against an older SE
    // and we should fallback to LL V0 widget
    if (isUndefined(version)) {
      return LivingLensV0;
    }

    return MultimediaFeedback.LivingLensWidgets[version] || LivingLensV1;
  }

  renderErrorMessage() {
    const {
      htmlInputs,
      fields
    } = this.props;
    const errorCode = htmlInputs[fields.errorCode];
    const message = this.errorMessages[errorCode];
    const errorCodeMessage = `${ERROR_CODE_MESSAGE}${errorCode}`;

    return errorCode === ERROR_CODES.ERROR_LOADING_SCRIPT && ([
      <ValidationMessage key="msg" isError hasIcon={false} containerStyles={styles.errorMessage} caption={message} />,
      <ValidationMessage key="code" isError hasIcon={false} containerStyles={styles.errorCode} caption={errorCodeMessage} />
    ]);
  }

  renderValidationMessage() {
    const { validationID, props: { id } } = this;
    const conforms = ValidationsServiceInstance.componentConformsToValidation(id, validationID);

    if (conforms) {
      return null;
    }

    const componentValidations = ValidationsServiceInstance.components.get(id);
    const validationMessage = componentValidations[validationID].message;

    return (
      <ValidationMessage
        key="val-message"
        isError
        hasIcon={false}
        containerStyles={styles.errorMessage}
        caption={validationMessage}
      />
    );
  }

  componentDidMount() {
    ScrollServiceInstance.subscribeComponent(this.props.id, this.multimediaFeedbackRef);
  }

  render() {
    const {
      apiKey,
      channelId,
      respondentId,
      questionId,
      mediaTypes,
      countryCode,
      languageCode,
      minLength,
      maxLength,
      interfaceLanguageCode,
      minWidth,
      maxWidth,
      caption,
      instructions,
      disclaimer,
      htmlInputs,
      dataUpload,
      singleUse,
      disableBranding,
      widgetDomain,
      widgetEnvironment,
      widgetVersion,
      region
    } = this.props;

    const Multimedia = this.getMultimediaComponent(widgetVersion);

    return (
      <div
        className="questionBlock multimediaFeedback"
        ref={ref => this.multimediaFeedbackRef = ref}
      >
        {caption && <Question caption={caption} />}
        {instructions && <Text caption={instructions} />}
        {this.renderErrorMessage()}
        {this.renderValidationMessage()}
        <Multimedia
          apiKey={apiKey}
          channelId={channelId}
          respondentId={respondentId}
          questionId={questionId}
          mediaTypes={mediaTypes}
          mediaCountryCode={countryCode}
          mediaLanguageCode={languageCode}
          mediaMinLength={minLength}
          mediaMaxLength={maxLength}
          interfaceLanguageCode={interfaceLanguageCode}
          minWidth={minWidth}
          maxWidth={maxWidth}
          onUploadSuccess={this.setAnswer}
          onRecordComplete={() => {
            this.setMediaStatus('RECORDED');
            setAttribute('LL_RECORDED', this.props.id);
          }}
          onError={this.setError}
          allowFileUpload={dataUpload}
          singleUseMode={singleUse}
          disableBranding={disableBranding}
          widgetDomain={widgetDomain}
          widgetEnvironment={widgetEnvironment}
          region={region}
        />
        {disclaimer && <Text caption={disclaimer} />}

        {Object.keys(htmlInputs).map(htmlInput => (
          <input type="hidden" name={htmlInput} id={htmlInput} key={htmlInput} value={htmlInputs[htmlInput]} />
        ))}
      </div>
    );
  }
}
