/* eslint-disable import/prefer-default-export */

import PropTypes from 'prop-types';

import React from 'react';
import isNil from 'lodash/isNil';
import { ReorderableList } from './ReorderableList';
import { DraggableItem } from './DraggableItem';
import styles from './DNDLayout.scss';

const {
  arrayOf,
  shape,
  string,
  func
} = PropTypes;

export class DNDLayout extends React.Component {
  static propTypes = {
    originalItems: arrayOf(shape({
      id: string,
      text: string
    })).isRequired,
    selectedItems: arrayOf(shape({
      id: string,
      text: string
    })).isRequired,
    reorderList: func.isRequired,
    firstSelection: func.isRequired
  };

  constructor(props) {
    super(props);

    this.onReorder = this.onReorder.bind(this);
    this.getDragPreview = this.getDragPreview.bind(this);
    this.setRef = this.setRef.bind(this);
    this.getRef = this.getRef.bind(this);
    this.getItems = this.getItems.bind(this);
    this.isAnswered = this.isAnswered.bind(this);

    this.itemsRefs = {};
  }

  onReorder = (rowIndex, targetIndex) => {
    const {
      reorderList,
      firstSelection
    } = this.props;

    if (!isNil(targetIndex)) {
      const alternative = this.getItems()[rowIndex];

      if (this.isAnswered()) {
        reorderList(alternative.id, targetIndex);
      } else {
        firstSelection(alternative.id, targetIndex);
      }
    }
  };

  getItemWidth(index) {
    const { id } = this.getItems()[index];
    const domEle = this.itemsRefs[id];
    const width = domEle ? domEle.getBoundingClientRect().width : null;
    return width;
  }

  getDragPreview = ({
    rowIndex,
    dropTargetIndex
  }) => (
    <DraggableItem
      text={this.getItems()[rowIndex].text}
      orderNumber={Number.isInteger(dropTargetIndex) ? dropTargetIndex : rowIndex}
      width={this.getItemWidth(rowIndex)}
      isPreview
    />
  );

  setRef = id => (ref) => {
    this.itemsRefs[id] = ref;
  }

  getRef = id => this.itemsRefs[id];

  getItems() {
    return this.isAnswered() ? this.props.selectedItems : this.props.originalItems;
  }

  isAnswered() {
    return !!this.props.selectedItems.length;
  }

  render() {
    return (
      <div className={styles.list_container}>
        <ReorderableList
          getPreview={this.getDragPreview}
          getRef={this.getRef}
          items={this.getItems()}
          onReorder={this.onReorder}
        >
          { (
            id,
            isDragging,
            isValidDropTarget,
            placeholderHeight,
            rowIndex,
            showDropPlaceholderAbove,
            showDropPlaceholderBellow,
            text
          ) =>
            (<DraggableItem
              isValidDropTarget={isValidDropTarget}
              isDragging={isDragging}
              key={id + text}
              orderNumber={this.isAnswered() ? rowIndex : undefined}
              placeholderHeight={placeholderHeight}
              setRef={this.setRef(id)}
              showDropPlaceholderAbove={showDropPlaceholderAbove}
              showDropPlaceholderBellow={showDropPlaceholderBellow}
              text={text}
            />)}
        </ReorderableList>
      </div>
    );
  }
}
