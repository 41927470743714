import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './cookieBannerHeader.scss';
import Icon, { ICON_TYPES } from '../../../commons/Icon';
import IconClose from './IconClose';

const Header = ({
  onBack, onClose, showBackButton, backButtonText, closeButtonText
}) => {
  const classes = classNames(styles.header, { [styles.spaceBetween]: showBackButton });

  const handleOnBack = (e) => {
    e.preventDefault();
    onBack();
  };

  return (
    <div className={classes}>
      {showBackButton &&
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" className={styles.backButton} onClick={handleOnBack}>
        <Icon type={ICON_TYPES.ARROW_LEFT} />
        <span dangerouslySetInnerHTML={{ __html: backButtonText }} />
      </a>
      }

      <button aria-label={closeButtonText} type="button" onClick={onClose}><IconClose /></button>
    </div>
  );
};

Header.propTypes = {
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  backButtonText: PropTypes.string.isRequired,
  closeButtonText: PropTypes.string.isRequired
};

export default Header;
