import { NODE_TYPES } from '../../constants';

function fieldGenerator(field) {
  return {
    type: NODE_TYPES.FIELD,
    field
  };
}

export default fieldGenerator;
