import { QUESTION_TYPES } from '../../question';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';

export const MATCH_FILE_UPLOAD = 'mediaStreamerFileUploader';

export default class FileUploadLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return questionData.blockType === MATCH_FILE_UPLOAD;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const { caption } = questionData;

    resourcesManager.createOrUpdateQuestion({
      id,
      caption,
      type: QUESTION_TYPES.FILE_UPLOAD
    });

    resourcesManager.createCondition(questionData.condition, id);

    return resourcesManager.resources();
  }
}
