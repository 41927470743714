import { connect } from 'react-redux';
import { compose } from 'redux';
import RatingGrid from './RatingGrid';
import { isSurveyAPIEnabled } from '../../services/featureFlags';
import { formKeyToQField } from '../../utils/formKeyAndQFieldTransformations';
import withCondition from '../../commons/withCondition';
import IPCService from '../../services/inPageConditioning';

export const mapStateToProps = (state, ownProps) => {
  if (!state.questions || !state.questions[ownProps.id]) return {};
  const question = state.questions[ownProps.id];
  const {
    ariaLabel,
    caption,
    isRequired,
    options,
    useFieldset,
    hasValidationFailure,
    hasValidationFailureForEmpty
  } = question;

  const optionsSet = [...options];
  const firstVisibleRow = optionsSet.findIndex(IPCService.shouldShowComponent);
  const qFields = isSurveyAPIEnabled() ?
    optionsSet.map(optionKey => (state.options[optionKey] ?
      formKeyToQField(state.options[optionKey].htmlInput) :
      null))
      .filter(Boolean) :
    [];

  return {
    ariaLabel,
    caption,
    isRequired,
    options,
    qFields,
    useFieldset,
    hasValidationFailure,
    hasValidationFailureForEmpty,
    firstVisibleRow
  };
};

export default compose(
  withCondition,
  connect(mapStateToProps)
)(RatingGrid);

