import pull from 'lodash/pull';
import answerQuestion from '../../redux/actions/answerQuestion';
import setComponentAnswer from '../../redux/actions/setComponentAnswer';

export const longformAnswerQuestion = params => (dispatch, getState) => {
  const state = getState();

  const { questionId, optionId, value } = params;
  const question = state.questions[questionId];
  const option = state.options[optionId];
  const { isMultiSelect, hasAnswer, offValue } = option;
  const field = state.fields[question.field || option.field];

  // If the field doesn't exist, then we're probably against a previous version of SE
  // where the fields were not included in the surveyData JSON and we should only dispatch
  // the legacy action
  const shouldOnlyDispatchLegacyAction = !field;
  // And if that's the only we're dispatching, then it should trigger the Survey API Events
  const shouldTriggerSurveyAPIEvents = shouldOnlyDispatchLegacyAction;

  // Legacy answerQuestion action
  // It does support triggering the new action if the componentId and field are provided.
  // But the legacy action expects the value of the option and in the reducer
  // decides whether to use the value or deselect the option if the value received was
  // the same as before. This logic is repeated in two reducers and in that same action for
  // the Survey SDK and we're moving that logic here to have more simple reducers.
  dispatch(answerQuestion({
    ...params,
    shouldTriggerSurveyAPIEvents
  }));

  if (shouldOnlyDispatchLegacyAction) return;

  let finalValue;

  // Multiplechoice - Many
  if (field.isMultiValued) {
    finalValue = [...state.components[questionId].values[field.id]];

    if (hasAnswer) {
      pull(finalValue, value);
    } else {
      finalValue.push(value);
    }
  } else if (isMultiSelect) {
    // Multiplefields
    finalValue = hasAnswer ? offValue : value;
  } else {
    // Multiplechoice - One
    finalValue = hasAnswer ? null : value;
  }

  // New state management action
  dispatch(setComponentAnswer(finalValue, questionId, field.id));
};

export const longformOtherTextAnswerQuestion = params => (dispatch, getState) => {
  const state = getState();

  const { questionId, optionId } = params;
  const option = state.options[optionId];

  dispatch(answerQuestion({
    ...params,
    componentId: questionId,
    field: option.field
  }));
};

export default longformAnswerQuestion;
