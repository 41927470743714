import { NODE_TYPES } from '../../constants';
import multiValueFieldGenerator from '../multiValueField';
import multiConstantGenerator from '../multiConstant';

function multiValuedSetGenerator(field, values, op) {
  return {
    type: NODE_TYPES.MULTI_VALUED_SET,
    op,
    left: multiValueFieldGenerator(field),
    right: multiConstantGenerator(values)
  };
}

function multiValuedSetConditionStringGenerator(field, values, op) {
  return `${field} contains ${op} {${values.join(',')}}`;
}

export { multiValuedSetConditionStringGenerator };
export default multiValuedSetGenerator;
