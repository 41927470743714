import React from 'react';
import ReactDOM from 'react-dom';
import getAllBlocks from './utils/getAllBlocks';
import App from './components/App';
import parseBoolProperties from './utils/parseBoolProperties';
import QuestionNormalizer from './models/questionNormalizer';
import NavigationButtonsNormalizer from './models/navigationButtonsNormalizer';
import createStoreWithInitialState from './redux/store';
import TouchDetector from './models/touchDetector';
import MediaQueryWatcher from './models/mediaQueryWatcher';
import { getInitialState as getPageSettingsInitialState } from './redux/reducers/pageSettings';
import { getInitialState as getSurveyStatusInitialState } from './redux/reducers/surveyStatus';
import { getInitialState as getCookieBannerInitialState } from './redux/reducers/cookieBanner';
import { trackPageStats } from './services/telemetry';
import { defineTranslations } from './services/translations';
import { setFeatureFlags, isSurveyAPIEnabled } from './services/featureFlags';
import { surveyIsReady } from './services/api/events';
import { createProvider } from './services/surveyDataProvider';
import { setStore } from './services/api/surveyMethods';
import './services/conditionEngine/evaluators';
import IPCService from './services/inPageConditioning';
import FieldsService from './services/fields';
import ValidationsService from './services/validations';

const surveyData = parseBoolProperties(window.surveyData || {});
const questionBlocks = getAllBlocks(surveyData);
const questionNormalizer = new QuestionNormalizer();
const navButtonsNormalizer = new NavigationButtonsNormalizer();

// Deprecation pending: usage of surveyData variable. Start using surveyDataStore instead
let normalizedData = questionNormalizer.normalize({ rawData: surveyData, questionBlocks });
normalizedData = navButtonsNormalizer.normalize({ rawData: surveyData, normalizedData });
normalizedData.pageSettings = getPageSettingsInitialState(surveyData);
normalizedData.surveyStatus = getSurveyStatusInitialState();

// New store slices are created from survey data store **/
const surveyDataStore = createProvider(questionBlocks, surveyData.cookieNotice);
normalizedData.cookieBanner = getCookieBannerInitialState(surveyDataStore.cookieBanner);

const store = createStoreWithInitialState(normalizedData);
setFeatureFlags(window.surveyData);
defineTranslations(window.surveyData.translations);
IPCService
  .setStore(store)
  .setFieldsService(FieldsService);
FieldsService
  .setStore(store)
  .setIPCService(IPCService);
ValidationsService
  .setStore(store)
  .init(); 

if (process.env.NODE_ENV !== 'production') {
  const queryString = window.location.search;
  const a11yFlag = queryString.includes('a11y');
  if (a11yFlag) {
    const a11y = require('react-a11y');
    a11y(React, { ReactDOM });
  }
}

MediaQueryWatcher.watch(store);
const $survey = document.getElementById('survey');
TouchDetector.detectAndAddHasTouchClassNameToDomNode($survey);
ReactDOM.render(
  <App
    questionBlocks={questionBlocks}
    surveyData={surveyData}
    store={store}
  />, $survey, () => {
    trackPageStats();
    if (isSurveyAPIEnabled()) {
      setStore(store);
      surveyIsReady();
    }
  });
