import omit from 'lodash/omit';
import { QUESTION_TYPES } from '../../question';
import { HTML_INPUT_TYPES } from '../../htmlInput';
import ResourcesRelationshipManager from '../../resourcesRelationshipManager';
import { shouldValidatorPass } from '../normalizeHelpers';

import ValidationService from '../../../services/validations';
import { getTranslation } from '../../../services/translations';

export const MATCH_MULTIMEDIA_FEEDBACK = 'livinglens';

export const defaultValues = {
  uploadUrl: '',
  uploadId: '',
  mediaId: '',
  noAnswer: true,
  errorCode: ''
};

export const questionConfigDefaults = {
  caption: '',
  instructions: '',
  disclaimer: '',
  minLength: '',
  maxLength: '',
  dataUpload: false,
  minWidth: '0',
  maxWidth: '700',
  singleUse: true,
  disableBranding: true,
  widgetDomain: 'https://embed.livinglens.tv',
  widgetEnvironment: 'production',
  region: null
};

const VALIDATION_MESSAGE_FALLBACK = 'Please upload your recording.';
const VALIDATION_MESSAGE = getTranslation('LL_UPLOAD_RECORDING') || VALIDATION_MESSAGE_FALLBACK;

export default class MultimediaFeedbackLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return questionData.blockType === MATCH_MULTIMEDIA_FEEDBACK;
  }

  // eslint-disable-next-line class-methods-use-this
  getFieldValue(field, questionData) {
    // for the first version, mediaType will be fixed during config time
    // so the list of possible media types (questionData.mediaTypes) will
    // always have one element
    // later on, the ST will be able to switch between the available options
    if (field === 'mediaType') {
      return questionData.mediaTypes[0];
    }
    if (field === 'channelId') {
      return questionData.channelId;
    }

    const values = questionData.values || {};
    return values[field] || defaultValues[field];
  }

  // eslint-disable-next-line class-methods-use-this
  normalizeRegion(region) {
    // LL widget breaks when specifying US or EU regions, which have autodiscovery
    if ([null, 'US', 'us', 'EU', 'eu'].includes(region)) {
      return null;
    }

    // At the moment of writing this, there's only one region without autodiscovery
    // that must be specifically set in lowercase: AU
    // Doing this generically hoping that if a new region appears, same treatment applies
    return region.toLowerCase();
  }

  getQuestionConfig(questionData) {
    const questionConfig = omit(questionData, 'blockType', 'requiredField');

    Object.keys(questionConfigDefaults).forEach((key) => {
      // checking if it's null or undefined with ==
      if (questionConfig[key] == null) {
        questionConfig[key] = questionConfigDefaults[key];
      }
    });

    // Special treatment for the region
    questionConfig.region = this.normalizeRegion(questionConfig.region);

    return questionConfig;
  }

  // eslint-disable-next-line class-methods-use-this
  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const {
      fields
    } = questionData;

    resourcesManager.createOrUpdateQuestion({
      id,
      type: QUESTION_TYPES.MULTIMEDIA_FEEDBACK,
      ...this.getQuestionConfig(questionData)
    });

    Object.keys(fields).forEach((field) => {
      resourcesManager.createOrUpdateHtmlInput({
        id: fields[field],
        question: id,
        type: HTML_INPUT_TYPES.HIDDEN,
        value: this.getFieldValue(field, questionData)
      });
    });

    resourcesManager.createCondition(questionData.condition, id);

    resourcesManager.createComponent(id, {
      data: {
        mediaStatus: 'NONE'
      },
      validations: []
    });

    const validators = [
      {
        predicate: (componentId, store) => {
          const component = store.getState().components[componentId];
          if (component && component.data && component.data.mediaStatus === 'RECORDED') return false;
          return true;
        },
        shouldValidatorPass,
        severity: 'warning',
        retries: 2,
        message: VALIDATION_MESSAGE
      }
    ];

    ValidationService.subscribeComponent(id, validators);

    return resourcesManager.resources();
  }
}
