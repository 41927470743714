import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { testName } from "../../../utils/testUtils";
import Icon, {ICON_TYPES} from '../../../commons/Icon';
import styles from '../../../css/layout.scss';
import { HtmlBlock } from '../../HtmlBlock';
import { isWCAG21_2_Enabled } from '../../../services/featureFlags';
import { getTranslation } from '../../../services/translations';
import CookieBannerOpener from './CookieBannerOpener';

const footerClassNames = styles.layoutContainer + ' ' + styles.layoutContainer_fadeIn;

const {bool, string} = PropTypes;

const POWERED_BY_MEDALLIA = 'Powered by Medallia';

export default class PageFooter extends Component {

  static propTypes = {
    currentYear: string,
    customFooter: string,
    disableTechSupport: bool,
    techSupportLink: string,
    showContent: bool,
    cookieBannerEnabled: bool
  };

  static defaultProps = {
    showContent: false,
    cookieBannerEnabled: false
  };

  renderPoweredByMedallia() {
    let ariaLabel = 'medallia';

    if (isWCAG21_2_Enabled()) {
      ariaLabel = getTranslation('POWERED_BY_MEDALLIA') || POWERED_BY_MEDALLIA;
    }

    return <Icon containerStyles={styles.medalliaLogo} type={ICON_TYPES.POWERED_BY_MEDALLIA} ariaHidden ariaLabel={ariaLabel}/>;
  }

  render() {
    const {
      currentYear,
      customFooter,
      disableTechSupport,
      techSupportLink,
      showContent,
      cookieBannerEnabled
    } = this.props;

    if (!showContent && cookieBannerEnabled) {
      return (
        <footer className={footerClassNames}>
          <CookieBannerOpener standalone />
        </footer>
      );
    }

    if (!showContent) {
      return null;
    }

    return (
      <footer className={footerClassNames}>
        {customFooter ? (
          <div id="customfooter" className="customFooter">
            <HtmlBlock containerClassName={null} content={customFooter} />
            <CookieBannerOpener standalone />
          </div>
        ) : (
          <div className={styles.footer}>
            {this.renderPoweredByMedallia()}
            <div className={styles.legalLinks}>
              <span className="copyright">Copyright &copy; {currentYear}</span>
              {!disableTechSupport && (
                <a target="_blank"
                   rel="noopener noreferrer"
                   href={techSupportLink || 'http://surveysupport.medallia.com'}
                   {...testName('support', 'link')}
                >
                  {getTranslation('TECH_SUPPORT')}
                </a>
              )}
              <a href="http://www.medallia.com/contact"
                 target="_blank"
                 rel="noopener noreferrer external"
                 {...testName('contact', 'link')}
              >
                {getTranslation('OTHER_CONTACT')}
              </a>
              <a href="http://www.medallia.com/privacy"
                 target="_blank" rel="noopener noreferrer external"
                 {...testName('privacy-policy', 'link')}
              >
                {getTranslation('PRIVACY_POLICY')}
              </a>
              <CookieBannerOpener />
            </div>
          </div>
        )}
      </footer>
    );
  }
}
