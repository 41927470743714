import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import Input from '../../../commons/Input';
import ValidationMessage from '../../../commons/ValidationMessage';
import { getTranslation } from '../../../services/translations';
import styles from './otherOptionTextfield.scss';
import { isAdvancedAccessibilityEnabled } from '../../../services/featureFlags';
import classNames from 'classnames';

const { bool, func, number, object, string } = PropTypes;

class OtherOptionTextfield extends PureComponent {
  static propTypes = {
    id: string,
    ariaLabel: string,
    answerQuestion: func,
    containerStyles: string,
    htmlInput: string,
    isFocused: bool,
    question: number,
    textfieldInput: object,
    value: string,
    type: string,
    isHidden: bool,
  };

  static defaultProps = {
    textfieldInput: {},
    isHidden: false,
  };

  static contextTypes = {
    translations: object
  };

  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.debounceAnswerQuestion = this.debounceAnswerQuestion.bind(this);
    this.debounceAnswerQuestion = debounce(this.debounceAnswerQuestion, 300);
    this.state = { textfieldValue: props.value || '' };
  }

  debounceAnswerQuestion() {
    const { id, htmlInput, question, answerQuestion } = this.props;
    const value = this.state.textfieldValue;
    answerQuestion({ htmlInputId: htmlInput, optionId: id, questionId: question, value });
  }

  getValidationMessageProps(textfieldInput) {
    if (Boolean(textfieldInput.hasValidationFailure)) {
      return {
        caption: getTranslation('REQUIRED_FIELD_TOOLTIP'),
        containerStyles: styles.textfieldValidationError,
        isError: true
      };
    }
    if (isAdvancedAccessibilityEnabled()) {
      return {
        caption: getTranslation('REQUIRED_FIELD'),
        containerStyles: styles.textfieldValidationError,
        isError: false,
      };
    }
    return {
      caption: getTranslation('REQUIRED_FIELD_TOOLTIP'),
      ariaHidden: true,
      containerStyles: classNames(styles.textfieldValidationError, styles.textfieldValidationError_isHidden),
      isError: true
    };
  }

  handleOnChange(e) {
    this.setState({ textfieldValue: e.target.value }, this.debounceAnswerQuestion);
  }

  handleOnClick(e) {
    e.stopPropagation();
  }

  render() {
    const {
      id,
      ariaLabel,
      containerStyles,
      htmlInput,
      isFocused,
      textfieldInput,
      type,
      isHidden,
    } = this.props;


    return (
      <div aria-hidden={isHidden} className={containerStyles} onClick={this.handleOnClick}>
        <ValidationMessage {...this.getValidationMessageProps(textfieldInput)} />
        <Input
          domId={id}
          ariaLabel={ariaLabel}
          ariaRequired
          containerStyles={styles.input}
          formKey={htmlInput}
          formValue={this.state.textfieldValue}
          isFocused={isFocused}
          isTextfield={type === 'textfield'}
          isTextarea={type === 'textarea'}
          onChange={this.handleOnChange}
          disableMouseOutBlur
          tabIndex={isHidden ? -1 : null}
        />
      </div>
    );
  }
}

export default OtherOptionTextfield;
