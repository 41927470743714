import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '../../commons/withCondition/';
import answerQuestion from '../../redux/actions/answerQuestion';
import setComponentData from '../../redux/actions/setComponentData';
import MultimediaFeedback from './MultimediaFeedback';

export function mapStateToProps(state, { id }) {
  const questions = state.questions || {};
  const question = questions[id];
  if (!question) return {};

  const htmlInputs = state.htmlInputs || {};

  const htmlInputsWithValue = [...question.htmlInputs].reduce((obj, htmlInput) => {
    if (htmlInputs[htmlInput]) {
      obj[htmlInput] = htmlInputs[htmlInput].value; // eslint-disable-line no-param-reassign
    }

    return obj;
  }, {});

  const retries = get(state.components, [id, 'validations', [0], 'retries'], 0);

  return {
    ...question,
    htmlInputs: htmlInputsWithValue,
    retries
  };
}


const mapDispatchToProps = {
  answerQuestion,
  setComponentData
};

export default compose(
  withCondition,
  connect(mapStateToProps, mapDispatchToProps)
)(MultimediaFeedback);
