import PropTypes from 'prop-types';
import React from 'react';
import Icon, {ICON_TYPES} from '../Icon';
import classNames from 'classnames';
import styles from './indicatorCheckbox.scss';

export default function IndicatorCheckbox(props) {
  if (!props.shouldRender) return null;

  const {containerStyles, isSelected} = props;
  const mainContainerStyles = classNames(
    styles.indicatorCheckboxContainer,
    containerStyles,
    'indicatorCheckbox',
    {
      [styles.indicatorCheckboxContainer_isSelected]: isSelected
    }
  );
  const checkmarkStyles = classNames(styles.checkmark, {[styles.checkmark_isSelected]: isSelected});

  return (
    <div aria-hidden className={mainContainerStyles}>
      <Icon type={ICON_TYPES.CHECKBOX} containerStyles={checkmarkStyles}/>
    </div>
  );
}

const {bool, string} = PropTypes;

IndicatorCheckbox.propTypes = {
  containerStyles: string,
  isSelected: bool,
  shouldRender: bool
};

IndicatorCheckbox.defaultProps = {
  shouldRender: true
};
