import { compose } from 'redux';
import { connect } from 'react-redux';
import CookieBanner from './CookieBanner';
import { withData } from '../../services/surveyDataProvider';
import { toggleBanner } from '../../redux/actions/cookieBanner';

export const mapSurveyDataToProps = surveyData => ({
  enabled: Object.keys(surveyData.cookieBanner).length > 0
});

export const mapStateToProps = ({ cookieBanner }, { enabled }) => ({
  isOpen: enabled && cookieBanner.isOpen,
  consentDate: enabled ? cookieBanner.consentDate : '',
  fadeIn: enabled && !cookieBanner.opener
});

const actions = {
  onClose: toggleBanner
};

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps, actions),
);

export default composedConnect(CookieBanner);
