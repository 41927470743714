import PropTypes from 'prop-types';
import React from 'react';
import DropdownWrap from './DropdownWrap';
import styles from './Item.scss';

export const TEST_NAME_ITEM = 'ITEM';
export const TEST_NAME_DROPDOWN_WRAP = 'DROPDOWN_WRAP';
export const TEST_NAME_TEXT = 'TEXT';

export default function Item(props) {
  const {
    id,
    optionsLength,
    selected,
    setAriaLiveMessage,
    setItemRef,
    text,
    updatePositions
  } = props;

  const callSetItemRef = ref => setItemRef(id, ref);

  return (
    <div
      key={id}
      ref={callSetItemRef}
      className={styles.item}
      data-test-name={TEST_NAME_ITEM}
    >
      <span
        data-test-name={TEST_NAME_DROPDOWN_WRAP}
      >
        <DropdownWrap
          label={text}
          id={id}
          optionsLength={optionsLength}
          selected={selected}
          setAriaLiveMessage={setAriaLiveMessage}
          text={text}
          updatePositions={updatePositions}
        />
      </span>

      <div
        data-test-name={TEST_NAME_TEXT}
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

const {
  func,
  string,
  number,
  shape
} = PropTypes;

Item.propTypes = {
  id: string.isRequired,
  optionsLength: number.isRequired,
  selected: shape({ id: string, text: string }).isRequired,
  setAriaLiveMessage: func.isRequired,
  setItemRef: func.isRequired,
  text: string.isRequired,
  updatePositions: func.isRequired
};
