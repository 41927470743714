import { connect } from 'react-redux';
import { compose } from 'redux';
import IPCService from '../../services/inPageConditioning';
import withCondition from './withCondition';

// Although the component doesn't use anything from the state, it needs
// to be connected so that it's updated on store changes
export const mapStateToProps = (state, ownProps) => {
  const componentId = ownProps.id;

  const shouldShow = IPCService.shouldShowComponent(componentId);

  return {
    shouldShow
  };
};

export const withConditionContainer = connect(mapStateToProps);

export default compose(
  withConditionContainer,
  withCondition
);

