import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './navigationButtons.scss';
import layoutStyles from '../../css/layout.scss';
import Button, { BUTTON_STYLES } from '../../commons/Button';
import { isLivingLensUploadValidationEnabled, isA11yWfcEnhancementEnabled } from '../../services/featureFlags';

const {
  number, string, shape, bool
} = PropTypes;

const LAYOUT_STYLES = classNames(layoutStyles.layoutContainer, layoutStyles.layoutContainer_fadeIn);
const MOVE_FORWARD_CLASS = '_moveForwardButton_';
const MOVE_FORWARD_CLASSES = [BUTTON_STYLES.PRIMARY, MOVE_FORWARD_CLASS].join(' ');


class NavigationButtons extends Component {
  static propTypes = {
    beginButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    backButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    nextButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    finishButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    submitButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    saveButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    numOfButtons: number,
    moveForwardIsLoading: bool
  };

  static contextTypes = {
    onSubmit: PropTypes.func
  };

  render() {
    const {
      beginButton, backButton, nextButton, finishButton, submitButton, saveButton, numOfButtons, moveForwardIsLoading
    } = this.props;
    if (numOfButtons < 1 || numOfButtons > 3) return null;

    const navigationContainerStyles = classNames(styles.navigationButtonsContainer, {
      [styles.navigationButtonsContainer_hasOneButton]: numOfButtons === 1,
      [styles.navigationButtonsContainer_hasTwoOrThreeButtons]: numOfButtons >= 2
    });

    const onClickHandler = event => onSubmit(event);
    const onClickCallBack = { ...(isLivingLensUploadValidationEnabled() && { onClickCallBack: onClickHandler }) };

    const hasSpinner = { hasSpinner: moveForwardIsLoading };
    const { onSubmit } = this.context;
    const Container = isA11yWfcEnhancementEnabled()? 'div' : 'nav';

    return (
      <Container className={LAYOUT_STYLES}>
        {submitButton && (
          <Button
            {...submitButton}
            styling={BUTTON_STYLES.TERTIARY}
            showArrowIcon
            {...onClickCallBack}
          />
        )}
        <div className={navigationContainerStyles}>
          {finishButton && (
            <Button
              {...finishButton}
              containerStyles={styles.pageButtonContainer}
              styling={MOVE_FORWARD_CLASSES}
              showArrowIcon
              {...hasSpinner}
              {...onClickCallBack}
            />
          )}
          {nextButton &&
            <Button
              {...nextButton}
              containerStyles={styles.pageButtonContainer}
              styling={MOVE_FORWARD_CLASSES}
              showArrowIcon
              {...hasSpinner}
              {...onClickCallBack}
            />
          }
          {saveButton &&
            <Button
              {...saveButton}
              containerStyles={styles.pageButtonContainer}
              styling={MOVE_FORWARD_CLASSES}
              {...hasSpinner}
              {...onClickCallBack}
            />
          }
          {beginButton && (
            <Button
              {...beginButton}
              containerStyles={styles.pageButtonContainer}
              showArrowIcon
              styling={MOVE_FORWARD_CLASSES}
              {...hasSpinner}
              {...onClickCallBack}
            />
          )}
          {backButton && (
            <Button
              {...backButton}
              containerStyles={styles.pageButtonContainer}
              styling={BUTTON_STYLES.SECONDARY}
              showArrowIcon={false}
              {...onClickCallBack}
            />
          )}
        </div>
      </Container>
    );
  }
}

export default NavigationButtons;
