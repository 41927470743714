import { compose } from 'redux';
import { connect } from 'react-redux';
import TextArea from './TextArea';
import withCondition from '../../commons/withCondition';
import setComponentAnswer from '../../redux/actions/setComponentAnswer';

export const mapDispatchToProps = (dispatch, { fieldName, id }) => ({
  setComponentAnswer: (value, extraParams = {}) =>
    dispatch(setComponentAnswer(value, id, fieldName, extraParams))
});

export default compose(
  withCondition,
  connect(null, mapDispatchToProps)
)(TextArea);
