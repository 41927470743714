import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { testName } from "../../../utils/testUtils";
import Table from '../../../commons/Table';
import Row from '../../../commons/Table/Row';
import Cell from '../../../commons/Table/Cell';
import IndicatorRadio from '../../../commons/IndicatorRadio';
import IndicatorCheckbox from '../../../commons/IndicatorCheckbox';
import Input from '../../../commons/Input';
import Tooltip from '../../../commons/Tooltip';
import OtherOptionTextfield from '../OtherOptionTextfield';
import stripHtml from '../../../utils/stripHtml';
import styles from './option.scss';
import layoutStyles from '../../../css/layout.scss';
import {
  isAdvancedAccessibilityEnabled,
  isAddingSelectedClassEnabled,
  isWCAG21_2_Enabled,
} from '../../../services/featureFlags';

const JAWS_CLICK_TARGET_ID = 'JAWS_CLICK_TARGET';
const JAWS_CLICK_TARGET_CLASS = 'jaws-click-target';

const {
  any,
  bool,
  func,
  instanceOf,
  number,
  string
} = PropTypes;


const Wrapper = ({ children, isHorizontal }) => (
  isHorizontal ? <Cell containerStyles={styles.selectionUnit}>{children}</Cell> : children
);

class Option extends PureComponent {
  static propTypes = {
    id: any,
    answerQuestion: func.isRequired,
    ariaLabel: string,
    ariaPosInSet: number,
    ariaRequired: bool,
    ariaSetSize: number,
    caption: string,
    containerStyles: string,
    hasAnswer: bool,
    hasOtherOption: bool,
    otherOptionType: string,
    hasTooltipChild: bool,
    shouldShowDescribedBy: bool,
    htmlInput: string,
    htmlInputFormKey: string,
    isMultiSelect: bool,
    isBazaarVoiceTerms: bool,
    isHorizontal: bool,
    isSubComponent: bool,
    onFocus: func,
    options: instanceOf(Set),
    isRequired: bool,
    parent: string,
    question: any,
    tooltipAriaLabel: string,
    tooltipCaption: string,
    tooltipImagePath: string,
    value: any
  };

  static defaultProps = {
    options: new Set(),
    isHorizontal: false,
    isRequired: false,
    isSubComponent: false,
    hasTooltipChild: false,
    shouldShowDescribedBy: true,
    hasOtherOption: false
  };

  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
    this.debouncedOnClickForIE9PreventDefaultBug = debounce(this.clickHandler, 30);
    this.handleOnClick = (e) => {
      e.persist();
      this.debouncedOnClickForIE9PreventDefaultBug(e);
    };

    this.handleShowBoxShadow = this.handleShowBoxShadow.bind(this);
    this.handleHideBoxShadow = this.handleHideBoxShadow.bind(this);
    this.handleShowTooltip = this.handleShowTooltip.bind(this);
    this.handleHideTooltip = this.handleHideTooltip.bind(this);
    this.handleInputOnFocusForTooltipAndBoxShadow = this.handleInputOnFocusForTooltipAndBoxShadow.bind(this);
    this.handleInputOnBlurForTooltipAndBoxShadow = this.handleInputOnBlurForTooltipAndBoxShadow.bind(this);
    this.setRefForTooltip = this.setRefForTooltip.bind(this);
    this.getTooltipId = this.getTooltipId.bind(this);
    this.getAriaDescribedBy = this.getAriaDescribedBy.bind(this);
    this.onOtherFieldBlur = this.onOtherFieldBlur.bind(this);
    this.isJawsClickTarget = this.isJawsClickTarget.bind(this);

    this.state = {
      hasBoxShadow: false,
      isOtherFieldFocused: false,
    };
  }

  handleShowBoxShadow() {
    if (this.state.hasBoxShadow) return;
    this.setState({ hasBoxShadow: true });
  }

  handleHideBoxShadow() {
    if (!this.state.hasBoxShadow) return;
    this.setState({ hasBoxShadow: false });
  }

  isJawsClickTarget(target) {
    return (target && (
      isWCAG21_2_Enabled() ?
        target.getAttribute('class').includes(JAWS_CLICK_TARGET_CLASS) :
        target.id === JAWS_CLICK_TARGET_ID
    ));
  }

  clickHandler(event) {
    const { type, clientY, clientX, target } = event;
    // This handler is also triggered when you check the option using the keyboard
    // as a native behavior of the browser
    const isClick = type === 'click' && clientX !== 0
      && clientY !== 0
      // Fix for pressing space using JAWS screen reader
      // JAWS simulates a click event when selecting elements in a form
      && !this.isJawsClickTarget(target);

    const { id, htmlInput, question, value, answerQuestion, hasAnswer, hasOtherOption } = this.props;
    answerQuestion({ htmlInputId: htmlInput, optionId: id, questionId: question, value });
    if (isClick && hasOtherOption) {
      this.setState({ isOtherFieldFocused: !hasAnswer });
    }
  }

  handleShowTooltip() {
    if (this._tooltipComponent) this._tooltipComponent.showPopup();
  }

  handleHideTooltip() {
    if (this._tooltipComponent) this._tooltipComponent.hidePopup();
  }

  handleInputOnFocusForTooltipAndBoxShadow() {
    if (this.props.onFocus) this.props.onFocus();
    this.handleShowTooltip();
    this.handleShowBoxShadow();
  }

  handleInputOnBlurForTooltipAndBoxShadow() {
    this.handleHideTooltip();
    this.handleHideBoxShadow();
  }

  setRefForTooltip(tooltipComponent) {
    this._tooltipComponent = tooltipComponent;
  }

  determineIndicator(isMultiSelect) {
    if (isMultiSelect) return IndicatorCheckbox;
    return IndicatorRadio;
  }

  onOtherFieldBlur() {
    this.setState({ isOtherFieldFocused: false });
  }

  getTooltipId() {
    return `${this.props.id}_tooltip`;
  }

  getAriaDescribedBy() {
    const { hasTooltipChild, parent, shouldShowDescribedBy, describedById } = this.props;
    if (!shouldShowDescribedBy) return;
    if (describedById) {
      return describedById;
    }
    return hasTooltipChild ? `${parent} ${this.getTooltipId()}` : `${parent}`;
  }

  renderOtherOption = (inputId) => {
    const { isOtherFieldFocused } = this.state;
    const {
      ariaLabel, hasAnswer, hasOtherOption, isHorizontal, otherOptionType
    } = this.props;

    if (!hasOtherOption) {
      return null;
    }

    const otherOptionTextfieldStyles = classNames(styles.otherOptionTextfield, {
      [styles.otherOptionTextfield_isHidden]: !hasAnswer
    });

    const otherOptionTextfield = (
      <OtherOptionTextfield
        id={inputId}
        ariaLabel={ariaLabel}
        containerStyles={otherOptionTextfieldStyles}
        type={otherOptionType}
        isFocused={isOtherFieldFocused}
        isHidden={!hasAnswer}
        onBlur={this.onOtherFieldBlur}
      />
    );

    if (isHorizontal) {
      return hasAnswer ? (
        <Row>
          <Cell containerStyles={styles.textfieldContainer}>
            {otherOptionTextfield}
          </Cell>
        </Row>
      ) : null;
    }

    return (
      <Row>
        <Cell />
        <Cell containerStyles={styles.textfieldContainer}>
          {otherOptionTextfield}
        </Cell>
      </Row>
    );
  }

  render() {
    const {
      ariaLabel,
      ariaRequired,
      caption,
      containerStyles,
      hasAnswer,
      hasTooltipChild,
      htmlInputFormKey,
      isMultiSelect,
      options,
      tooltipAriaLabel,
      tooltipCaption,
      tooltipImagePath,
      value,
      isBazaarVoiceTerms,
      isSubComponent,
      isHorizontal
    } = this.props;

    const { hasBoxShadow } = this.state;

    const htmlInput = htmlInputFormKey || this.props.htmlInput; // for chooseMany

    const mainContainerStyles = classNames(
      containerStyles,
      layoutStyles.answer,
      {
        [`${styles.bazaarVoiceOption}`]: isBazaarVoiceTerms,
        [`${styles.inSubComponent}`]: isSubComponent,
        answer_isSelected: isAddingSelectedClassEnabled() && hasAnswer
      }
    );

    const Indicator = this.determineIndicator(isMultiSelect);

    const optionContainerStyles = classNames(
      styles.optionContainer,
      {
        [styles.optionContainer_hasBoxShadow]: hasBoxShadow && !isBazaarVoiceTerms,
        [JAWS_CLICK_TARGET_CLASS]: isWCAG21_2_Enabled()
      }
    );

    const inputId = options.values().next().value;

    const tableId = {
      id: isWCAG21_2_Enabled() ? null : JAWS_CLICK_TARGET_ID
    };

    return (
      <Wrapper isHorizontal={isHorizontal}>
        <div className={mainContainerStyles} {...testName(stripHtml(caption), 'option')}>
          <Table
            {...tableId}
            containerStyles={optionContainerStyles}
            onMouseOver={this.handleShowBoxShadow}
            onMouseOut={this.handleHideBoxShadow}
            onFocus={this.handleShowBoxShadow}
            onBlur={this.handleHideBoxShadow}
            onClick={this.handleOnClick}
            hasFixedLayoutStyle={!isHorizontal}
          >
            <Row>
              <Cell containerStyles={isHorizontal ? styles.indicatorContainerHorizontal : styles.indicatorContainer}>
                {htmlInput && (
                  <Input
                    ariaLabel={ariaLabel}
                    ariaRequired={ariaRequired}
                    ariaDescribedBy={this.getAriaDescribedBy()}
                    checked={hasAnswer}
                    containerStyles={styles.input}
                    formKey={htmlInput}
                    formValue={value}
                    isCheckbox={isMultiSelect}
                    isRadio={!isMultiSelect}
                    onBlur={this.handleInputOnBlurForTooltipAndBoxShadow}
                    onMouseLeave={this.handleHideTooltip}
                    onClick={this.handleOnClick}
                    onFocus={this.handleInputOnFocusForTooltipAndBoxShadow}
                    onKeyUp={this.handleShowTooltip}
                  />
                )}
                <Indicator containerStyles={styles.indicator} isSelected={hasAnswer}/>
              </Cell>
              {!isHorizontal && (
                <Cell containerStyles={styles.captionContainer}>
                  { isAdvancedAccessibilityEnabled() ?
                    <label htmlFor={inputId} aria-hidden className={styles.caption} dangerouslySetInnerHTML={{__html: caption}}/> :
                    <span aria-hidden className={styles.caption} dangerouslySetInnerHTML={{__html: caption}}/> }
                  {hasTooltipChild && (
                    <Tooltip
                      ariaHidden
                      ref={this.setRefForTooltip}
                      domId={this.getTooltipId()}
                      ariaLabel={tooltipAriaLabel}
                      caption={tooltipCaption}
                      imagePath={tooltipImagePath}
                    />
                  )}
                </Cell>)
              }
            </Row>
            {this.renderOtherOption(inputId)}
          </Table>
        </div>
      </Wrapper>
    );
  }
}

export default Option;
