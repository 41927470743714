import stripHtml from '../../utils/stripHtml';

const getMultiValuedOpenerContent = (texts) => {
  if (texts.length > 2) {
    const [first, second] = texts;

    return `${first}, ${second}, +${texts.length - 2} more`;
  }

  return texts.join(', ');
};

export const getOpenerContent = (props) => {
  const { disabled, isMultiValued, selected } = props;

  if (disabled) {
    return null;
  }

  if (isMultiValued) {
    if (Array.isArray(selected)) {
      return selected.length > 0 &&
        getMultiValuedOpenerContent(selected.map(item => stripHtml(item.text)));
    }
  }

  return selected && stripHtml(selected.text);
};

export const isItemSelected = ({ itemId, isMultiValued, selected }) => {
  if (isMultiValued) {
    return Array.isArray(selected) && selected.find(item => item.id === itemId) !== undefined;
  }

  return selected && itemId === selected.id;
};
