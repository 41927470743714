import PropTypes from 'prop-types';
import React from 'react';
import Icon, { ICON_TYPES } from '../../Icon';
import stripHtml from '../../../utils/stripHtml';
import styles from '../dropdown.scss';
import { testName } from "../../../utils/testUtils";
import { itemShape } from '../types';
import IndicatorCheckbox from '../../IndicatorCheckbox';
import Row from '../../../commons/Table/Row';
import Cell from '../../../commons/Table/Cell';

function MobileItem(props) {
  const {
    selectItem,
    isMultiValued,
    item,
    selected
  } = props;

  function select() {
    selectItem(item);
  }

  const renderContent = () => {
    const optionText = stripHtml(item.text);

    return isMultiValued ? (
      <Row>
        <Cell containerStyles={styles.dropdownListItemMultiValuedCheckboxCell}>
          <IndicatorCheckbox isSelected={selected} />
        </Cell>
        <Cell>
          {optionText}
        </Cell>
      </Row>
    ) : (
      <span>
        {optionText}
        {selected && <Icon type={ICON_TYPES.CHECKBOX} containerStyles={styles.dropdownIcon} />}
      </span>
    );
  };

  return (
    <li
      role="menuitem"
      tabIndex="-1"
      className={styles.dropdownListItem}
      onClick={select}
      {...testName(stripHtml(item.text), 'option')}
    >
      {renderContent()}
    </li>
  );
}

MobileItem.propTypes = {
  isMultiValued: PropTypes.bool.isRequired,
  item: itemShape.isRequired,
  selected: PropTypes.bool.isRequired,
  selectItem: PropTypes.func.isRequired
};

export default MobileItem;
