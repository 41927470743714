import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import styles from './subComponentCell.scss';
import SingleDropdown from '../../SingleDropdown';
import RatingGrid from '../../RatingGrid';
import Longform from '../../Longform';
import TextField from '../../TextField';
import TextArea from '../../TextArea';

class SubComponentCell extends PureComponent {
  getSubComponent() {
    const { component, isMobileMedia } = this.props;
    const {
      caption, blockType, blockId, ...componentData
    } = component;

    switch (blockType) {
      case 'dropdown':
        return (
          <SingleDropdown
            alt={component.alt}
            formkey={component.formkey}
            id={blockId}
            isMultiValued={component.multivalued}
            isSubComponent
          />
        );
      case 'grid': return isMobileMedia && !componentData.isNumeric ? <Longform id={blockId} isSubComponent /> : <RatingGrid id={blockId} isSubComponent />;
      case 'longform': return <Longform id={blockId} isSubComponent isHorizontal={!isMobileMedia} />;
      case 'choose': return <Longform id={blockId} isSubComponent isHorizontal={!isMobileMedia} />;
      case 'textfield': return <TextField id={blockId} {...componentData} isSubComponent />;
      case 'textarea': return <TextArea id={blockId} {...componentData} isMobileMedia={isMobileMedia} isInMatrix />;
      default: return null;
    }
  }

  render() {
    const { component, isMobileMedia, columnCaption, rowCaption, columnIndex, questionCaption } = this.props;
    const subComponentStyles = styles[component.blockType] || '';
    const componentStyles = classNames(subComponentStyles, {
      [styles.mobileSubComponentCell]: isMobileMedia
    });

    return (
      <div className={componentStyles} data-test-id={`cell_${component.blockId}`}>
        <fieldset role={'group'} aria-label={`${questionCaption} ${rowCaption} ${columnCaption}`}>
          {isMobileMedia && <div className={styles.mobileColumnCaption}>{columnCaption}</div>}
          {this.getSubComponent()}
        </fieldset>
      </div>
    );
  }
}
const {
  string,
  number,
  shape,
  func,
  bool
} = PropTypes;

SubComponentCell.propTypes = {
  id: string.isRequired,
  questionId: number.isRequired,
  component: shape({
    blockType: string
  }).isRequired,
  answerQuestion: func.isRequired,
  columnCaption: string,
  isMobileMedia: bool
};

SubComponentCell.defaultProps = {
  columnCaption: '',
  isMobileMedia: false
};

export default SubComponentCell;
