import { connect } from 'react-redux';
import answerQuestion from '../actions';
import CellOption from './CellOption';

/**
 * This helper function is used to determine whether the current cell being
 * evaluated has the answer or is before the cell that has the answer.  This
 * "before" case is specifically for IndicatorStar support.
 */
function shouldDisplayAsSelectedHelper(id, options, parentOptions) {
  if (options[id].isNaOption) {
    return false;
  }

  const optionsData = Array.from(parentOptions).map(optionId => options[optionId]);

  const selectedIndex = optionsData.findIndex(option => !option.isNaOption && option.hasAnswer);
  if (selectedIndex < 0) {
    return false;
  }

  const optionIndex = optionsData.findIndex(option => !option.isNaOption && id === option.id);

  return optionIndex <= selectedIndex;
}

function mapStateToProps(state, ownProps) {
  if (!ownProps.id || !state.options || !state.options[ownProps.id]) {
    return {};
  }

  const option = state.options[ownProps.id];

  if (!option.parent) {
    return {};
  }

  const parent = state.options[option.parent];

  const shouldDisplayAsSelected = option.hasAnswer || (
    ownProps.showStars &&
    shouldDisplayAsSelectedHelper(ownProps.id, state.options, parent.options)
  );

  return {
    ariaLabel: option.ariaLabel,
    ariaPosInSet: option.ariaPosInSet,
    ariaSetSize: option.ariaSetSize,
    caption: option.caption,
    hasAnswer: option.hasAnswer,
    htmlInput: option.htmlInput,
    htmlInputFormKey: state.htmlInputs[option.htmlInput].formKey,
    isNaOption: option.isNaOption,
    // TODO: Remove this property when
    // isAdvancedAccessibility is set to true in all instances
    isRequired: option.isRequired,
    question: option.question,
    value: option.value,
    shouldDisplayAsSelected,
    optionsSize: ownProps.optionsSize
  };
}

const mapDispatchToProps = { answerQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(CellOption);
