import setComponentAnswer from '../../redux/actions/setComponentAnswer';
import {
  getAllOptionIds,
  getAllOptionIdsFilteredByIPC,
  getSelectedOptionIds,
  getSelectedOptionIdsFilteredByIPC
} from './selectors';
import { setAlternativeAt } from './setAlternativeAt';

export const setAlternativeAtFactory = (dispatch, ownProps) =>
  (alternativeId, targetIndexInFilteredList) => dispatch((innerDispatch, getState) => {
    const state = getState();

    const filteredList = getSelectedOptionIdsFilteredByIPC(state, ownProps);
    const unfilteredList = getSelectedOptionIds(state, ownProps);
    const reorderedList = setAlternativeAt(
      alternativeId,
      targetIndexInFilteredList,
      filteredList,
      unfilteredList
    );

    dispatch(setComponentAnswer(reorderedList, ownProps.id, ownProps.fieldName));
  });

export const firstSelectionOnDnDFactory = (dispatch, ownProps) =>
  (alternativeId, targetIndexInFilteredList) => dispatch((innerDispatch, getState) => {
    const state = getState();

    // In DnD mode, when there are no selections, we need to use the full list of items
    const unfilteredList = getAllOptionIds(state, ownProps);
    const filteredList = getAllOptionIdsFilteredByIPC(state, ownProps);

    const reorderedList = setAlternativeAt(
      alternativeId,
      targetIndexInFilteredList,
      filteredList,
      unfilteredList
    );

    dispatch(setComponentAnswer(reorderedList, ownProps.id, ownProps.fieldName));
  });

export const unselectAlternativeFactory = (dispatch, ownProps) =>
  alternativeId => dispatch((innerDispatch, getState) => {
    const unfilteredList = getSelectedOptionIds(getState(), ownProps);
    const initialRowIndexInUnfilteredList = unfilteredList.indexOf(alternativeId);

    const reorderedList = [...unfilteredList];
    reorderedList[initialRowIndexInUnfilteredList] = null;

    dispatch(setComponentAnswer(reorderedList, ownProps.id, ownProps.fieldName));
  });
