export const SET_COMPONENT_VALIDATION = 'SET_COMPONENT_VALIDATION';

const setComponentValidation = (componentId, validationId, newValue) =>
  (dispatch) => {
    dispatch({
      type: SET_COMPONENT_VALIDATION,
      payload: {
        componentId,
        validationId,
        newValue
      }
    });
  };

export default setComponentValidation;
