import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import styles from './cookieBanner.scss';
import MainPanel from './MainPanel';
import SecondaryPanel from './SecondaryPanel';
import Header from './Header';
import layoutStyles from '../../css/layout.scss';
import { ARIA_DESCRIPTION_ID, ARIA_TITLE_ID } from './constants';

const { func, bool } = PropTypes;
const KEY_TAB = 9;
const KEY_ESCAPE = 27;

class CookieBanner extends React.PureComponent {
  static propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    fadeIn: bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSecondaryPanel: null
    };

    this.containerRef = null;

    this.getFocusableElements = this.getFocusableElements.bind(this);
    this.closeSecondaryPanel = this.closeSecondaryPanel.bind(this);
    this.openSecondaryPanel = this.openSecondaryPanel.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  getFocusableElements() {
    // eslint-disable-next-line react/no-find-dom-node
    const container = ReactDOM.findDOMNode(this.containerRef);
    if (!container) {
      return [];
    }
    const focusable = container.querySelectorAll('a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    return [...focusable];
  }

  componentDidUpdate() {
    if (!this.props.isOpen) {
      return;
    }

    const focusable = this.getFocusableElements();

    if (!focusable.includes(document.activeElement) && focusable.length) {
      focusable[0].focus();
    }
  }

  componentDidMount() {
    if (!this.props.isOpen) {
      return;
    }

    const focusable = this.getFocusableElements();
    focusable[0].focus();
  }

  closeSecondaryPanel() {
    this.setState({
      activeSecondaryPanel: null
    });
  }

  openSecondaryPanel(panel) {
    this.setState({
      activeSecondaryPanel: panel
    });
  }

  handleBackwardTab(e) {
    const focusable = this.getFocusableElements();
    const first = focusable[0];
    if (document.activeElement === first) {
      const last = focusable[focusable.length - 1];
      e.preventDefault();
      last.focus();
    }
  }

  handleForwardTab(e) {
    const focusable = this.getFocusableElements();
    const last = focusable[focusable.length - 1];
    if (document.activeElement === last) {
      const first = focusable[0];
      e.preventDefault();
      first.focus();
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === KEY_ESCAPE) {
      this.props.onClose();
    }
    if (e.keyCode !== KEY_TAB) {
      return;
    }

    if (e.shiftKey) {
      this.handleBackwardTab(e);
      return;
    }
    this.handleForwardTab(e);
  }

  render() {
    const { activeSecondaryPanel } = this.state;
    const showBackButton = activeSecondaryPanel !== null;
    const { isOpen, onClose, fadeIn } = this.props;

    if (!isOpen) {
      return null;
    }

    const containerClasses = classNames(styles.container, {
      [layoutStyles.layoutContainer_fadeIn]: fadeIn
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div
        className={styles.wrapper}
        role="dialog"
        aria-modal="true"
        aria-labelledby={ARIA_TITLE_ID}
        aria-describedby={ARIA_DESCRIPTION_ID}
        onKeyDown={this.handleKeyDown}
        ref={(el) => { this.containerRef = el; }}
      >
        <div className={containerClasses}>
          <div className={styles.header}>
            <Header
              onBack={this.closeSecondaryPanel}
              onClose={onClose}
              showBackButton={showBackButton}
            />
          </div>
          <div className={styles.body}>
            {activeSecondaryPanel ?
              <SecondaryPanel
                onBack={this.closeSecondaryPanel}
                panel={activeSecondaryPanel}
              /> :
              <MainPanel openSecondaryPanel={this.openSecondaryPanel} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CookieBanner;
