import PropTypes from 'prop-types';
import React from 'react';
import styles from './description.scss';
import { ARIA_DESCRIPTION_ID } from '../../constants';

const Description = ({ text }) => (
  <p
    className={styles.description}
    id={ARIA_DESCRIPTION_ID}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

Description.propTypes = {
  text: PropTypes.string.isRequired
};

export default Description;
