import evaluateNode from '../..';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function eqEvaluator(node, values) {
  const { left, right } = node;

  return evaluateNode(left, values) === evaluateNode(right, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.EQ, eqEvaluator);
export default eqEvaluator;
