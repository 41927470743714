import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import LivingLensService, { ERROR_CODES } from '../../services/LivingLens';

export default class LivingLensV1 extends Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    channelId: PropTypes.string.isRequired,
    respondentId: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    mediaTypes: PropTypes.arrayOf(PropTypes.oneOf([
      'video',
      'audio',
      'image'
    ])).isRequired,
    mediaCountryCode: PropTypes.string.isRequired,
    mediaLanguageCode: PropTypes.string.isRequired,
    mediaMinLength: PropTypes.string,
    mediaMaxLength: PropTypes.string,
    interfaceLanguageCode: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
    allowFileUpload: PropTypes.bool,
    singleUseMode: PropTypes.bool,
    disableBranding: PropTypes.bool,
    widgetDomain: PropTypes.string,
    widgetEnvironment: PropTypes.string,
    region: PropTypes.string,
    onUploadSuccess: PropTypes.func.isRequired,
    onRecordComplete: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    mediaMinLength: '',
    mediaMaxLength: '',
    allowFileUpload: false,
    maxWidth: '700',
    singleUseMode: true,
    disableBranding: true,
    region: null,
  };

  constructor(props) {
    super(props);

    this.livingLensContainer = `multimedia-${this.props.questionId}`;
    this.mediaCaptureCallbacks = this.mediaCaptureCallbacks.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.LivingLensService = new LivingLensService(this.props.widgetDomain, this.handleErrors);
  }

  mediaCaptureCallbacks(mediaCapture) {
    mediaCapture.onEvent('uploadSuccessful', this.props.onUploadSuccess);
    mediaCapture.onEvent('recordingStopped', this.props.onRecordComplete);
    mediaCapture.onEvent('uploadFailed', () => this.props.onError(ERROR_CODES.UPLOAD_FAILED));
    mediaCapture.onEvent('stalledUploadDetected', () => this.props.onError(ERROR_CODES.UPLOAD_STALLED));
    mediaCapture.onEvent('noVideoCaptureDeviceDetected', () => this.props.onError(ERROR_CODES.NO_RECORDING_DEVICE));
    mediaCapture.onEvent('noAudioCaptureDetected', () => this.props.onError(ERROR_CODES.NO_RECORDING_DEVICE));
    mediaCapture.onEvent('deviceNotSupported', () => this.props.onError(ERROR_CODES.NO_RECORDING_DEVICE));
    mediaCapture.onEvent('accessDenied', () => this.props.onError(ERROR_CODES.NO_RECORDING_DEVICE));
  }

  handleErrors(errorCode) {
    this.props.onError(errorCode);
  }

  componentDidMount() {
    const LivingLens = this.LivingLensService.getInstance();
    if (isFunction(LivingLens.startMedalliaMediaCapture)) {
      LivingLens.startMedalliaMediaCapture({
        target: this.livingLensContainer,
        callback: this.mediaCaptureCallbacks,
        filters: [{
          group: 'mec_ballot_id',
          filters: [this.props.respondentId]
        }, {
          group: 'mec_question_id',
          filters: [this.props.questionId]
        }],
        environment: this.props.widgetEnvironment
      });
    } else {
      this.props.onError(ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      apiKey,
      channelId,
      respondentId,
      questionId,
      mediaTypes,
      mediaCountryCode,
      mediaLanguageCode,
      mediaMinLength,
      mediaMaxLength,
      interfaceLanguageCode,
      maxWidth,
      allowFileUpload,
      singleUseMode,
      disableBranding,
      widgetEnvironment,
      region,
    } = this.props;

    const reloadOnComplete = singleUseMode ? {} : {'data-reload-on-complete': true };
    const dataRegion = region ? { 'data-channel-region': region } : {};

    return (
      <div data-livinglens-container={this.livingLensContainer}
           data-api-key={apiKey}
           data-channel-id={channelId}
           data-respondent-id={respondentId}
           data-question-id={questionId}
           data-capture-types={mediaTypes.join(',')}
           data-media-min-length={mediaMinLength}
           data-media-max-length={mediaMaxLength}
           data-country-code={mediaCountryCode}
           data-language-code={mediaLanguageCode}
           data-interface-language-code={interfaceLanguageCode}
           data-size-max-width={maxWidth}
           data-allow-uploads={allowFileUpload}
           data-disable-branding={disableBranding}
           data-channel-environment={widgetEnvironment}
           {...dataRegion}
           {...reloadOnComplete}
      />
    );
  }
}
