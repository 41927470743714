/* eslint-disable react/prefer-stateless-function */

import PropTypes from 'prop-types';

import React from 'react';
import { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

import SelectItem from './SelectItem';
import { selectPropTypes } from './utils';
import styles from './SelectMenuList.scss';

const ITEM_SIZE = 43;

class SelectMenuList extends React.Component {
  //----------------------------------------------------------------------
  // LIFECYCLE
  //----------------------------------------------------------------------
  componentDidMount() {
    const { selectedOptionIndex } = this.props.selectProps;
    // This was done interely by trial and error and `5` is a magic number.
    const scrollTargetIndex = selectedOptionIndex + 5;

    if (!isNil(this._$listRef)) {
      // TODO: This is WIP for OA-26527.
      // this._$listRef.scrollToItem(scrollTargetIndex);
    }
  }

  shouldComponentUpdate(prevProps) {
    const { focusedOptionIndex, options, isLoading } = this.props.selectProps;

    return (
      !isEqual(prevProps.selectProps.focusedOptionIndex, focusedOptionIndex) ||
      !isEqual(prevProps.selectProps.options, options) ||
      prevProps.isLoading !== isLoading
    );
  }

  componentDidUpdate(prevProps) {
    const { focusedOptionIndex } = this.props.selectProps;

    if (
      !isNil(this._$listRef) &&
      prevProps.selectProps.focusedOptionIndex !== focusedOptionIndex
    ) {
      this._$listRef.scrollToItem(focusedOptionIndex);
    }
  }

  //----------------------------------------------------------------------
  // UTILS
  //----------------------------------------------------------------------

  setListRef = (listRef) => {
    this._$listRef = listRef;
  };

  getListSize = () => {
    const itemCount = this.props.options.length;

    if (itemCount < 10) {
      return itemCount * ITEM_SIZE;
    }

    return 300;
  };

  //----------------------------------------------------------------------
  // RENDER
  //----------------------------------------------------------------------

  render() {
    const {
      selectProps, isLoading, options, noOptionsMessage
    } = this.props;

    if (isLoading) {
      return (
        <components.LoadingMessage {...this.props}>
          {selectProps.loadingMessage()}
        </components.LoadingMessage>
      );
    }

    if (options.length === 0) {
      return (
        <components.NoOptionsMessage {...this.props}>
          {noOptionsMessage}
        </components.NoOptionsMessage>
      );
    }

    return (
      <components.MenuList {...this.props} className={styles.container}>
        <List
          height={this.getListSize()}
          itemCount={this.props.options.length}
          itemSize={ITEM_SIZE}
          itemData={this.props.children}
          width="100%"
          ref={this.setListRef}
        >
          {SelectItem}
        </List>
      </components.MenuList>
    );
  }
}

SelectMenuList.propTypes = {
  selectProps: PropTypes.shape({
    selectedOptionIndex: PropTypes.number.isRequired,
    focusedOptionIndex: PropTypes.number.isRequired,
    options: selectPropTypes.options,
    isLoading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.func.isRequired
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: selectPropTypes.options,
  noOptionsMessage: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default SelectMenuList;
