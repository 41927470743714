import isNil from 'lodash/isNil';
import get from 'lodash/get';
import evaluatorRegistry from '../registry';
import { NODE_TYPES } from '../../constants';

function fieldEvaluator(node, values) {
  const value = values[node.field];

  return isNil(value) ? null : `${value}`;
}

export function numericValueFieldEvaluator(node, values, alternatives) {
  const altSeqNumber = fieldEvaluator(node, values);
  const alt = alternatives.find(alternative => alternative.identifier === altSeqNumber);
  return get(alt, 'numeric_value', null);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.FIELD, fieldEvaluator);
export default fieldEvaluator;
