import PropTypes from 'prop-types';
import React from 'react';
import Cell from '../../../commons/Table/Cell';
import classNames from 'classnames';
import { isAddingSelectedClassEnabled } from '../../../services/featureFlags';
import "./anchor.global.scss";
const { bool, string } = PropTypes;

function Anchor(props) {
  const {
    caption,
    containerStyles,
    isForcedShown,
    isJustified,
    isNaOption,
    isOnFirstRow,
    isSelected,
    questionId,
    id,
  } = props;

  const anchorStyles = classNames('anchor', 'anchor_customStyles', containerStyles, {
    anchor_isForcedShown: isForcedShown,
    anchor_isJustified: isJustified,
    anchor_isNaOption: isNaOption,
    anchor_isOnFirstRow: isOnFirstRow,
    anchor_isSelected: isAddingSelectedClassEnabled() && isSelected
  });
  const spanId = (isOnFirstRow) ? { 'id': `anchor_${questionId}_${isNaOption ? 'na' : id }`} : '';

  return (
    <Cell ariaHidden containerStyles={anchorStyles}>
      <span {...spanId} dangerouslySetInnerHTML={{__html: caption}}/>
    </Cell>
  );
}

Anchor.propTypes = {
  caption: string,
  containerStyles: string,
  isForcedShown: bool,
  isJustified: bool,
  isNaOption: bool,
  isOnFirstRow: bool,
  isSelected: bool
};

export default Anchor;
