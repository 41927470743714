import PropTypes from 'prop-types';
import React from 'react';

import styles from './SelectItem.scss';

const SelectItem = (props) => {
  const { index, style, data } = props;
  const item = data[index];
  return (
    <div className={styles.container} style={style}>
      {item}
    </div>
  );
};

SelectItem.propTypes = {
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.node).isRequired
  }).isRequired
};

export default SelectItem;
