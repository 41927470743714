import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Icon, {ICON_TYPES} from '../../commons/Icon';
import { isWCAG21_2_Enabled } from '../../services/featureFlags';
import styles from './validation.scss';

export function determineIconType(isError) {
  if (isError) return ICON_TYPES.CLOSE;
  return ICON_TYPES.ARROW_RIGHT;
}

export default function ValidationMessage(props) {
  if (!props.shouldRender) return null;
  const {
    ariaHidden,
    caption,
    containerStyles,
    isError,
    hasIcon
  } = props;

  const mainContainerStyles = classNames(
    styles.validationBlock,
    containerStyles,
    'validationMessage',
    {
      [styles.errorBlock]: isError,
      [styles.requiredBlock]: !isError,
      [styles.opacityPointEight]: isWCAG21_2_Enabled() && !isError
    },
  );

  const iconType = determineIconType(isError);

  return (
    <span className={mainContainerStyles} aria-hidden={ariaHidden}>
      <Icon containerStyles={styles.validationIcon} type={iconType} shouldRender={hasIcon}/>
      <span className={styles.caption} dangerouslySetInnerHTML={{__html: caption}}/>
    </span>
  );
}

const { bool, string } = PropTypes;

ValidationMessage.propTypes = {
  ariaHidden: bool,
  caption: string,
  containerStyles: string,
  isError: bool,
  hasIcon: bool,
  shouldRender: bool
};

ValidationMessage.defaultProps = {
  isError: false,
  hasIcon: true,
  shouldRender: true
};
